<template>
  <layout>
    <template #header>
      <ui-header :title="$t('grondbank.view.title', {id})" back-route="/grondbank/list">
        <template #actions>
          <div class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                    data-bs-toggle="dropdown">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="contacts-actions" class="dropdown-menu">
              <li
                v-if="$roles([roles.DRIVER, roles.DRIVER_BOSSCHAERT])"
                id="fullViewBtn"
              >
                <button v-if="showDriverView" class="dropdown-item" @click="$emit('showDriverView')">
                  {{ $t('waste_identification.view.show_simple_form') }}
                </button>
              </li>
              <li
                v-if="$roles([roles.USER]) && !FormState.isEndState(form.state)"
                id="shareBtn"
                :title="!canShare ? $t('forms.view.only_signed_shared') : ''"
              >
                <button
                  class="dropdown-item"
                  :class="!canShare ? 'disabled' : ''"
                  @click="shareStore.showShare([form.id])"
                >
                  {{ $t('forms.view.share_with_driver') }}
                </button>
              </li>
              <li v-if="$roles([roles.OVERVIEWER, roles.USER])"
                  :title="!FormState.canExport(form.state) ? $t('forms.view.draft_no_export') : ''">
                <button class="dropdown-item" :class="!FormState.canExport(form.state) ? 'disabled' : ''"
                        @click="exportStore.showExport([form.id])">
                  {{ $t('forms.view.export') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])">
                <button class="dropdown-item" @click="duplicateStore.showDuplicate([form.id])">
                  {{ $t('forms.view.duplicate') }}
                </button>
              </li>
              <li
                v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT])"
                id="changeLogBtn"
              >
                <button class="dropdown-item" @click="changeLogStore.showChangeLog()">
                  {{ $t('forms.view.view_change_log') }}
                </button>
              </li>
              <li
                v-if="$roles([roles.USER]) && !FormState.isEndState(form.state)"
                id="deleteBtn"
                :title="!FormState.canDelete(form.state) ? $t('forms.view.only_draft_deleted') : ''">
                <button class="dropdown-item" :class="!FormState.canDelete(form.state) ? 'disabled' : ''"
                        @click="deleteStore.showDelete([form.id])">
                  {{ $t('forms.view.delete') }}
                </button>
              </li>
              <li
                v-if="$roles([roles.USER]) && !FormState.isDraft(form.state) && form.state !== FormState.CANCELLED && form.state !== FormState.STOPPED"
                id="cancelBtn">
                <button class="dropdown-item" @click="cancelStore.showCancel()">
                  {{ $t('forms.view.cancel') }}
                </button>
              </li>
              <li v-if="$flag('ATTACHMENTS')">
                <button class="dropdown-item" @click="attachmentsStore.showAttachments">
                  {{ $t('forms.view.manage_attachments') }}
                </button>
              </li>
            </ul>
          </div>
          <button
            v-if="$roles([roles.USER], false) && form.state && !FormState.isEndState(form.state)"
            type="button" class="btn btn-outline-secondary"
            @click="formStore.goToEdit()"><i class="bi-pencil"/>&nbsp;{{ $t('forms.view.edit') }}
          </button>
          <button
            v-if="confirmStore.canConfirm"
            type="button" class="btn btn-outline-primary"
            @click="confirmStore.showConfirm"><i class="bi-check"/>&nbsp;{{ $t('forms.view.confirm') }}
          </button>
          <button
            v-if="$roles([roles.DRIVER], false) && !rideInProgress && FormState.canStart(form.state, true)"
            type="button" class="btn btn-outline-primary"
            @click="startRideStore.showStartRide"><i class="bi-truck"/>&nbsp;{{ $t('forms.view.start_ride') }}
          </button>
          <button
            v-if="$roles([roles.DRIVER], false) && rideInProgress && !FormState.isEndState(form.state)"
            type="button" class="btn btn-outline-primary"
            @click="stopRideStore.showStopRide"><i class="bi-slash-circle"/>&nbsp;{{ $t('forms.view.stop_ride') }}
          </button>
          <button
            v-if="$roles([roles.USER], false) && finishStore.canFinish"
            type="button" class="btn btn-outline-primary"
            @click="finishStore.showFinish([form.id])"
          >
            <i class="bi-check-circle"/>&nbsp;{{ $t('forms.view.finish') }}
          </button>
          <button
            v-else-if="$roles([roles.USER], false) && canShare && form.state === FormState.SIGNED"
            type="button" class="btn btn-outline-primary"
            @click="shareStore.showShare([form.id])"><i class="bi-send"/>&nbsp;{{
              $t('forms.view.share_with_driver')
            }}
          </button>
          <button
            v-else-if="$roles([roles.USER], false) && form.state === FormState.FINISHED"
            type="button" class="btn btn-outline-primary"
            @click="summaryStore.showSummary"><i class="bi-list"/>&nbsp;{{ $t('forms.view.summary') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <grondbank-form-view :form="form" :key="form.lastUpdate" @signed="reloadForm()" @ride-added="reloadForm()"/>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import {computed, provide, ref} from 'vue'
import FormState from '@/types/formState'
import OrganisationType from '@/types/organisationType'
import DocumentType from '@/types/documentType'
import GrondbankFormView from '@/partials/grondbank/FormView'
import GrondbankModel from '@/models/GrondbankModel'
import roles from '@/util/roles'
import {useFormStore} from '@/stores/FormStore'
import {storeToRefs} from 'pinia'
import {useConfirmStore} from '@/stores/modals/ConfirmStore'
import {useSummaryStore} from '@/stores/modals/SummaryStore'
import {useChangeLogStore} from '@/stores/modals/ChangeLogStore'
import {useCancelStore} from '@/stores/modals/CancelStore'
import {useDuplicateStore} from '@/stores/modals/DuplicateStore'
import {useExportStore} from '@/stores/modals/ExportStore'
import {useDeleteStore} from '@/stores/modals/DeleteStore'
import {useShareStore} from '@/stores/modals/ShareStore'
import {useAttachmentsStore} from '@/stores/modals/AttachmentsStore'
import {useFinishStore} from '@/stores/modals/FinishStore'
import {useStartRideStore} from '@/stores/modals/StartRideStore'
import {useStopRideStore} from '@/stores/modals/StopRideStore'

export default {
  name:       'GrondbankViewUser',
  computed:   {
    DocumentType() {
      return DocumentType
    },
    FormState() {
      return FormState
    },
    OrganisationType() {
      return OrganisationType
    },
  },
  components: {
    GrondbankFormView,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id:             {
      type:     String,
      required: true,
    },
    showDriverView: {
      type:    Boolean,
      default: false,
    },
  },
  emits:      ['showDriverView'],
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK, BREADCRUMBS.VIEW]

    const formStore = useFormStore()
    const {form}    = storeToRefs(formStore)

    const confirmStore     = useConfirmStore()
    const summaryStore     = useSummaryStore()
    const changeLogStore   = useChangeLogStore()
    const cancelStore      = useCancelStore()
    const duplicateStore   = useDuplicateStore()
    const exportStore      = useExportStore()
    const deleteStore      = useDeleteStore()
    const shareStore       = useShareStore()
    const attachmentsStore = useAttachmentsStore()
    const finishStore      = useFinishStore()
    const startRideStore   = useStartRideStore()
    const stopRideStore    = useStopRideStore()

    const canShare = computed(() => {
      return form.value.state && FormState.canShare(form.value.state, true) && roles.hasOneOfRoles([roles.USER]) && GrondbankModel.modelCheck(form.value, {allowPast: true})
    })

    const processorInfo = ref(null)
    provide('processorInfo', processorInfo)
    const reloadForm = async () => {
      await formStore.reloadForm(props.id)

      processorInfo.value = {organisation: form.value.soilDestination.name}
    }

    const rideInProgress = computed(() => {
      let res = false
      form.value.soilRides.forEach((ride) => {
        if (ride.startOfTransport && !ride.endOfTransport) {
          res = true
        }
      })
      return res
    })

    return {
      breadcrumbs,
      ...storeToRefs(formStore),
      formStore,
      confirmStore,
      summaryStore,
      changeLogStore,
      cancelStore,
      duplicateStore,
      exportStore,
      deleteStore,
      shareStore,
      attachmentsStore,
      finishStore,
      startRideStore,
      stopRideStore,
      reloadForm,
      canShare,
      rideInProgress,
    }
  },
}
</script>
