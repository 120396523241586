import {defineStore} from "pinia";
import {computed, inject, ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import FormState from "@/types/formState";
import roles from "@/util/roles";
import DriverModel from "@/models/DriverModel";
import session from "@/util/session";
import flag from "@/util/flag";
import ShareLinkType from "@/types/shareLinkType";
import {useFormListStore} from "@/stores/FormListStore";

export const useShareStore = defineStore('share', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const formListStore = useFormListStore()
  const ids = ref([])
  const list = ref(false)
  const documentType = ref(null) // TODO can be removed when we only use formStore
  const qrSrc = ref(null)
  const driver = ref({})
  const $isSafari = inject('$isSafari')

  const initDriver = () => {
    driver.value = JSON.parse(JSON.stringify(DriverModel.empty))
    driver.value.language = session.getOrganisationLanguage() // default to organisation language
    driver.value.workslip = flag('include_work_slip')
    qrSrc.value = null
  }

  const shareModal = ref('shareModal')

  const showShare = (selectedIds, isList) => {
    ids.value = selectedIds
    list.value = isList
    shareModal.value.modal.open()
  }

  const canShare = computed(() => {
    return roles.hasOneOfRoles([roles.USER]) && FormState.isDraft(formStore.form.state) && formStore.modelCheck()
  })

  const share = async () => {
    if (!list.value) {
      await shareOne()
    } else {
      await shareList()
    }
  }

  const shareOne = async () => {
    const id = ids.value[0]
    try {
      const result = await backend.post(`api/documents/batch`, {
        'action': 'SHARE_DRIVER',
        'ids': ids.value,
        'params': driver.value,
      })
      if (result.status !== 200) {
        notifier.error('toast.sharing_failed')
      } else {
        notifier.success('toast.sharing_successful')
        await formStore.reloadForm(id)
      }
    } catch (err) {
      notifier.error('toast.sharing_failed')
    } finally {
      shareModal.value.modal.close()
    }
  }

  const shareList = async () => {
    try {
      if (ids.value.length > 0) {
        const result = await backend.post('api/documents/batch', {
          'action': 'SHARE_DRIVER',
          'ids': ids.value,
          'params': driver.value,
        })
        if (result.status === 200) {
          notifier.success('toast.sharing_successful')
        } else {
          notifier.error('toast.sharing_failed')
        }
      }
    } catch (err) {
      notifier.error('toast.sharing_failed')
    } finally {
      shareModal.value.modal.close()
      formListStore.reload()
    }
  }

  const generating = ref(false)
  const generateQR = async () => {
    const id = ids.value[0]
    generating.value = true
    try {
      const result = await backend.post('api/sharedlinks', {
        documentId: id,
        shareLinkType: ShareLinkType.TRANSPORTER,
        documentType: formStore.form?.type ?? documentType.value,
      })
      const url = `${window.location.hostname === 'localhost'
          ? window.location.hostname + ':' + 8082
          : window.location.origin}/#/shared-links/${result.data.id}`
      const QRCode = require('qrcode');
      QRCode.toDataURL(url)
          .then(url => {
            qrSrc.value = url
          })
    } catch (e) {
      notifier.error('toast.sharing_failed')
    } finally {
      await formStore.reloadForm(id)
      generating.value = false
    }
  }

  const resetQR = () => {
    qrSrc.value = null
  }

  const generatedWorkSlip = ref(false)
  const generateWorkSlip = async () => {
    const id = ids.value[0]
    if ($isSafari()) {
      const url = `api/documents/${id}/transportpdf?language=${driver.value.language ?? ''}`
      window.open(url, '_blank')
    } else {
      const result = await backend.get(`api/documents/${id}/transportpdf?language=${driver.value.language ?? ''}`, {responseType: 'blob'})
      if (result.status === 200) {
        const blob = new Blob([result.data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url)
        await formStore.reloadForm(id)
        generatedWorkSlip.value = true
      } else {
        notifier.error('toast.exporting_failed')
      }
    }
  }

  return {
    shareModal,
    canShare,
    showShare,
    share,
    ids,
    list,
    documentType,
    qrSrc,
    initDriver,
    driver,
    generating,
    generateQR,
    resetQR,
    generatedWorkSlip,
    generateWorkSlip
  }
})