<template>
  <annex7-list-support v-if="$orgType(OrganisationType.SUPPORT)"/>
  <annex7-list-user v-else/>
</template>

<script>

import Annex7ListUser from '@/views/forms/annex-7/List-User.vue'
import session from '@/util/session'
import OrganisationType from '@/types/organisationType'
import Annex7ListSupport from '@/views/forms/annex-7/List-Support.vue'
import DocumentType from '@/types/documentType'
import {useFormStore} from '@/stores/FormStore'

export default {
  name:       'Annex7List',
  computed:   {
    OrganisationType() {
      return OrganisationType
    },
  },
  components: {
    Annex7ListSupport,
    Annex7ListUser,
  },
  setup:      () => {
    session.setActiveForm('annex-7')

    const formStore = useFormStore()
    formStore.init(DocumentType.ANNEX_7)

    return {}
  },
}
</script>
