import {defineStore, storeToRefs} from "pinia";
import {computed, ref} from "vue";
import {useFormStore} from "@/stores/FormStore";
import session from "@/util/session";
import {useSharedLinkStore} from "@/stores/SharedLinkStore";

export const useChangeLogStore = defineStore('changeLog', () => {
  const changeLogModal = ref('changeLogModal')

  const showChangeLog = () => {
    changeLogModal.value.modal.open()
  }

  const formStore = useFormStore()
  const sharedLinkStore = useSharedLinkStore()
  const {form} = session.isAnonymousUser() ? storeToRefs(sharedLinkStore) : storeToRefs(formStore)
  const logs = computed(() => {
    const temp = form.value?.documentAuditLogs
    if (temp) {
      return temp.reverse()
    }
    return []
  })

  return {
    changeLogModal,
    showChangeLog,
    logs,
  }
})