import {defineStore, storeToRefs} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";

export const useSharedWithStore = defineStore('sharedWith', () => {
  const list = ref([])
  const notifier = Notifier()

  const formStore = useFormStore()
  const {form} = storeToRefs(formStore)

  const sharedWithModal = ref('sharedWithModal')
  const showSharedWith = () => {
    sharedWithModal.value.modal.open()
  }

  const load = async (id) => {
    const result = await backend.get(`api/documents/${id ?? form.value.id}/sharedWith`)
    list.value = result.data
  }

  const remove = async (s) => {
    try {
      const result = await backend.delete(`api/sharedlinks/${s.id}`)
      if (result.status === 200) {
        notifier.success('toast.sharelink_delete_success')
      } else {
        notifier.success('toast.sharelink_delete_failed')
      }
    } finally {
      await load()
    }
  }

  return {
    sharedWithModal,
    showSharedWith,
    list,
    remove,
    load,
  }
})