<template>
  <loading-layout :title="$t('annex_7.edit.title', {id})" :back-route="`/annex-7/view/${id}`"
                  v-if="formStore.loading"/>
  <layout v-else>
    <template #header>
      <ui-header :title="$t('annex_7.edit.title', {id})" :back-route="`/annex-7/view/${id}`">
        <template #actions>
          <button data-cy="check-a7" type="button" class="btn btn-outline-secondary" @click="formStore.formCheck()"><i
              class="bi-check"/>&nbsp;{{ $t('form.check_form') }}
          </button>
          <button data-cy="save-a7" type="button" class="btn btn-outline-primary" :disabled="saving"
                  @click="formStore.save" form="annex-7-form"><i class="bi-save"/>&nbsp;{{ $t('general.save') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <annex7-form :item="form"/>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import {watch} from 'vue'
import FormState from '@/types/formState'
import DocumentType from '@/types/documentType'
import Annex7Form from '@/partials/annex7/Form.vue'
import {storeToRefs} from 'pinia'
import LoadingLayout from '@/partials/LoadingLayout.vue'
import {useFormStore} from '@/stores/FormStore'

export default {
  name:       'Annex7Edit',
  components: {
    LoadingLayout,
    Annex7Form,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ANNEX_7, BREADCRUMBS.EDIT]

    const formStore = useFormStore()
    formStore.init(DocumentType.ANNEX_7)

    formStore.loadForm(props.id)

    watch(() => props.id, () => {
      formStore.loadForm(props.id)
    })

    watch(() => formStore.form.state, (state) => {
      if (FormState.isEndState(state)) {
        formStore.goToView()
      }
    }, {deep: true})

    return {
      breadcrumbs,
      formStore,
      ...storeToRefs(formStore),
    }
  },
}
</script>
