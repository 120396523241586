<template>
  <ui-modal id="warningModal" ref="modal" :callback="cb">
    <template #title>
      {{ $t('forms.warning_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ message ? $t(message) : '' }}
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {computed, ref} from 'vue'
import UiModal from '@/components/ui-modal'
import {useWarningStore} from '@/stores/modals/WarningStore'

export default {
  name:       'FormWarningModal',
  components: {
    UiModal,
  },
  props:      {
    warningMessage: {
      type: String,
    },
    callback:       {
      type: Function,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const warningStore = useWarningStore()

    const message = computed(() => {
      if (props.warningMessage) {
        return props.warningMessage
      } else {
        return warningStore.message
      }
    })
    const cb      = async () => {
      if (props.callback) {
        await props.callback()
      } else {
        await warningStore.performCallback()
      }
    }

    return {
      modal,
      message,
      cb,
      warningStore,
    }
  },
}
</script>
