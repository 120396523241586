<template>
  <layout>
    <template #header>
      <ui-header data-cy="grondwijzer-title" :title="$t('grondwijzer.new.title')" back-route="/grondwijzer/list">
        <template #actions>
          <button data-cy="grondwijzer-create" type="button" class="btn btn-outline-primary" :disabled="saving"
                  @click="save()" form="grondwijzer-form"><i class="bi-save"/>&nbsp;{{ $t('general.create') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <grondwijzer-form :item="item"/>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import GrondwijzerModel from '@/models/GrondwijzerModel'
import session from '@/util/session'
import Notifier from '@/util/notifier'
import GrondwijzerForm from '@/partials/grondwijzer/Form'

export default {
  name:       'GrondwijzerNew',
  components: {GrondwijzerForm, UiContent, UiBreadcrumbs, UiHeader, Layout},
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDWIJZER, BREADCRUMBS.NEW]
    const item        = ref(JSON.parse(JSON.stringify(GrondwijzerModel.empty)))
    const date        = ref(null)

    const columnDefs = [
      {headerName: 'description', field: 'description', sortable: true, filter: true},
      {headerName: 'amount', field: 'amount', sortable: true, filter: true},
      {headerName: 'EURAL-code', field: 'euralCode', sortable: true, filter: true},
    ]

    const validate = () => {
      return validateIDs()
    }

    const validateIDs = () => {
      const vatElements = $('.identification-to-validate')
      for (let i = 0; i < vatElements.length; i++) {
        const el = vatElements[i]
        if (!el?.checkValidity()) {
          // something in the form is not valid so don't allow signing
          el?.reportValidity()
          return false
        }
      }
      return true
    }

    const saving   = ref(false)
    const router   = useRouter()
    const notifier = Notifier()
    const save     = async () => {
      saving.value = true
      if (!validate()) {
        saving.value = false
        return false
      }

      const result = await backend.post('api/documents', item.value)
      if (result.status === 200) {
        router.push(`/grondwijzer/edit/${result.data.id}`)
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    // when creating the new doc set the language to the default organisation language
    const setDefaultLanguage = (item) => {
      const language                                    = session.getOrganisationLanguage()
      item.value.producerIdentification.language        = language
      item.value.organiserIdentification.language       = language
      item.value.transporterIdentifications[0].language = language
      item.value.processorIdentification.language       = language
    }
    setDefaultLanguage(item)

    return {
      breadcrumbs,
      item,
      columnDefs,
      date,
      save,
      saving,
    }
  },
}
</script>
