<template>
  <ui-modal ref="modal" :callback="cb">
    <template #title>
      {{ $t('grondbank.stop_ride.title') }}
    </template>
    <template #default>
      {{ $t('grondbank.stop_ride.text') }}
      <br/>
      <br/>
      <form id="grondbank-stop-ride-form">
        <ui-view-card-field :label="$t('form.labels.end_hour')" :required="true" :readonly="true">
          <datepicker time-picker data-cy="grondbank-ride-start-time" v-model="ride.endOfTransport" :readonly="true"/>
        </ui-view-card-field>
        <ui-view-card-number :label="$t('form.labels.volume_in_m3')" v-model="ride.volume" required/>
      </form>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref} from 'vue'
import Datepicker from '@vuepic/vue-datepicker'
import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardNumber from '@/components/ui-view-card-number.vue'
import {useStopRideStore} from '@/stores/modals/StopRideStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormStopRideModal',
  components: {
    UiViewCardNumber,
    UiViewCardField,
    Datepicker,
    UiModal,
  },
  props:      {
    callback:       {
      type: Function,
    },
    endOfTransport: {
      type:    String,
      default: '',
    },
  },
  setup:      (props) => {
    const modal         = ref('modal')
    const stopRideStore = useStopRideStore()

    const cb = async () => {
      const formElement = $('#grondbank-stop-ride-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow stoping
        formElement.reportValidity()
        return false
      }

      if (props.callback) {
        let res = props.callback(stopRideStore.ride)
        if (res) {
          modal.value.close()
        }
      } else {
        await stopRideStore.stopRide()
      }
    }

    return {
      stopRideStore,
      ...storeToRefs(stopRideStore),
      modal,
      cb,
    }
  },
}
</script>
