import {defineStore} from "pinia";
import {computed, ref} from "vue";
import backend from "@/util/backend";
import sharelinkConfig from "@/services/sharelinkConfig";
import {useRouter} from "vue-router";
import ShareLinkType from "@/types/shareLinkType";
import session from "@/util/session";

export const useSharedLinkStore = defineStore('sharedLink', () => {
  const loading = ref(true)
  const shareLinkType = ref(null)
  const link = ref(null)
  const form = ref(null)
  const user = ref(null)
  const organisation = ref(null)
  const shareLinkConfig = ref(sharelinkConfig)
  const router = useRouter()

  const init = async (uuid) => {
    loading.value = true
    try {
      await backend.get(`api/sharedlinks/init?shareLinkUuid=${uuid}`)

      user.value = await session.setUser()
      organisation.value = await session.getOrganisation()
      shareLinkConfig.value.parse(organisation.value)

      const sharelinkResult = await backend.get(`api/sharedlinks/${uuid}`)
      if (!ShareLinkType.isValidType(sharelinkResult.data.shareLinkType)) {
        throw new Error('Unknown shared link type!')
      }
      shareLinkType.value = sharelinkResult.data.shareLinkType
      link.value = sharelinkResult.data
      await loadForm()
    } catch (e) {
      if (e !== 'no session') {
        await router.push({name: 'SharedExpired'})
      }
    }
  }

  const loadForm = async () => {
    loading.value = true
    await reloadForm()
    loading.value = false
  }

  const resetForm = async () => {
    form.value = {}
  }

  const reloadForm = async () => {
    try {
      const result = await backend.get(`api/sharedlinks/${link.value.id}/document`)
      form.value = result.data
    } finally {
      loading.value = false
    }
  }

  const setForm = (formValue) => {
    form.value = formValue
  }

  const attachmentsFlag = computed(() => {
    return shareLinkConfig.value && shareLinkConfig.value.getConfig('ATTACHMENTS')
  })

  return {
    loading,
    shareLinkType,
    init,
    shareLinkConfig,
    link,
    form,
    user,
    organisation,
    loadForm,
    reloadForm,
    resetForm,
    setForm,
    attachmentsFlag,
  }
})