import {defineStore, storeToRefs} from "pinia";
import {ref} from "vue";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import backend from "@/util/backend";
import {useSharedLinkStore} from "@/stores/SharedLinkStore";
import session from "@/util/session";

export const useAttachmentsStore = defineStore('attachments', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const sharedLinkStore = useSharedLinkStore()
  const {form} = session.isAnonymousUser() ? storeToRefs(sharedLinkStore) : storeToRefs(formStore)
  const files = ref([])
  const tooMuch = ref(false)
  const deleting = ref(false)

  const attachmentsModal = ref('attachmentsModal')

  const showAttachments = () => {
    files.value = form.value?.attachments.map((attachment) => {
      return {id: attachment.id, name: attachment.name, size: attachment.size, url: attachment.url}
    })
    attachmentsModal.value.modal.open()
  }

  const handleFileUpload = async (event, index) => {
    if (session.isAnonymousUser()) {
      return await handleFileUploadAnon(event, index)
    }
    return await handleFileUploadAuth(event, index)
  }

  const handleFileUploadAuth = async (event, index) => {
    if (!event.target.files || !event.target.files[0]) {
      return
    }
    if (files.value[index]?.url) {
      const res = await deleteFile(index)
      if (!res) {
        return
      }
    }

    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('multipartFile', file)
    try {
      tooMuch.value = false
      const result = await backend.post(`api/documents/${form.value.id}/attachments`, formData)
      notifier.success('toast.upload_successful')
      files.value = []
      result.data.attachments.forEach((attachment) => {
        files.value.push({id: attachment.id, name: attachment.name, size: attachment.size, url: attachment.url})
      })
      await formStore.reloadForm()
    } catch (err) {
      tooMuch.value = true
      notifier.error('toast.upload_failed')
    }
  }

  const handleFileUploadAnon = async (event, index) => {
    if (!event.target.files || !event.target.files[0]) {
      return
    }
    if (files.value[index]?.url) {
      const res = await deleteFile(index)
      if (!res) {
        return
      }
    }

    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('multipartFile', file)
    try {
      tooMuch.value = false
      const result = await backend.post(`api/sharedlinks/${sharedLinkStore.link.id}/attachments`, formData)
      notifier.success('toast.upload_successful')
      files.value = []
      result.data.attachments.forEach((attachment) => {
        files.value.push({id: attachment.id, name: attachment.name, size: attachment.size, url: attachment.url})
      })
      await sharedLinkStore.reloadForm()
    } catch (err) {
      tooMuch.value = true
      notifier.error('toast.upload_failed')
    }
  }

  const deleteFile = async (index) => {
    deleting.value = true
    if (session.isAnonymousUser()) {
      return await deleteFileAnon(index)
    }
    return await deleteFileAuth(index)
  }

  const deleteFileAuth = async (index) => {
    const file = files.value[index]
    if (!file.url) {
      files.value.splice(index, 1)
      return
    }

    try {
      await backend.delete(`api/documents/${form.value.id}/attachments/${file.id}`)
      notifier.success('toast.attachment_delete_successful')
      files.value.splice(index, 1)
      await formStore.reloadForm(formStore.form.id)
    } catch (e) {
      notifier.error('toast.attachment_delete_failed')
    } finally {
      deleting.value = false
    }
  }

  const deleteFileAnon = async (index) => {
    const file = files.value[index]
    if (!file.url) {
      files.value.splice(index, 1)
      return
    }

    try {
      await backend.delete(`api/sharedlinks/${sharedLinkStore.link.id}/attachments/${file.id}`)
      notifier.success('toast.attachment_delete_successful')
      files.value.splice(index, 1)
      await sharedLinkStore.reloadForm()
    } catch (e) {
      notifier.error('toast.attachment_delete_failed')
    } finally {
      deleting.value = false
    }
  }

  const addFile = () => {
    files.value.push({name: '', url: null})
  }

  const getFile = async (file) => {
    window.open(file.url, '_blank')
  }

  const save = async () => {
    return null
  }

  return {
    attachmentsModal,
    showAttachments,
    getFile,
    deleteFile,
    addFile,
    handleFileUpload,
    files,
    tooMuch,
    save,
    deleting,
  }
})