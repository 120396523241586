import axios from 'axios'
import Notifier from '../util/notifier'
import session from '../util/session'
import i18n, {languages} from '../util/i18n'
import _ from 'lodash'

const $t = () => {
  const user = session.getUser()
  return i18n(user?.language ?? languages.NL)
}

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    if (error.response.exceptionType === 'SessionNotFoundException') {
      session.dele
    }
    if (error.response.data.message === 'no credentials provided for: /api/auth/logout') {
      // we tried to log out, but we were already logged out
      return Promise.resolve()
    }
    if (error.response.data.message === 'no credentials provided for: /api/auth/me') {
      // we thought we had a session, but we don't
      session.setSessionStorage('redirectUrl', location.href)
    }
    router.push({name: 'Login'})
    return Promise.reject(error)
  }
  let notifier = Notifier()
  if (error?.response?.data?.messageKey) {
    const params = {}
    if (_.isObject(error?.response?.data?.params)) {
      Object.keys(error?.response?.data?.params).forEach((key) => {
        params[key] = $t()(error.response.data.params[key])
      })
    }
    notifier.error('toast.errors.' + error.response.data.messageKey, params)
  } else if (error?.response?.data?.message) {
    notifier.error(error.response.data.message)
  } else if (error?.response?.data?.errorCode) {
    notifier.error('toast.error', {errorcode: error.response.data.errorCode})
  } else {
    notifier.error('toast.error_no_code')
  }
  return Promise.reject(error)
})

let router
const backend = {
  check:       function () {
    const sessionID = session.getSessionStorage('user-session')
    const localID   = session.getLocalStorage('user-session')
    if (sessionID !== localID) {
      session.setLocalStorage('user-session', sessionID)
      window.location.reload()
      return false
    }
    return true
  },
  initBackend: (r) => {
    router = r
  },
  getLogin:    () => {
    return axios.get('api/auth/login')
  },
  getLogout:   () => {
    return axios.get('api/auth/logout')
  },
  get:         function (url, config) {
    if (!this.check()) {
      return Promise.reject('no session')
    }
    return axios.get(url, config)
  },
  post:        function (url, data, config) {
    if (!this.check()) {
      return Promise.reject('no session')
    }
    return axios.post(url, data, config)
  },
  put:         function (url, data, config) {
    if (!this.check()) {
      return Promise.reject('no session')
    }
    return axios.put(url, data, config)
  },
  delete:      function (url, config) {
    if (!this.check()) {
      return Promise.reject('no session')
    }
    return axios.delete(url, config)
  },
}

export default backend
