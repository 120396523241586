<template>
  <ui-modal id="shareModal" ref="modal" size="fullscreen" :on-open="() => sharedWithStore.load(id)" :no-confirm="true">
    <template #title>
      {{ $t('forms.shared_with_modal.title') }}
    </template>
    <template #default>
      <table class="table table-bordered table-striped">
        <thead>
        <tr>
          <th>{{ $t('form.table.created_on') }}</th>
          <th>{{ $t('form.table.email') }}</th>
          <th>{{ $t('form.table.first_name') }}</th>
          <th>{{ $t('form.table.last_name') }}</th>
          <th>{{ $t('form.table.organisation') }}</th>
          <th>{{ $t('form.table.type') }}</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        <tr v-for="s in list" :key="s.id">
          <td>
            {{ DateFormatter.formatDateTime(s.createdOn) }}
          </td>
          <td>
            {{ s.email }}
          </td>
          <td>
            {{ s.firstName }}
          </td>
          <td>
            {{ s.lastName }}
          </td>
          <td>
            {{ s.organisation }}
          </td>
          <td>
            {{ $t('share_link_types.' + s.shareLinkType) }}
          </td>
          <td>
            <div class="btn btn-danger" @click.once="sharedWithStore.remove(s)">
              <i class="bi-trash"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import {DateFormatter} from '@/services/dateFormatter'
import {storeToRefs} from 'pinia'
import {useSharedWithStore} from '@/stores/modals/SharedWithStore'

export default {
  name:       'FormSharedWithModal',
  computed:   {
    DateFormatter() {
      return DateFormatter
    },
  },
  components: {
    UiModal,
  },
  props:      {
    id: {
      type:    Number,
      default: null,
    },
  },
  setup:      () => {
    const modal           = ref('modal')
    const sharedWithStore = useSharedWithStore()

    return {
      modal,
      sharedWithStore,
      ...storeToRefs(sharedWithStore),
    }
  },
}
</script>
