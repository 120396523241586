<template>
  <layout>
    <template #header>
      <ui-header :title="$t('annex_7.list.title')"/>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="formListStore.resetGrid">{{
            $t('general.reset_filters')
          }}
        </button>
      </div>
    </div>
    <ui-content>
      <div class="tab-content h-100">
        <div id="forms" class="h-100">
          <div class="d-flex flex-column h-100">
            <ag-grid-vue
                :columnDefs="columnDefs"
                :grid-options="grid.defaultOptions"
                :serverSideDatasource="grid.datasource(`api/documents/list?documentType=${DocumentType.ANNEX_7}`)"
                class="ag-theme-quartz"
                style="min-height:100%"
                @row-clicked="formListStore.formRowClick"
                @grid-ready="formListStore.onGridReady"
                @grid-pre-destroyed="formListStore.onGridPreDestroyed"
                @filter-changed="formListStore.onFilterChanged"
                :initialState="initialState"
            />
          </div>
        </div>
      </div>
    </ui-content>
  </layout>
</template>

<script>

import {inject, onBeforeUnmount} from 'vue'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import {DateFormatter} from '@/services/dateFormatter'
import FormState from '@/types/formState'
import '@vuepic/vue-datepicker/dist/main.css'
import DocumentType from '@/types/documentType'
import stateColorRenderer from '@/util/stateColorRenderer'
import checkboxRenderer from '@/util/checkboxRenderer'
import grid from '@/util/grid'
import {useFormListStore} from '@/stores/FormListStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'Annex7ListSupport',
  computed:   {
    grid() {
      return grid
    },
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    UiContent,
    UiHeader,
    Layout,
    UiBreadcrumbs,
    AgGridVue,
    // eslint-disable-next-line
    stateColorRenderer,
    // eslint-disable-next-line
    checkboxRenderer,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ANNEX_7]
    const $t          = inject('$t')

    const formListStore = useFormListStore()
    formListStore.init('annex-7-list-support')

    const resize = () => {
      setTimeout(() => {
        formListStore.api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }

    window.addEventListener('resize', resize)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resize)
      formListStore.api.value = null
    })

    const columnDefs = [
      {
        headerName:  $t('annex_7.list.table.key'),
        field:       'id',
        maxWidth:    250,
        valueGetter: (params) => {
          return 'DGF-A7-' + params.data.id
        },
      },
      {
        headerName:   $t('forms.list.table.organisation'),
        field:        'organisationId',
        filterParams: {
          filterOptions:    ['equals'],
          maxNumConditions: 1,
        },
      },
      {
        headerName:     $t('annex_7.list.table.date'),
        field:          'transportDate',
        maxWidth:       250,
        filter:         'agDateColumnFilter',
        filterParams:   {
          filterOptions: ['inRange'],
          minValidYear:  2000,
          maxValidYear:  2099,
        },
        valueGetter:    (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        },
      },
      {headerName: $t('annex_7.list.table.waste_description'), field: 'wasteDescription'},
      {
        headerName:     $t('annex_7.list.table.amount'),
        field:          'amount',
        maxWidth:       200,
        valueFormatter: (params) => {
          if (params.value) {
            let result  = ''
            const parts = params.value.split(' ')
            if (parts[0] !== 'null') {
              result += parts[0] + ' ' + $t('form.table.tonne') + ' '
            }
            if (parts[1] !== 'null') {
              result += parts[1] + ' ' + 'm³'
            }
            return result

          } else {
            return ''
          }
        },
      },
      {headerName: $t('annex_7.list.table.from'), field: 'from'},
      {headerName: $t('annex_7.list.table.to'), field: 'to'},
      {headerName: $t('annex_7.list.table.transporter'), field: 'transporter'},
      {
        headerName:   $t('annex_7.list.table.state'),
        field:        'state',
        maxWidth:     400,
        cellRenderer: 'stateColorRenderer',
        filter:       'agSetColumnFilter',
        filterParams: {
          values:         FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          },
        },
      },
    ]

    return {
      formListStore,
      ...storeToRefs(formListStore),
      breadcrumbs,
      columnDefs,
    }
  },
}
</script>
