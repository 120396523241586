import {defineStore} from "pinia";
import {ref} from "vue";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import backend from "@/util/backend";
import ShareLinkType from "@/types/shareLinkType";
import DocumentType from "@/types/documentType";

export const useControlStore = defineStore('control', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const mailInput = ref('')
  const controlQR = ref('')
  const controlUrl = ref('')
  const controlQRModal = ref('controlQRModal')

  const showControl = async () => {
    const result = await backend.post('api/sharedlinks', {
      documentId: formStore.form.id,
      shareLinkType: ShareLinkType.CONTROL,
      documentType: DocumentType.WASTE_IDENTIFICATION,
    })
    controlUrl.value = `${window.location.hostname === 'localhost'
        ? window.location.hostname + ':' + 8082
        : window.location.hostname}/#/shared-links/${result.data.id}`
    await setControlQR()
    controlQRModal.value.modal.open()
  }

  const setControlQR = async () => {
    const QRCode = require('qrcode')
    QRCode.toDataURL(controlUrl.value)
        .then(url => {
          controlQR.value = url
        })
        .catch(err => {
          console.error(err)
        })
  }

  const sendControlMail = async () => {
    const result = await backend.put(`api/documents/${formStore.form.id}/share`, {
      email: mailInput.value,
    })
    if (result.status === 200) {
      notifier.success('toast.sharing_successful')
    } else {
      notifier.error('toast.sharing_failed')
    }
    controlQRModal.value.modal.close()
  }

  return {
    controlQRModal,
    showControl,
    sendControlMail,
    mailInput,
    controlQR,
  }
})