<template>
  <ui-modal id="finishModal" ref="modal" :callback="cb" :no-confirm="demo">
    <template #title>
      {{ $t('forms.finish_modal.title') }}
    </template>
    <template #default v-if="demo">
      <div class="pb-2">
        <div class="mb-4" style="color:red">
          {{ $t('forms.finish_modal.demo_warning') }}
        </div>
        <div>
          {{ $t('forms.finish_modal.' + form.type + '.information') }}
        </div>
      </div>
    </template>
    <template #default v-else>
      <div class="pb-2">
        <template v-if="ids.length>1">
          {{ $t('forms.finish_modal.finish_forms', {amount: ids.length}) }}
        </template>
        <template v-else>
          {{ $t('forms.finish_modal.finish_form') }}
        </template>
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import {useFormStore} from '@/stores/FormStore'
import {useFinishStore} from '@/stores/modals/FinishStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormFinishModal',
  components: {
    UiModal,
  },
  setup:      () => {
    const modal = ref('modal')

    const finishStore = useFinishStore()

    const cb = async () => {
      await finishStore.finishFunc()
    }

    const formStore = useFormStore()

    return {
      modal,
      ...storeToRefs(formStore),
      finishStore,
      ...storeToRefs(finishStore),
      cb,
    }
  },
}
</script>
