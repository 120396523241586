<template>
  <ui-modal id="confirmModal" ref="modal" :callback="cb">
    <template #title>
      {{ $t('forms.confirm_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('forms.confirm_modal.confirm_form') }}
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import {useConfirmStore} from '@/stores/modals/ConfirmStore'

export default {
  name:       'FormConfirmModal',
  components: {
    UiModal,
  },
  setup:      () => {
    const modal        = ref('modal')
    const confirmStore = useConfirmStore()

    const cb = () => {
      confirmStore.confirm()
    }

    return {
      modal,
      cb,
    }
  },
}
</script>
