import {defineStore} from "pinia";
import {inject, ref} from "vue";
import backend from "@/util/backend";
import {DateFormatter} from "@/services/dateFormatter";
import {useFormStore} from "@/stores/FormStore";
import router from "@/router/router";
import Notifier from "@/util/notifier";

export const useSummaryStore = defineStore('summary', () => {
  const $t = inject('$t')

  const formStore = useFormStore()
  const loading = ref(false)
  const ids = ref([])
  const fields = ref([])

  const $isSafari = inject('$isSafari')
  const notifier = Notifier()


  const summaryModal = ref('summaryModal')

  const showSummary = () => {
    summaryModal.value.modal.open()
  }

  const fetchSummary = async () => {
    // TODO build up fields based on form type
    fields.value = []
    loading.value = true
    const summary = await backend.get(`api/documents/${formStore.form.id}/rides/summary`)
    fields.value.push({
      'label': $t('forms.summary_modal.permission_id'),
      'value': summary.data.permissionId,
    })
    fields.value.push({
      'label': $t('forms.summary_modal.first_transport_date'),
      'value': DateFormatter.formatDate(summary.data.firstTransport),
    })
    fields.value.push({
      'label': $t('forms.summary_modal.last_transport_date'),
      'value': DateFormatter.formatDate(summary.data.lastTransport),
    })
    fields.value.push({
      'label': $t('forms.summary_modal.volumeCubicMeters'),
      'value': summary.data.volumeCubicMeters + ' m³',
    })
    ids.value.push(...summary.data.documentIds)
    loading.value = false
  }

  const exporting = ref(false)
  const exportFunc = async () => {
    if ($isSafari()) {
      const route = router.resolve(`/export-csv/${ids.value.join(',')}`)
      window.open(route.href, '_blank')
    } else {
      await exportCsv()
    }
  }

  const exportCsv = async () => {
    exporting.value = true
    try {
      const result = await backend.post('api/documents/batch', {
        'action': 'EXPORT_CSV',
        'ids': ids.value,
      })
      if (result.status === 200) {
        const blob = new Blob([result.data])
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `export.csv`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } else {
        notifier.error('toast.exporting_failed')
      }
    } catch (e) {
      notifier.error('toast.exporting_failed')
    } finally {
      exporting.value = false
    }
  }

  return {
    summaryModal,
    showSummary,
    fetchSummary,
    ids,
    fields,
    loading,
    exportFunc,
    exporting,
  }
})