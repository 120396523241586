<template>
  <ui-modal id="attachmentsModal" ref="modal" :no-confirm="true">
    <template #title>
      {{ $t('waste_identification.attachments_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('waste_identification.attachments_modal.help') }}
      </div>
      <div v-if="files.length === 0">
        {{ $t('waste_identification.attachments_modal.no_attachments') }}
      </div>
      <div class="text-danger" v-if="tooMuch">
        {{ $t('waste_identification.attachments_modal.too_much') }}
      </div>
      <div class="d-flex justify-content-between" v-for="(file, index) in files" :key="index">
        <input type="file" :id="'file'+index" @change="attachmentsStore.handleFileUpload($event, index)"
               style="display:none;"
               :ref="el => inputs[index] = el"/>
        <label v-if="!file.name" class="btn btn-outline-secondary flex-grow-1 text-truncate" style="min-width:0"
               :for="'file'+index">
          <span>
            {{ $t('waste_identification.attachments_modal.choose_file') }}
          </span>
        </label>
        <label v-else class="btn btn-outline-secondary flex-grow-1 text-truncate"
               @click="attachmentsStore.getFile(file)">
          <span>
            {{ file.name }}
          </span>
        </label>
        <div class="btn-group ml-auto flex-shrink-0">
          <button class="btn btn-outline-secondary" @click="inputs[index].click()"
                  v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT, roles.ANONYMOUS])"><i
              class="bi-pencil"/></button>
          <button class="btn btn-outline-danger" @click="attachmentsStore.deleteFile(index)"
                  v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT, roles.ANONYMOUS])"
                  :disabled="deleting"
          ><i
              class="bi-trash"/></button>
        </div>
      </div>
      <br/>
      <div class="d-flex justify-content-center">
        <button class="btn btn-outline-primary" @click="attachmentsStore.addFile"
                v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT, roles.ANONYMOUS])">
          {{ $t('waste_identification.attachments_modal.add_file') }}
        </button>
      </div>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref} from 'vue'
import {useAttachmentsStore} from '@/stores/modals/AttachmentsStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormAttachmentsModal',
  components: {
    UiModal,
  },
  setup:      () => {
    const modal  = ref('modal')
    const inputs = ref([])

    const attachmentsStore = useAttachmentsStore()

    return {
      attachmentsStore,
      ...storeToRefs(attachmentsStore),
      inputs,
      modal,
    }
  },
}
</script>
