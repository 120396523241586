<template>
  <loading-layout :title="$t('grondbank.view.title', {id})" back-route="/grondbank/list" v-if="formStore.loading"/>
  <template v-else>
    <grondbank-view-driver :id="id" v-if="roles.hasOnlyRole(roles.DRIVER)"/>
    <grondbank-view-user :id="id" v-else/>
  </template>
</template>

<script>

import GrondbankViewDriver from '@/views/forms/grondbank/View-Driver.vue'
import GrondbankViewUser from '@/views/forms/grondbank/View-User.vue'
import {useFormStore} from '@/stores/FormStore'
import roles from '@/util/roles'
import DocumentType from '@/types/documentType'
import LoadingLayout from '@/partials/LoadingLayout.vue'
import {watch} from 'vue'

export default {
  name:       'GrondbankView',
  computed:   {
    roles() {
      return roles
    },
  },
  components: {
    LoadingLayout,
    GrondbankViewUser,
    GrondbankViewDriver,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const formStore = useFormStore()
    formStore.init(DocumentType.GRONDBANK)

    formStore.loadForm(props.id)

    watch(() => props.id, () => {
      formStore.loadForm(props.id)
    })

    return {
      formStore,
    }
  },
}
</script>
