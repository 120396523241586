<template>
  <div class="row">
    <div class="col-lg-6">
      <Popper arrow class="light" @close="closePopper" :show="showPopper && suggestions.length > 0"
              v-click-outside="closePopper">
        <ui-view-card-field data-cy="transporter-name" :label="$t('form.labels.name')" v-model="model.name"
                            :required="true"/>
        <template #content>
          <div v-for="suggestion in suggestions" :key="suggestion.name" class="popper-suggestion">
            <div @click="useSuggestion(suggestion)">
          <span v-if="suggestion.address && suggestion.address.street && suggestion.address.number">
            {{ suggestion.name }}: {{ suggestion.address.street }} {{ suggestion.address.number }}
          </span>
              <span v-else>{{ suggestion.name }}</span>
            </div>
          </div>
        </template>
      </Popper>
    </div>
    <div class="col-lg-6">
      <ui-view-card-field v-model="model.externalId" :label="$t('form.labels.external_identifier')" :maxlength="50"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <ui-view-card-field v-model="model.email" :label="$t('form.labels.email')" data-cy="transporter-email"
                          type="email"/>
    </div>
    <div class="col-lg-6">
      <ui-view-card-field data-cy="transporter-phone'" v-model="model.phone" :label="$t('form.labels.phone_number')"
                          :maxlength="50"/>
    </div>
  </div>
  <ui-view-card-address cy="transporter" :edit="true" v-model="model.address" :required="true"/>
  <ui-view-card-language cy="transporter-language" :label="$t('form.labels.language')" v-model="model.language"
                         :required="true"/>
  <ui-view-card-ids v-model="model.identifications" :required="true" :country-code="model.address?.country"
                    cy="transporter" ref="idsRef"/>
  <ui-view-card-field v-if="withLicensePlate" data-cy="transporter-license-plate"
                      :label="$t('form.labels.license_plate')" v-model="model.licensePlate" :maxlength="25"/>
  <ui-view-card-field :label="$t('form.labels.means_of_transport')">
    <v-select :options="meansOfTransport.getAll()" v-model="model.meansOfTransport"
              :reduce="operation => operation.value">
      <template #search="{attributes, events}">
        <input
          class="vs__search"
          v-bind="attributes"
          v-on="events"
        />
      </template>
      <template #no-options>
        {{ $t('general.no_options') }}
      </template>
    </v-select>
  </ui-view-card-field>
</template>

<script>

import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {ref, watch} from 'vue'
import {useModelWrapper} from '@/util/modelWrapper'
import {debounce} from '@/util/debounce'
import backend from '@/util/backend'
import UiViewCardIds from '@/components/ui-view-card-ids'
import clearField from '@/services/clearfield'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import meansOfTransport from '@/types/meansOfTransport'

export default {
  name:       'ui-view-card-transporter',
  computed:   {
    meansOfTransport() {
      return meansOfTransport
    },
  },
  components: {UiViewCardLanguage, UiViewCardIds, UiViewCardAddress, UiViewCardField},
  props:      {
    modelValue:       {
      default: '',
    },
    withLicensePlate: {
      type:    Boolean,
      default: false,
    },
  },
  setup:      (props, {emit}) => {
    const model  = ref(useModelWrapper(props, emit))
    const idsRef = ref(null)

    if (!model.value.address) {
      model.value.address = {}
    }

    const suggestions = ref([])
    const hidePopup   = ref(false)
    const showPopper  = ref(true)
    watch(() => model.value.name, debounce(async (newVal) => {
      if (newVal && !hidePopup.value) {
        showPopper.value = true
        const result     = await backend.get(`/api/contacts/suggest?searchTerm=${encodeURIComponent(newVal)}`)
        if (result.status === 200 && result.data.length > 0) {
          suggestions.value = result.data.slice(0, 5)
        } else {
          showPopper.value = false
        }
      }
      hidePopup.value = false
    }, 400))

    setTimeout(() => {
      hidePopup.value = false // hide popup on open, then enable after half a second
    }, 500)

    const closePopper = () => {
      showPopper.value = false
    }

    const useSuggestion = (suggestion) => {
      const cleared = clearField(suggestion, 'id')
      if (cleared.identifications.length === 0) {
        cleared.identifications.push({
          value:              null,
          identificationType: null,
        })
      }
      Object.assign(model.value, cleared)
      hidePopup.value = true
      closePopper()
      idsRef.value.triggerValidateAll()
    }

    return {
      model,
      suggestions,
      showPopper,
      closePopper,
      useSuggestion,
      idsRef,
    }
  },
}
</script>
