import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'

const $t = () => {
  const user = session.getUser()
  return i18n(user?.language ?? languages.NL)
}

const TABS = {
  FORMS:       {value: 'forms', label: $t()('tabs.forms')},
  TEMPLATES:   {value: 'templates', label: $t()('tabs.templates')},
  ORGANISER:   {value: 'ORGANISER', label: $t()('tabs.organiser')},
  PRODUCER:    {value: 'PRODUCER', label: $t()('tabs.producer')},
  PROCESSOR:   {value: 'PROCESSOR', label: $t()('tabs.processor')},
  TRANSPORTER: {value: 'TRANSPORTER', label: $t()('tabs.transporter')},
}

export default TABS
