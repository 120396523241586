<template>
  <form-attachments-modal ref="attachmentsModal"/>
  <form-cancel-modal ref="cancelModal"/>
  <form-change-log-modal ref="changeLogModal"/>
  <form-confirm-modal ref="confirmModal"/>
  <form-control-qr-modal ref="controlQRModal"/>
  <form-delete-modal ref="deleteModal"/>
  <form-duplicate-modal ref="duplicateModal"/>
  <form-edit-weights-modal ref="editWeightsModal"/>
  <form-export-modal ref="exportModal"/>
  <form-finish-modal ref="finishModal"/>
  <form-refinalise-modal ref="refinaliseModal"/>
  <form-refuse-modal ref="refuseModal"/>
  <form-set-state-empty-modal ref="setStateEmptyModal"/>
  <form-share-modal ref="shareModal"/>
  <form-shared-with-modal ref="sharedWithModal"/>
  <form-summary-modal ref="summaryModal"/>
  <form-warning-modal ref="warningModal"/>
  <form-edit-ride-modal ref="editRideModal"/>
  <form-start-ride-modal ref="startRideModal"/>
  <form-stop-ride-modal ref="stopRideModal"/>
</template>

<script>
import {storeToRefs} from 'pinia'
import FormWarningModal from '@/views/forms/shared/Warning-Modal.vue'
import FormCancelModal from '@/views/forms/shared/Cancel-Modal.vue'
import FormChangeLogModal from '@/views/forms/shared/Change-Log-Modal.vue'
import FormSummaryModal from '@/views/forms/shared/Summary-Modal.vue'
import FormConfirmModal from '@/views/forms/shared/Confirm-Modal.vue'
import {useWarningStore} from '@/stores/modals/WarningStore'
import {useConfirmStore} from '@/stores/modals/ConfirmStore'
import {useSummaryStore} from '@/stores/modals/SummaryStore'
import {useChangeLogStore} from '@/stores/modals/ChangeLogStore'
import {useCancelStore} from '@/stores/modals/CancelStore'
import FormDuplicateModal from '@/views/forms/shared/Duplicate-Modal.vue'
import {useDuplicateStore} from '@/stores/modals/DuplicateStore'
import FormExportModal from '@/views/forms/shared/Export-Modal.vue'
import {useExportStore} from '@/stores/modals/ExportStore'
import FormDeleteModal from '@/views/forms/shared/Delete-Modal.vue'
import {useDeleteStore} from '@/stores/modals/DeleteStore'
import {useShareStore} from '@/stores/modals/ShareStore'
import FormShareModal from '@/views/forms/shared/Share-Modal.vue'
import FormAttachmentsModal from '@/views/forms/shared/Attachments-Modal.vue'
import {useAttachmentsStore} from '@/stores/modals/AttachmentsStore'
import FormFinishModal from '@/views/forms/shared/Finish-Modal.vue'
import {useFinishStore} from '@/stores/modals/FinishStore'
import {useStartRideStore} from '@/stores/modals/StartRideStore'
import {useStopRideStore} from '@/stores/modals/StopRideStore'
import FormStartRideModal from '@/views/forms/shared/Start-Ride-Modal.vue'
import FormStopRideModal from '@/views/forms/shared/Stop-Ride-Modal.vue'
import FormEditRideModal from '@/views/forms/grondbank/Edit-Ride-Modal.vue'
import {useEditRideStore} from '@/stores/modals/EditRideStore'
import FormControlQrModal from '@/views/forms/shared/Control-Qr-Modal.vue'
import {useControlStore} from '@/stores/modals/ControlStore'
import FormRefuseModal from '@/views/forms/shared/Refuse-Modal.vue'
import {useRefuseStore} from '@/stores/modals/RefuseStore'
import FormEditWeightsModal from '@/views/forms/shared/Edit-Weights-Modal.vue'
import {useEditWeightsStore} from '@/stores/modals/EditWeightsStore'
import {useSharedWithStore} from '@/stores/modals/SharedWithStore'
import FormSharedWithModal from '@/views/forms/shared/Shared-With-Modal.vue'
import FormSetStateEmptyModal from '@/views/forms/shared/Set-State-Empty-Modal.vue'
import {useSetStateEmptyStore} from '@/stores/modals/SetStateEmptyStore'
import FormRefinaliseModal from '@/views/forms/shared/Refinalise-Modal.vue'
import {useRefinaliseStore} from '@/stores/modals/RefinaliseStore'

export default {
  name:       'Modals',
  components: {
    FormRefinaliseModal,
    FormSetStateEmptyModal,
    FormSharedWithModal,
    FormEditWeightsModal,
    FormRefuseModal,
    FormControlQrModal,
    FormEditRideModal,
    FormStopRideModal,
    FormStartRideModal,
    FormFinishModal,
    FormAttachmentsModal,
    FormShareModal,
    FormDeleteModal,
    FormCancelModal,
    FormChangeLogModal,
    FormConfirmModal,
    FormDuplicateModal,
    FormExportModal,
    FormSummaryModal,
    FormWarningModal,
  },
  setup:      () => {
    const attachmentsStore   = useAttachmentsStore()
    const cancelStore        = useCancelStore()
    const changeLogStore     = useChangeLogStore()
    const confirmStore       = useConfirmStore()
    const controlStore       = useControlStore()
    const deleteStore        = useDeleteStore()
    const duplicateStore     = useDuplicateStore()
    const editRideStore      = useEditRideStore()
    const editWeightsStore   = useEditWeightsStore()
    const exportStore        = useExportStore()
    const finishStore        = useFinishStore()
    const refinaliseStore    = useRefinaliseStore()
    const refuseStore        = useRefuseStore()
    const setStateEmptyStore = useSetStateEmptyStore()
    const shareStore         = useShareStore()
    const sharedWithStore    = useSharedWithStore()
    const startRideStore     = useStartRideStore()
    const stopRideStore      = useStopRideStore()
    const summaryStore       = useSummaryStore()
    const warningStore       = useWarningStore()

    return {
      ...storeToRefs(attachmentsStore),
      ...storeToRefs(cancelStore),
      ...storeToRefs(changeLogStore),
      ...storeToRefs(confirmStore),
      ...storeToRefs(controlStore),
      ...storeToRefs(deleteStore),
      ...storeToRefs(duplicateStore),
      ...storeToRefs(editRideStore),
      ...storeToRefs(editWeightsStore),
      ...storeToRefs(exportStore),
      ...storeToRefs(finishStore),
      ...storeToRefs(refinaliseStore),
      ...storeToRefs(refuseStore),
      ...storeToRefs(setStateEmptyStore),
      ...storeToRefs(shareStore),
      ...storeToRefs(sharedWithStore),
      ...storeToRefs(startRideStore),
      ...storeToRefs(stopRideStore),
      ...storeToRefs(summaryStore),
      ...storeToRefs(warningStore),
    }
  },
}
</script>