const DateValidator = {
  dateIsInPast: (date) => {
    const localOffset = new Date().getTimezoneOffset() / -60 // offset to UTC in hours
    let transportDate = new Date(date)
    transportDate.setHours(transportDate.getHours() + localOffset)
    transportDate = transportDate.setUTCHours(0, 0, 0, 0)
    const today = new Date().setUTCHours(0, 0, 0, 0)
    return transportDate < today
  }
}


export default DateValidator