<template>
  <div id="WIFormView" class="container-fluid">
    <div class="row">
      <ui-view-card id="WIBasicInfo" data-bs-toggle="collapse" type="col-lg-12"
                    :title="$t('form.title.basic_information')" :demo-warning="demo">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.unique_identifier') }}: {{ 'DGF-' + formRef.id }}
            <br/>
            <template v-if="formRef.externalId">
              {{ $t('form.labels.external_identifier') }}: {{ formRef.externalId }}
              <br/>
            </template>
            {{ $t('form.labels.last_update') }}: {{ updateDate }}
            <br/>
            {{ $t('form.labels.updated_by') }}: {{ formRef.modifiedBy }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.status') }}: {{ $t('form.state.' + formRef.state) }}
            <br/>
            {{ $t('form.labels.transport_date') }}: {{ date }}
            <template v-if="$flag('PRIVATE_NOTE')">
              <br/>
              {{ $t('form.labels.note_for_driver') }}: {{ formRef.privateNotes }}
            </template>
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.producer')">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.name') }}: {{ formRef.producerIdentification.name }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.external_identifier') }}: {{ formRef.producerIdentification.externalId }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.email') }}: {{ formRef.producerIdentification.email }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.phone_number') }}: {{ formRef.producerIdentification.phone }}
          </div>
        </div>
        <ui-view-card-address :model-value="formRef.producerIdentification.address"/>
        <div class="col-lg-6">
          {{ $t('form.labels.language') }}: {{ languages.getLanguage(formRef.producerIdentification.language) }}
        </div>
        <template v-for="identification in formRef.producerIdentification.identifications" :key="identification.id">
          <div class="d-flex">
            {{ $t('form.labels.identification_number') }}:
            <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')"/>&nbsp;
            <span v-if="identification.identificationType">
              {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value }}
            </span>
          </div>
        </template>
        <hr/>
        <h6 class="card-subtitle text-muted">
          {{ $t('form.sending_address') }}
        </h6>
        <ui-view-card-address :model-value="formRef.producerIdentification.shipmentAddress"/>
        <div class="col-lg-6">
          {{ $t('form.labels.matis_location_type') }}:
          <span v-if="formRef.producerIdentification.locationType">
            {{ $t('matis_location_types.' + formRef.producerIdentification.locationType) }}
          </span>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.processor')">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.name') }}: {{ formRef.processorIdentification.name }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.external_identifier') }}: {{ formRef.processorIdentification.externalId }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.email') }}: {{ formRef.processorIdentification.email }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.phone_number') }}: {{ formRef.processorIdentification.phone }}
          </div>
        </div>
        <ui-view-card-address :model-value="formRef.processorIdentification.address"/>
        <div class="col-lg-6">
          {{ $t('form.labels.language') }}: {{ languages.getLanguage(formRef.processorIdentification.language) }}
        </div>
        <template v-for="identification in formRef.processorIdentification.identifications" :key="identification.id">
          <div class="d-flex">
            {{ $t('form.labels.identification_number') }}:
            <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')"/>&nbsp;
            <span v-if="identification.identificationType">
              {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value }}
            </span>
          </div>
        </template>
        <hr/>
        <h6 class="card-subtitle text-muted">
          {{ $t('form.receiving_address') }}
        </h6>
        <ui-view-card-address :model-value="formRef.processorIdentification.shipmentAddress"/>
        <div class="col-lg-6">
          {{ $t('form.labels.matis_location_type') }}:
          <span v-if="formRef.processorIdentification.locationType">
            {{ $t('matis_location_types.' + formRef.processorIdentification.locationType) }}
          </span>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.organiser_IHM')">
        <template #subtitle>{{ $t('form.organiser_subtitle') }}</template>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.name') }}: {{ formRef.organiserIdentification.name }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.external_identifier') }}: {{ formRef.organiserIdentification.externalId }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.phone_number') }}: {{ formRef.organiserIdentification.phone }}
          </div>
        </div>
        <ui-view-card-address :model-value="formRef.organiserIdentification.address"/>
        <div class="col-lg-6">
          {{ $t('form.labels.language') }}: {{ languages.getLanguage(formRef.organiserIdentification.language) }}
        </div>
        <template v-for="identification in formRef.organiserIdentification.identifications" :key="identification.id">
          <div class="d-flex">
            {{ $t('form.labels.identification_number') }}:
            <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')"/>&nbsp;
            <span v-if="identification.identificationType">
              {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value }}
            </span>
          </div>
        </template>
      </ui-view-card>
      <template v-for="(transporter, index) in formRef.transporterIdentifications" :key="index">
        <ui-view-card
            :title="index === 0 ? $t('form.title.transporter') : $t('form.title.transporter_count', {count: index+1})">
          <div class="row">
            <div class="col-lg-6">
              {{ $t('form.labels.name') }}: {{ transporter.name }}
            </div>
            <div class="col-lg-6">
              {{ $t('form.labels.external_identifier') }}: {{ transporter.externalId }}
            </div>
            <div class="col-lg-6">
              {{ $t('form.labels.email') }}: {{ transporter.email }}
            </div>
            <div class="col-lg-6">
              {{ $t('form.labels.phone_number') }}: {{ transporter.phone }}
            </div>
          </div>
          <ui-view-card-address v-model="transporter.address"/>
          <div class="col-lg-6">
            {{ $t('form.labels.language') }}: {{ languages.getLanguage(transporter.language) }}
          </div>
          <template v-for="identification in transporter.identifications" :key="identification.id">
            <div class="d-flex">
              {{ $t('form.labels.identification_number') }}:
              <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')"/>&nbsp;
              <span v-if="identification.identificationType">
              {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value }}
            </span>
            </div>
          </template>
          <div class="col-lg-6">
            {{ $t('form.labels.license_plate') }}: {{ transporter.licensePlate }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.means_of_transport') }}: {{
              transporter.meansOfTransport
              ? $t('means_of_transport.' + transporter.meansOfTransport)
              : ''
            }}
          </div>
        </ui-view-card>
      </template>
      <ui-view-card :title="$t('form.title.waste_identification')" type="col-lg-12">
        <table v-if="!$isMobile" class="table table-bordered table-striped">
          <thead>
          <tr>
            <th scope="col">{{ $t('form.table.description') }}</th>
            <th scope="col">
              {{ $t('form.table.amount') }}
              <span class="fs-4" v-if="weightEdited" :title="$t('form.weight_edited')">
                <weight-edited-icon/>
              </span>
            </th>
            <th scope="col">{{ $t('form.table.EURAL-code') }}</th>
            <th scope="col">{{ $t('form.table.processing_mode') }}</th>
            <th scope="col">{{ $t('form.table.processing_technique') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in formRef.wasteIdentifications" :key="index">
            <tr>
              <td style="word-break: break-word">
                {{ row.description }}
              </td>
              <td>
                {{ row.amount }} {{ $t('form.table.tonne') }}
              </td>
              <td>
                {{ getEuralCodeText(row) }}
              </td>
              <td>
                {{ row.removalOperation }}
              </td>
              <td>
                  <span v-if="row.processingTechnique">
                    {{ $t('processing_techniques.' + row.processingTechnique) }}
                  </span>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <table v-else class="table table-striped">
          <tbody>
          <tr v-for="(row, index) in formRef.wasteIdentifications" :key="index">
            <td>
              <table class="table">
                <tbody>
                <tr>
                  <th>{{ $t('form.table.description') }}</th>
                  <td style="word-break: break-word">{{ row.description }}</td>
                </tr>
                <tr>
                  <th>
                    {{ $t('form.table.amount') }}
                    <span class="fs-4" v-if="weightEdited" style="color:#4cd06d" :title="$t('form.weight_edited')">
                    <weight-edited-icon/>
                  </span>
                  </th>
                  <td>{{ row.amount }} {{ $t('form.table.tonne') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.EURAL-code') }}</th>
                  <td>{{ getEuralCodeText(row) }}</td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.processing_mode') }}</th>
                  <td>{{ row.removalOperation }}</td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.processing_technique') }}</th>
                  <td>
                    <template v-if="row.processingTechnique">
                      {{ $t('processing_techniques.' + row.processingTechnique) }}
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.waste_description')" v-if="isDangerous">
        <table v-if="!$isMobile" class="table table-bordered table-striped">
          <thead>
          <tr>
            <th scope="col">{{ $t('form.table.EURAL-code') }}</th>
            <th scope="col">{{ $t('form.table.physical') }}</th>
            <th scope="col">{{ $t('form.table.chemical') }}</th>
            <th scope="col">{{ $t('form.table.packaging_type') }}</th>
            <th scope="col">{{ $t('form.table.amount_of_packages') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in formRef.wasteIdentifications" :key="index">
            <template v-if="row.dangerous">
              <td>
                {{ row.euralCode ? row.euralCode.substring(6).replaceAll('_', ' ') + '*' : '' }}
              </td>
              <td>
                {{ row.physical }}
              </td>
              <td>
                {{ row.chemical }}
              </td>
              <td>
                {{ row.packagingType }}
              </td>
              <td>
                {{ row.amountOfPackages }}
              </td>
            </template>
          </tr>
          </tbody>
        </table>
        <table v-else class="table table-striped table-striped-columns">
          <tbody>
          <tr v-for="(row, index) in formRef.wasteIdentifications" :key="index">
            <td>
              <table v-if="row.dangerous" class="table">
                <tbody>
                <tr>
                  <th>{{ $t('form.table.EURAL-code') }}</th>
                  <td>{{ row.euralCode ? row.euralCode.substring(6).replaceAll('_', ' ') + '*' : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.physical') }}</th>
                  <td>{{ row.physical }}</td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.chemical') }}</th>
                  <td>{{ row.chemical }}</td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.packaging_type') }}</th>
                  <td>{{ row.packagingType }}</td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.amount_of_packages') }}</th>
                  <td>
                    {{ row.amountOfPackages }}
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.notes')" :type="isDangerous ? 'col-lg-6' : 'col-lg-12'"
                    style="white-space: pre-wrap">
        {{ formRef.notes }}
      </ui-view-card>
    </div>
    <div class="row">
      <ui-view-card-signature :form="form" :processor-info="processorInfo" @signed="$emit('signed')" :uuid="uuid"
                              :sharelink="sharelink"/>
      <ui-view-card-transport :id="formRef.id" :start="formRef.startPoint" :end="formRef.endPoint"
                              :key="formRef.startPoint && formRef.endPoint ? formRef.startPoint.timestamp + formRef.endPoint.timestamp : 'transportKey'"/>
    </div>
  </div>
</template>

<script>
import UiViewCardSignature from '@/components/ui-view-card-signature'
import UiViewCardTransport from '@/components/ui-view-card-transport'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {computed, ref} from 'vue'
import {DateFormatter} from '@/services/dateFormatter'
import languages from '@/types/languages'
import OrganisationModel from '@/models/OrganisationModel'
import session from '@/util/session'
import OrganisationState from '@/types/organisationState'
import weightEditedChecker from '@/services/weightEditedChecker'
import WeightEditedIcon from '@/components/weight-edited-icon.vue'

export default {
  name:       'WasteIdentificationFormView',
  components: {
    WeightEditedIcon,
    UiViewCardAddress,
    UiViewCard,
    UiViewCardTransport,
    UiViewCardSignature,
  },
  props:      {
    form:          {
      type:     Object,
      required: true,
    },
    processorInfo: {
      type:    Object,
      default: () => {
      },
    },
    uuid:          {
      type:    String,
      default: '',
    },
    sharelink:     {
      type:    Boolean,
      default: false,
    },
  },
  emits:      ['signed'],
  setup:      (props) => {
    const formRef = ref(props.form)
    if (formRef.value.producerIdentification === null) {
      formRef.value.producerIdentification = OrganisationModel.empty
    }
    if (formRef.value.processorIdentification === null) {
      formRef.value.processorIdentification = OrganisationModel.empty
    }
    if (formRef.value.organiserIdentification === null) {
      formRef.value.organiserIdentification = OrganisationModel.empty
    }
    if (formRef.value.transporterIdentifications.length === 0) {
      formRef.value.transporterIdentifications.push(OrganisationModel.empty)
    }

    const date = computed(() => {
      return DateFormatter.formatDate(props.form.transportDate)
    })

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(props.form.lastUpdate)
    })

    const getEuralCodeText = (row) => {
      if (!row.euralCode) {
        return ''
      }

      let result = row.euralCode.substring(6).replaceAll('_', ' ')
      if (row.dangerous) {
        result += '*'
      }

      return result
    }

    const isDangerous = computed(() => {
      let dangerous = false
      props.form.wasteIdentifications.forEach((row) => {
        if (row.dangerous) {
          dangerous = true
        }
      })
      return dangerous
    })

    const weightEdited = computed(() => {
      return weightEditedChecker.check(props.form)
    })

    const org  = session.getOrganisation()
    const demo = computed(() => {
      return (org && org.organisationState === OrganisationState.ONBOARDING) || formRef.value.demo
    })

    return {
      formRef,
      date,
      updateDate,
      languages,
      getEuralCodeText,
      isDangerous,
      weightEdited,
      demo,
    }
  },
}
</script>
