import {defineStore} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import {useFormListStore} from "@/stores/FormListStore";

export const useDeleteStore = defineStore('delete', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const formListStore = useFormListStore()
  const ids = ref([])
  const list = ref(false)

  const deleteModal = ref('deleteModal')

  const showDelete = (selectedIDs, isList) => {
    ids.value = selectedIDs
    list.value = isList
    deleteModal.value.modal.open()
  }

  const deleteFunc = async () => {
    if (!list.value) {
      await deleteOne()
    } else {
      await deleteList()
    }
  }

  const deleteOne = async () => {
    const result = await backend.delete(`api/documents/${ids.value[0]}`)
    if (result.status === 200) {
      notifier.success('toast.deleting_successful')
      setTimeout(() => {
        formStore.goToList()
      }, 2000)
    } else {
      notifier.error('toast.deleting_failed')
    }
    deleteModal.value.modal.close()
  }

  const deleteList = async () => {
    try {
      if (ids.value.length > 0) {
        const result = await backend.post('api/documents/batch', {
          'action': 'DELETE',
          'ids': ids.value,
        })
        if (result.status === 200) {
          notifier.success('toast.deleting_successful')
        } else {
          notifier.error('toast.deleting_failed')
        }
      }
    } catch (err) {
      notifier.error('toast.deleting_failed')
    } finally {
      deleteModal.value.modal.close()
      formListStore.reload()
    }
  }

  return {
    deleteModal,
    showDelete,
    deleteFunc,
    ids,
  }
})