<template>
  <layout>
    <template #header>
      <ui-header :title="$t('grondbank.list.grondbank')"/>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="formListStore.resetGrid()">{{
            $t('general.reset_filters')
          }}
        </button>
      </div>
    </div>
    <ui-content>
      <ag-grid-vue
          :serverSideDatasource="grid.datasource('api/documents/list?documentType=SOIL_BANK')"
          :grid-options="grid.defaultOptions"
          :columnDefs="columnDefs"
          class="ag-theme-quartz"
          style="min-height:100%"
          @row-clicked="formListStore.formRowClick"
          @grid-ready="formListStore.onGridReady"
          @grid-pre-destroyed="formListStore.onGridPreDestroyed"
          @filter-changed="formListStore.onFilterChanged"
          :initialState="initialState"
      />
    </ui-content>
  </layout>
</template>

<script>

import {inject, onBeforeUnmount} from 'vue'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import {DateFormatter} from '@/services/dateFormatter'
import FormState from '@/types/formState'
import '@vuepic/vue-datepicker/dist/main.css'
import DocumentType from '@/types/documentType'
import grid from '@/util/grid'
import stateColorRenderer from '@/util/stateColorRenderer'
import {useFormListStore} from '@/stores/FormListStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'GrondbankListSupport',
  computed:   {
    grid() {
      return grid
    },
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    UiContent,
    UiHeader,
    Layout,
    // eslint-disable-next-line
    stateColorRenderer,
    UiBreadcrumbs,
    AgGridVue,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK]
    const $t          = inject('$t')

    const formListStore = useFormListStore()
    formListStore.init('grondbank-list-support')

    const resize = () => {
      setTimeout(() => {
        formListStore.api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }

    window.addEventListener('resize', resize)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resize)
      formListStore.api.value = null
    })

    const columnDefs = [
      {
        headerName:  $t('forms.list.table.key'),
        field:       'id',
        valueGetter: (params) => {
          return 'DGF-GB-' + params.data.id
        },
      },
      {
        headerName:   $t('forms.list.table.organisation'),
        field:        'organisationId',
        filterParams: {
          filterOptions:    ['equals'],
          maxNumConditions: 1,
        },
      },
      {
        headerName:     $t('forms.list.table.date'),
        filter:         'agDateColumnFilter',
        field:          'transportDate',
        maxWidth:       250,
        filterParams:   {
          minValidYear:  2000,
          maxValidYear:  2099,
          filterOptions: ['inRange'],
        },
        valueGetter:    (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        },
      },
      {
        headerName: $t('forms.list.table.description'),
        field:      'wasteDescription',
      },
      {
        headerName: $t('forms.list.table.permissionId'),
        field:      'permissionId',
      },
      {headerName: $t('forms.list.table.from'), field: 'from'},
      {headerName: $t('forms.list.table.to'), field: 'to'},
      {
        headerName:   $t('forms.list.table.state'),
        field:        'state',
        maxWidth:     400,
        cellRenderer: 'stateColorRenderer',
        filter:       'agSetColumnFilter',
        filterParams: {
          values:         FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          },
        },
      },
    ]

    return {
      formListStore,
      ...storeToRefs(formListStore),
      breadcrumbs,
      columnDefs,
    }
  },
}
</script>
