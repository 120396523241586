<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="() => { return mailInput !== ''} ">
    <template #title>
      {{ $t('share_link.control_qr.title') }}
    </template>
    <template #default>
      {{ $t('share_link.control_qr.text') }}
      <div class="d-flex justify-content-center">
        <img id="qr-canvas" :src="controlQR" alt="qrSrc"/>
      </div>
      {{ $t('share_link.control_qr.text2') }}
      <div class="d-flex justify-content-center">
        <ui-view-card-field :label="$t('form.labels.email')" v-model="mailInput"/>
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {ref, watch} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field'
import {useControlStore} from '@/stores/modals/ControlStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormControlQRModal',
  components: {UiViewCardField, UiModal},
  props:      {
    callback: {
      type: Function,
    },
    url:      {
      type: String,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const controlStore = useControlStore()

    const cb = async () => {
      if (props.callback) {
        await props.callback(controlStore.mailInput.value)
      } else {
        await controlStore.sendControlMail()
      }
    }

    watch(() => props.url, (url) => {
      if (url) {
        // get control sharelink qr code
        setTimeout(() => {
          const QRCode = require('qrcode')
          QRCode.toDataURL(props.url)
              .then(url => {
                controlStore.controlQR.value = url
              })
              .catch(err => {
                console.error(err)
              })
        }, 100)
      }
    })

    return {
      modal,
      controlStore,
      ...storeToRefs(controlStore),
      cb,
    }
  },
}
</script>
