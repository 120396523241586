<template>
  <ui-modal id="changeLogModal" ref="modal" :size="'lg'" no-confirm>
    <template #title>
      {{ $t('waste_identification.change_log_modal.title') }}
    </template>
    <template #default>
      <template v-if="logs.length === 0">
        {{ $t('waste_identification.change_log_modal.none') }}
      </template>
      <template v-else>
        <div v-for="(log, idx) in logs" :key="log.id">
          {{
            $t('waste_identification.change_log_modal.change_log_1', {
              'name': log.modifier,
              'date': DateFormatter.formatDateTime(log.timestamp),
            })
          }}
          <br/>
          <template v-if="!log.oldValue">
            {{
              $t('waste_identification.change_log_modal.change_log_added', {
                'field': $t(log.field),
                'to':    log.newValue,
              })
            }}
          </template>
          <template v-else-if="!log.newValue">
            {{
              $t('waste_identification.change_log_modal.change_log_removed', {
                'field': $t(log.field),
                'from':  log.oldValue,
              })
            }}
          </template>
          <template v-else>
            {{
              $t('waste_identification.change_log_modal.change_log_changed', {
                'field': $t(log.field),
                'from':  log.oldValue,
                'to':    log.newValue,
              })
            }}
          </template>
          <hr v-if="idx < logs.length-1 "/>
        </div>
      </template>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import {DateFormatter} from '@/services/dateFormatter'
import {useChangeLogStore} from '@/stores/modals/ChangeLogStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormChangeLogModal',
  components: {
    UiModal,
  },
  setup:      () => {
    const modal          = ref('modal')
    const changeLogStore = useChangeLogStore()

    return {
      modal,
      DateFormatter,
      ...storeToRefs(changeLogStore),
    }
  },
}
</script>
