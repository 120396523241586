import {defineStore} from 'pinia'
import {computed, ref} from 'vue'
import DocumentType from '@/types/documentType'
import ContactOrganisationType from '@/types/contactOrganisationType'
import grid from '@/util/grid'

export const useExternalStore = defineStore('external', () => {
  const formType    = ref()
  const contactType = ref()

  const init = (fType, cType) => {
    formType.value    = fType
    contactType.value = cType
  }

  const setContactType = (cType) => {
    contactType.value = cType
  }

  const dataSource = computed(() => {
    let url
    switch (formType.value) {
      case DocumentType.WASTE_IDENTIFICATION: {
        switch (contactType.value) {
          case ContactOrganisationType.ORGANISER: {
            url = '/api/iop/digid/list?role=IHM'
            break
          }
          case ContactOrganisationType.PRODUCER: {
            url = '/api/iop/digid/list?role=PRODUCER'
            break
          }
          case ContactOrganisationType.PROCESSOR: {
            url = '/api/iop/digid/list?role=PROCESSOR'
            break
          }
          case ContactOrganisationType.TRANSPORTER: {
            url = '/api/iop/digid/list?role=TRANSPORTER'
            break
          }
          default: {
            console.error('Unknown contact type')
            break
          }
        }
      }
    }
    return grid.datasource(url)
  })

  return {
    init,
    formType,
    contactType,
    setContactType,
    dataSource,
  }
})