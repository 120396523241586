<template>
  <layout>
    <template #header>
      <ui-header :title="title" :back-route="backRoute"/>
    </template>
    <ui-content class="pb-1">
      <spinner/>
    </ui-content>
  </layout>
</template>

<script>

import UiHeader from '@/components/ui-header.vue'
import UiContent from '@/components/ui-content.vue'
import Spinner from '@/components/spinner.vue'
import Layout from '@/components/layout.vue'

export default {
  name:       'LoadingLayout',
  components: {Layout, Spinner, UiContent, UiHeader},
  props:      {
    title:     {
      type:    String,
      default: '',
    },
    backRoute: {
      type: String,
    },
  },
  setup:      () => {
    return {}
  },
}

</script>