import {defineStore} from 'pinia'
import {computed, ref} from 'vue'
import {useFormStore} from '@/stores/FormStore'
import FormState from '@/types/formState'
import session from '@/util/session'

export const useFormListStore = defineStore('formList', () => {
	const api          = ref(null)
	const selection    = ref([])
	const gridKey      = ref('')
	const initialState = ref({})

	const init = (key) => {
		gridKey.value = key
		let formState = session.loadFormState(key)
		if (formState === null) {
			formState = {filter: {filterModel: initialFilter}}
		}
		initialState.value = ref(formState)
	}

	const initialFilter = {
		'state': {
			filterType: 'set',
			values:     FormState.getAll(false),
		},
	}

	const onGridReady = (params) => {
		api.value = params.api
		api.value.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
		api.value.sizeColumnsToFit({'defaultMinWidth': 200})
	}

	const onGridPreDestroyed = (params) => {
		session.saveFormState(gridKey.value, params.state)
	}
	const onFilterChanged    = (event) => {
		session.saveFormState(gridKey.value, event.api.getState())
	}

	const onFirstDataRendered = () => {
		api.value.sizeColumnsToFit({'defaultMinWidth': 100})
	}

	const draftSelected         = ref(false)
	const notShareableSelected  = ref(false)
	const notFinishableSelected = ref(false)
	const demoSelected          = ref(false)
	const countSelectedDrafts   = ref(0)
	const refreshSelection      = (params) => {
		selection.value             = params.api.getSelectedRows()
		draftSelected.value         = selection.value.filter((item) => {
			return FormState.isDraft(item.state)
		}).length > 0
		countSelectedDrafts.value   = selection.value.filter((item) => {
			return FormState.isDraft(item.state)
		}).length
		notShareableSelected.value  = selection.value.filter((item) => {
			return !FormState.canShare(item.state)
		}).length > 0
		notFinishableSelected.value = selection.value.filter((item) => {
			return !FormState.canFinish(item.state)
		}).length > 0
		demoSelected.value          = selection.value.filter((item) => {
			return item.demo
		}).length > 0
	}

	const formStore    = useFormStore()
	const formRowClick = async (event) => {
		if (event.data) {
			await formStore.goToView(event.data.id)
		}
	}

	const reload = () => {
		api.value.refreshServerSide({purge: true})
		api.value.deselectAll()
	}

	const resetGrid = () => {
		api.value.setFilterModel({
			'state': {
				filterType: 'set',
				values:     FormState.getAll(false),
			},
		})
		api.value.onFilterChanged()
		session.saveFormState(gridKey.value, null)
	}

	const selectionIDs = computed(() => {
		return selection.value.map((item) => {
			return item.id
		})
	})

	const shareableSelectionIDs = computed(() => {
		return selection.value.filter((item) => {
			return FormState.canShare(item.state)
		}).map((item) => {
			return item.id
		})
	})

	const finishableSelectionIDs = computed(() => {
		return selection.value.filter((item) => {
			return FormState.canFinish(item.state)
		}).map((item) => {
			return item.id
		})
	})

	const notDraftSelectionIDs = computed(() => {
		return selection.value.filter((item) => {
			return !FormState.isDraft(item.state)
		}).map((item) => {
			return item.id
		})
	})

	const draftSelectionIDs = computed(() => {
		return selection.value.filter((item) => {
			return item.state === FormState.DRAFT
		}).map((item) => {
			return item.id
		})
	})

	const draftAndEmptySelectionIDs = computed(() => {
		return selection.value.filter((item) => {
			return FormState.isDraft(item.state)
		}).map((item) => {
			return item.id
		})
	})

	return {
		init,
		onGridReady,
		api,
		initialState,
		onGridPreDestroyed,
		onFilterChanged,
		onFirstDataRendered,
		selection,
		selectionIDs,
		shareableSelectionIDs,
		notDraftSelectionIDs,
		draftSelectionIDs,
		draftAndEmptySelectionIDs,
		finishableSelectionIDs,
		refreshSelection,
		resetGrid,
		reload,
		formRowClick,
		draftSelected,
		notShareableSelected,
		notFinishableSelected,
		countSelectedDrafts,
	}
})