import DocumentType from '../types/documentType'
import CountryType from '@/types/countryType'
import DateValidator from "@/util/dateValidator";

const checkAlways = (form) => {
  const formElement = $('#annex-7-form')[0]
  if (!formElement) {
    return 'toast.form_missing'
  }
  if (!formElement.checkValidity()) {
    formElement.reportValidity()
    // something in the form is not valid
    return 'toast.form_incomplete'
  }
  if (!validWasteAmounts(form)) {
    return 'toast.invalid_amount_ton_m3'
  }
  if (!validWasteIdentifications(form.wasteIdentifications)) {
    return 'toast.invalid_waste_identifications'
  }
  if (!validCountries(form.countriesConcerned)) {
    return 'toast.invalid_countries'
  }
}

const validWasteAmounts = (form) => {
  return !(!form.amountTon && !form.amountM3);
}

const validWasteIdentifications = (wasteIdentifications) => {
  if (!(wasteIdentifications.length > 0)) {
    return false
  }
  for (let i = 0; i < wasteIdentifications.length; i++) {
    if (!wasteIdentifications[i].type || !wasteIdentifications[i].value) {
      return false
    }
  }
  return true
}

const validCountries = (countries) => {
  if (!(countries.length >= 2)) {
    return false
  }
  const exportCountries = countries.filter((c) => {
    return c.type === CountryType.EXPORT
  })
  if (!(exportCountries.length === 1) || !exportCountries[0].value) {
    return false
  }
  const destinationCountries = countries.filter((c) => {
    return c.type === CountryType.DESTINATION
  })
  return !(!(destinationCountries.length === 1) || !destinationCountries[0].value)
}

const checkToSign = (form) => {
  const localOffset = new Date().getTimezoneOffset() / -60 // offset to UTC in hours
  let date = new Date(form.transportDate)
  date.setHours(date.getHours() + localOffset)
  date = date.setUTCHours(0, 0, 0, 0)
  const today = new Date().setUTCHours(0, 0, 0, 0)
  // if date is in past
  if (date < today) {
    return 'toast.date_in_past'
  }
}

const checkMaxWeight = (form) => {
  if (form.amountTon > 50 || (form.amountM3 * 1.6) > 50) {
    return 'warning.heavy_load'
  }
}

const Annex7Model = {
  empty: {
    id: null,
    externalId: null,
    type: DocumentType.ANNEX_7,
    state: 'DRAFT',
    notes: '',
    transportDate: null,
    removalOperation: null,
    description: null,
    amount: null,
    producerIdentification: {
      language: 'NL',
      address: null,
      shipmentAddress: null,
    },
    organiserIdentification: {
      language: 'NL',
      address: null,
    },
    transporterIdentifications: [
      {
        language: 'NL',
        address: null,
      },
    ],
    processorIdentification: {
      language: 'NL',
      address: null,
    },
    recoveryFacility: {},
    wasteIdentifications: [{type: null, value: null}],
    countriesConcerned: [],
    organiserSignature: null,
    processorSignature: null,
    recoveryFacilitySignature: null,
  },
  formCheck: (form, allChecks = false) => {
    let r = checkAlways(form)
    if (r) {
      return r
    }
    if (allChecks) {
      r = checkToSign(form)
      if (r) {
        return r
      }
    }
  },
  warningChecks: (form) => {
    return checkMaxWeight(form)
  },
  modelCheck: (model, options = {}) => {
    const date = (model.transportDate && (options?.allowPast || !DateValidator.dateIsInPast(model.transportDate)))
    const producer = Annex7Model.modelProducer(model)
    const processor = Annex7Model.modelProcessor(model)
    const organiser = Annex7Model.modelOrganiser(model)
    const transporters = Annex7Model.modelTransporters(model)
    const recoveryFacility = Annex7Model.modelRecoveryFacility(model)
    const waste = Annex7Model.modelCheckWaste(model)
    const countries = Annex7Model.modelCheckCountries(model)
    return !!(date &&
        producer &&
        processor &&
        organiser &&
        transporters &&
        recoveryFacility &&
        waste &&
        countries
    )
  },
  modelProducer: (model) => {
    return !!(model.producer?.name &&
        Annex7Model.modelCheckAddress(model.producer?.address) &&
        model.producer?.contactPerson &&
        model.producer?.email &&
        model.producer?.language
    )
  },
  modelProcessor: (model) => {
    return !!(model.processor?.name &&
        Annex7Model.modelCheckAddress(model.processor?.address) &&
        model.processor?.contactPerson &&
        model.processor?.email &&
        model.processor?.language
    )
  },
  modelOrganiser: (model) => {
    return !!(model.organiser?.name &&
        Annex7Model.modelCheckAddress(model.organiser?.address) &&
        model.organiser?.contactPerson &&
        model.organiser?.email &&
        model.organiser?.language
    )
  },
  modelTransporters: (model) => {
    if (model.transporters.length === 0) {
      return false
    }
    for (let i = 0; i < model.transporters.length; i++) {
      if (!Annex7Model.modelTransporter(model.transporters[i])) {
        return false
      }
    }
    return true
  },
  modelTransporter: (transporter) => {
    return !!(transporter?.name &&
        Annex7Model.modelCheckAddress(transporter?.address) &&
        transporter?.contactPerson &&
        transporter?.email &&
        transporter?.language &&
        transporter?.meansOfTransport &&
        transporter?.transportDate
    )
  },
  modelRecoveryFacility: (model) => {
    return !!(model.recoveryFacility?.name &&
        Annex7Model.modelCheckAddress(model.recoveryFacility?.address) &&
        model.recoveryFacility?.contactPerson &&
        model.recoveryFacility?.email &&
        // model.recoveryFacility?.language &&
        model.recoveryFacility?.annex7RecoveryDestinationType
    )
  },
  modelCheckWaste: (model) => {
    return !!(model.wasteIdentifications &&
        Annex7Model.modelCheckWasteIdentification(model) &&
        (model.amountTon || model.amountM3) &&
        model.removalOperation
    )
  },
  modelCheckWasteIdentification: (model) => {
    if (model.wasteIdentifications.length === 0) {
      return false
    }
    for (let i = 0; i < model.wasteIdentifications.length; i++) {
      const wasteIdentification = model.wasteIdentifications[i]
      if (!wasteIdentification?.type || !wasteIdentification?.value) {
        return false
      }
    }
    return true
  },
  modelCheckCountries: (model) => {
    let exportFound = false
    let destinationFound = false
    let allFilledIn = true
    for (let i = 0; i < model.countriesConcerned.length; i++) {
      const country = model.countriesConcerned[i]
      if (country.type === CountryType.EXPORT) {
        exportFound = true
      }
      if (country.type === CountryType.DESTINATION) {
        destinationFound = true
      }
      if (!country.value) {
        allFilledIn = false
      }
    }
    return exportFound && destinationFound && allFilledIn
  },
  modelCheckAddress: (address) => {
    return !!(address &&
        address.street &&
        address.number &&
        address.zip &&
        address.city &&
        address.country)
  }
}

export default Annex7Model
