<template>
  <ui-modal ref="modal" :on-open="summaryStore.fetchSummary" no-confirm>
    <template #title>
      {{ $t('forms.summary_modal.title') }}
    </template>
    <template #default>
      <template v-if="loading">
        <spinner/>
      </template>
      <template v-else>
        <ui-view-card-field
            v-for="(field, index) in fields"
            :key="index"
            :label="field.label"
            :model-value="field.value"
            readonly
        />
        <hr/>
        <div class="d-flex justify-content-center">
          <button id="generateQRCode" type="button" :class="exporting ? 'disabled' : ''"
                  class="btn btn-outline-secondary" @click="summaryStore.exportFunc()"
          >
            {{ $t('forms.summary_modal.export_rides') }}
          </button>
        </div>
      </template>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field.vue'
import Spinner from '@/components/spinner.vue'
import {useSummaryStore} from '@/stores/modals/SummaryStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormSummaryModal',
  components: {Spinner, UiViewCardField, UiModal},
  setup:      () => {
    const modal        = ref('modal')
    const summaryStore = useSummaryStore()

    return {
      summaryStore,
      ...storeToRefs(summaryStore),
      modal,
    }
  },
}
</script>
