<template :key="demo + user.email">
  <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
    <div class="container-fluid">
      <a style="cursor: pointer" class="navbar-brand" @click="reload()">
        <img src="../assets/logo.svg" alt="" width="30" height="30" class="d-inline-block align-bottom">
        DigiForm
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              v-if="$roles(roles.ALL())">
        <span class="navbar-toggler-icon"/>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav" v-if="$roles(roles.ALL())">
        <ul class="navbar-nav me-auto">
          <li class="nav-item" id="formsList" v-if="formTypes.length === 1">
            <router-link :to="{ name: formTypes[0].routeName }" class="nav-link">{{ $t('general.forms') }}</router-link>
          </li>
          <li class="nav-item dropdown" id="formsList" v-if="formTypes.length > 1">
            <a id="dropdownForms" aria-expanded="false"
               class="d-flex align-items-center text-decoration-none dropdown-toggle nav-link" data-bs-toggle="dropdown"
               href="#">
              {{ $t('general.forms') }}
            </a>
            <ul aria-labelledby="dropdownForms"
                class="dropdown-menu dropdown-menu-end dropdown-menu-dark text-small shadow">
              <li
                v-for="formType in formTypes"
                class="nav-item"
                :id="formType.routeName"
                :key="formType.routeName"
              >
                <router-link :to="{ name: formType.routeName }" class="dropdown-item">{{
                    $t(formType.label)
                  }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" id="externalList" v-if="$flag('EXTERNAL')">
            <a id="dropdownExternal" aria-expanded="false"
               class="d-flex align-items-center text-decoration-none dropdown-toggle nav-link" data-bs-toggle="dropdown"
               href="#">
              {{ $t('general.external') }}
            </a>
            <ul aria-labelledby="dropdownExternal"
                class="dropdown-menu dropdown-menu-end dropdown-menu-dark text-small shadow">
              <li class="nav-item" id="externalWasteIdentificationList">
                <router-link :to="{ name: 'ExternalWasteIdentificationList'}" class="dropdown-item">
                  {{ $t('general.waste_identification') }}
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="$orgType(OrganisationType.REGULAR) && $roles([roles.ORGANISATION_ADMIN, roles.USER])"
              class="nav-item dropdown">
            <a id="dropdownTemplates" aria-expanded="false"
               class="d-flex align-items-center text-decoration-none dropdown-toggle nav-link" data-bs-toggle="dropdown"
               href="#">
              {{ $t('general.templates') }}
            </a>
            <ul aria-labelledby="dropdownTemplates"
                class="dropdown-menu dropdown-menu-end dropdown-menu-dark text-small shadow">
              <li class="nav-item" id="organisationsList">
                <router-link :to="{ name: 'OrganisationsList'}" class="dropdown-item">
                  {{ $t('general.organisations') }}
                </router-link>
              </li>
              <li class="nav-item" id="driversList">
                <router-link :to="{ name: 'DriversList'}" class="dropdown-item">{{ $t('general.drivers') }}
                </router-link>
              </li>
              <li class="nav-item" id="WasteIdentificationTemplatesList">
                <router-link :to="{ name: 'WasteIdentificationTemplatesList'}" class="dropdown-item">
                  {{ $t('general.wastes') }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" id="formsList" v-if="$flag('MATIS') && $roles([roles.ORGANISATION_ADMIN, roles.USER])">
            <router-link :to="{ name: 'MatisList'}" class="nav-link">
              {{ $t('general.matis') }}
              <span
                v-if="hasMatisErrors"
                class="color-dot-dark-red"
                :style="{
                  'transform': 'translate(-5px, -10px)',
                  'margin-left': 0,
                  'margin-right': 0,
                }"
                :title="$t('general.matis_errors')"/>
            </router-link>
          </li>
          <li class="nav-item dropdown" v-if="$roles([roles.ORGANISATION_ADMIN])">
            <a id="dropdownOrgAdmin" aria-expanded="false"
               class="d-flex align-items-center text-decoration-none dropdown-toggle nav-link" data-bs-toggle="dropdown"
               href="#">
              {{ $t('general.my_organisation') }}
            </a>
            <ul aria-labelledby="dropdownOrgAdmin"
                class="dropdown-menu dropdown-menu-end dropdown-menu-dark text-small shadow">
              <li class="nav-item" id="organisation">
                <router-link v-if="$roles([roles.ORGANISATION_ADMIN])" :to="{ name: 'MyOrganisationView'}"
                             class="dropdown-item">{{ $t('general.details') }}
                </router-link>
              </li>
              <li class="nav-item" id="usersList">
                <router-link v-if="$roles([roles.ORGANISATION_ADMIN])" :to="{ name: 'UsersList'}" class="dropdown-item">
                  {{ $t('general.users') }}
                </router-link>
              </li>
              <li class="nav-item" id="linkedOrgList">
                <router-link v-if="$roles([roles.ORGANISATION_ADMIN]) && $flag('LINKED_ORGANISATIONS')"
                             :to="{ name: 'MyOrganisationLinkedOrganisations'}" class="dropdown-item">
                  {{ $t('general.linked_organisations') }}
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="$roles([roles.SUPPORT])" class="nav-item dropdown">
            <a id="dropdownSysAdmin" aria-expanded="false"
               class="d-flex align-items-center text-decoration-none dropdown-toggle nav-link" data-bs-toggle="dropdown"
               href="#">
              {{ $t('topbar.sys_admin_settings') }}
            </a>
            <ul aria-labelledby="dropdownSysAdmin"
                class="dropdown-menu dropdown-menu-end dropdown-menu-dark text-small shadow">
              <li class="nav-item" id="sys-dashboard">
                <router-link v-if="$roles([roles.SUPPORT])" :to="{ name: 'SysDashboard'}" class="dropdown-item">
                  {{ $t('topbar.sys_admin.dashboard') }}
                </router-link>
              </li>
              <li class="nav-item" id="sys-orglist">
                <router-link v-if="$roles([roles.SUPPORT])" :to="{ name: 'SysOrgList'}" class="dropdown-item">
                  {{ $t('topbar.sys_admin.organisations') }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="$roles([roles.SUPPORT], false)">
            <a id="dropdownSupport" aria-expanded="false"
               class="d-flex align-items-center text-decoration-none dropdown-toggle nav-link" data-bs-toggle="dropdown"
               href="#">
              {{ $t('topbar.support.support') }}
            </a>
            <ul aria-labelledby="dropdownSupport"
                class="dropdown-menu dropdown-menu-end dropdown-menu-dark text-small shadow">
              <li class="nav-item" id="support-dashboard">
                <router-link v-if="$roles([roles.SUPPORT])" :to="{ name: 'SupportMatis'}" class="dropdown-item">
                  {{ $t('topbar.support.matis') }}
                </router-link>
              </li>
              <li class="nav-item" id="support-dashboard">
                <router-link v-if="$roles([roles.SUPPORT])" :to="{ name: 'SupportMails'}" class="dropdown-item">
                  {{ $t('topbar.support.mails') }}
                </router-link>
              </li>
              <li class="nav-item" id="support-translations">
                <router-link v-if="$roles([roles.SUPPORT])" :to="{ name: 'SupportTranslations'}" class="dropdown-item">
                  {{ $t('topbar.support.translations') }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <div class="dropdown navbar-nav" v-if="$roles(roles.ALL())">
          <span class="d-flex align-items-center nav-link text-decoration-none" v-if="organisations.length === 1">
            {{ getCurrentOrgName }}
          </span>
          <a v-if="organisations.length > 1" id="dropdownOrganisations"
             class="d-flex align-items-center nav-link text-decoration-none dropdown-toggle" data-bs-toggle="dropdown"
             href="#">
            {{ getCurrentOrgName }}
          </a>
          <ul aria-labelledby="dropdownOrganisations"
              class="dropdown-menu dropdown-menu-end dropdown-menu-dark text-small shadow">
            <li v-for="org in organisations" :key="org.id">
              <a class="dropdown-item" style="cursor: pointer" @click="switchOrganisation(org)">{{ org.name }}</a>
            </li>
          </ul>
          <a id="dropdownUser1" class="d-flex align-items-center nav-link text-decoration-none dropdown-toggle"
             data-bs-toggle="dropdown"
             href="#">
            {{ getName() ?? $t('topbar.my_user') }}
          </a>
          <ul aria-labelledby="dropdownUser1"
              class="dropdown-menu dropdown-menu-end dropdown-menu-dark text-small shadow">
            <li>
              <router-link :to="{ name: 'Profile', force: true}" class="dropdown-item">{{ $t('general.profile') }}
              </router-link>
            </li>
            <li v-if="$orgType(OrganisationType.REGULAR)">
              <a class="dropdown-item" href="https://digiform.be/instructievideos/"
                 target="_blank">{{ $t('general.documentation') }}</a>
            </li>
            <li v-if="$orgType(OrganisationType.REGULAR)">
              <a
                v-if="$flag('SWAGGER') && !roles.hasOnlyRole(roles.DRIVER)"
                class="dropdown-item"
                :href="url + '/api/documentation'"
                target="_blank"
              >{{ $t('general.api_documentation') }}</a>
            </li>
            <li v-if="$orgType(OrganisationType.REGULAR)">
              <a class="dropdown-item" href="https://digiform.be/laatste-wijzigingen-aan-digiform/"
                 target="_blank">{{ $t('general.whatsnew') }}</a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" :class="loggingOut ? 'disabled' :''" style="cursor: pointer"
                 @click="logout()">
                {{ $t('topbar.sign_out') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-white position-absolute translate-middle top-50 start-50" v-if="demo">DEMO</div>
    <div class="text-white position-absolute translate-middle top-50 start-50" v-if="inactive">INACTIVE</div>
  </nav>
  <nav v-if="showErrorBanner" class="navbar text-white justify-content-center navbar-banner-error">
    <span v-html="errorBannerText"/>
    &nbsp;
    <i class="bi-x fs-4 pb-1" style="cursor: pointer" @click="hideErrorBanner()"/>
  </nav>
  <nav v-if="showInfoBanner" class="navbar text-white justify-content-center navbar-banner-info">
    <span v-html="infoBannerText"/>
    &nbsp;
    <i class="bi-x fs-4 pb-1" style="cursor: pointer" @click="hideInfoBanner()"/>
  </nav>
  <header class="bg-light" v-if="standardHeader">
    <slot name="header"/>
  </header>
  <slot name="altHeader"/>
  <main>
    <toast/>
    <slot/>
  </main>
  <modals/>
</template>

<script>
import session from '@/util/session'
import {useRouter} from 'vue-router'
import Toast from '@/components/toast'
import {computed, inject, onBeforeMount, ref} from 'vue'
import OrganisationType from '@/types/organisationType'
import backend from '@/util/backend'
import OrganisationState from '@/types/organisationState'
import roles from '@/util/roles'
import Modals from '@/components/Modals.vue'

export default {
  name:       'Layout',
  computed:   {
    OrganisationType() {
      return OrganisationType
    },
  },
  props:      {
    standardHeader: {
      type:    Boolean,
      default: true,
    },
  },
  components: {Modals, Toast},
  setup:      () => {
    const router = useRouter()
    const $t     = inject('$t')

    const goHome = () => {
      router.push({name: 'Home'})
    }

    const getName = () => {
      const user = session.getUser()
      let name   = ''
      if (user) {
        name += user.firstName + ' ' + user.lastName
      } else {
        name += $t('topbar.my_user')
      }
      return name
    }

    const org             = session.getOrganisation()
    const errorBannerText = ref('')
    const showErrorBanner = ref(false)
    const demo            = ref(false)
    const inactive        = ref(false)
    if (org && org.organisationState === OrganisationState.ONBOARDING) {
      demo.value            = true
      errorBannerText.value = $t('banner.demo')
      showErrorBanner.value = JSON.parse(sessionStorage.getItem('digiform-show-error-banner')) ?? true
    } else if (org && org.organisationState === OrganisationState.INACTIVE && roles.hasOneOfRoles([
      roles.USER,
      roles.ORGANISATION_ADMIN,
    ])) {
      inactive.value        = true
      errorBannerText.value = $t('banner.inactive')
      showErrorBanner.value = JSON.parse(sessionStorage.getItem('digiform-show-error-banner')) ?? true
    } else if (session.getLowCreditsContract() && roles.hasOneOfRoles([roles.USER, roles.ORGANISATION_ADMIN])) {
      const remaining = session.getLowCreditsContract().currentCredits
      if (remaining === 0) {
        errorBannerText.value = $t('banner.none_remaining')
      } else {
        errorBannerText.value = $t('banner.low_credits', {remaining: remaining})
      }
      showErrorBanner.value = JSON.parse(sessionStorage.getItem('digiform-show-error-banner')) ?? true
    } else if (session.getOverdraftContract() && roles.hasOneOfRoles([roles.USER, roles.ORGANISATION_ADMIN])) {
      errorBannerText.value = $t('banner.overdraft')
      showErrorBanner.value = JSON.parse(sessionStorage.getItem('digiform-show-error-banner')) ?? true
    }

    const hideErrorBanner = () => {
      sessionStorage.setItem('digiform-show-error-banner', 'false')
      showErrorBanner.value = false
    }

    const hasMatisErrors = computed(() => {
      return org.hasMatisErrors
    })

    const today          = new Date()
    const infoBannerText = ref('')
    let infoBannerKey    = null

    const startSep = new Date('2024/09/03')
    const startOct = new Date('2024/10/02')
    if (today > startSep && today < startOct) {
      infoBannerText.value = $t('banner.release_notes.note_030924')
      infoBannerKey        = '030924'
    }

    const showInfoBanner = ref(false)
    if (infoBannerKey) {
      showInfoBanner.value = JSON.parse(localStorage.getItem(`digiform-show-info-banner-${infoBannerKey}`)) ?? true
    }
    const hideInfoBanner = () => {
      localStorage.setItem(`digiform-show-info-banner-${infoBannerKey}`, 'false')
      showInfoBanner.value = false
    }

    const user                    = ref(session.getUser())
    document.documentElement.lang = session.getLanguage()

    const reload = async () => {
      if (session.isAnonymousUser()) {
        location.reload()
      } else {
        goHome()
      }
    }

    const organisations = computed(() => {
      const result = []
      user.value.organisationRoles.forEach((o) => {
        result.push({id: o.organisation.id, name: o.organisation.name})
      })
      return result
    })

    const getCurrentOrgName = computed(() => {
      return session.getCurrentOrganisation().organisation.name
    })

    const switchOrganisation = (org) => {
      backend.put(`api/users/${user.value.id}/organisations/${org.id}`).then((result) => {
        if (result.status === 200) {
          session.setUser().then(() => {
            router.push({name: 'Home'})
          })
        }
      })
    }

    const loggingOut = ref(false)
    const logout     = () => {
      loggingOut.value = true
      router.push(`/logout`)
    }

    const url = window.location.origin

    const flag      = inject('$flag')
    const formTypes = ref([{routeName: 'WasteList', label: 'general.waste_identification'}])
    if (flag('ANNEX_7')) {
      formTypes.value.push({routeName: 'Annex7List', label: 'general.annex_7'})
    }
    if (flag('ECMR')) {
      formTypes.value.push({routeName: 'ECmrList', label: 'general.ecmr'})
    }
    if (flag('GRONDBANK')) {
      formTypes.value.push({routeName: 'GrondbankList', label: 'general.grondbank'})
    }
    if (flag('GRONDWIJZER')) {
      formTypes.value.push({routeName: 'GrondwijzerList', label: 'general.grondwijzer'})
    }

    onBeforeMount(() => {
      $(document.body).removeClass('modal-open')
      $('.modal-backdrop').remove()
    })

    return {
      getName,
      goHome,
      demo,
      inactive,
      errorBannerText,
      showErrorBanner,
      hideErrorBanner,
      infoBannerText,
      showInfoBanner,
      hideInfoBanner,
      reload,
      user,
      organisations,
      getCurrentOrgName,
      switchOrganisation,
      loggingOut,
      logout,
      url,
      formTypes,
      hasMatisErrors,
    }
  },
}
</script>
