import {defineStore, storeToRefs} from "pinia";
import {ref} from "vue";
import {useFormStore} from "@/stores/FormStore";
import backend from "@/util/backend";
import Notifier from "@/util/notifier";
import {useWarningStore} from "@/stores/modals/WarningStore";
import DocumentType from "@/types/documentType";
import WasteIdentificationModel from "@/models/forms/WasteIdentificationModel";
import session from "@/util/session";
import {useSharedLinkStore} from "@/stores/SharedLinkStore";

export const useEditWeightsStore = defineStore('editWeights', () => {
  const notifier = Notifier()
  const editWeightsModal = ref('editWeightsModal')
  const formRef = ref({})
  const wasteIdentifications = ref([])
  const warningStore = useWarningStore()
  const formStore = useFormStore()
  const sharedLinkStore = useSharedLinkStore()
  const {form} = session.isAnonymousUser() ? storeToRefs(sharedLinkStore) : storeToRefs(formStore)

  const showEditWeights = () => {
    formRef.value = JSON.parse(JSON.stringify(form.value))
    wasteIdentifications.value = formRef.value.wasteIdentifications
    editWeightsModal.value.modal.open()
  }

  const warningChecks = async () => {
    switch (form.value.type) {
      case DocumentType.WASTE_IDENTIFICATION: {
        return WasteIdentificationModel.warningChecks(formRef.value)
      }
    }
  }

  const saveEditWeights = async () => {
    const warning = await warningChecks()
    if (warning) {
      await warningStore.showWarning(warning, performSaveEditWeights)
      return
    }

    await performSaveEditWeights()
  }

  const performSaveEditWeights = async () => {
    if (session.isAnonymousUser()) {
      await performSaveEditWeightsAnon()
    } else {
      await performSaveEditWeightsAuth()
    }
  }

  const performSaveEditWeightsAuth = async () => {
    try {
      const result = await backend.put(`api/documents/${formRef.value.id}`, formRef.value)
      notifier.success('toast.successfully_saved')
      formStore.setForm(result.data)
    } catch (e) {
      notifier.error('toast.save_failed')
    } finally {
      editWeightsModal.value.modal.close()
    }
  }

  const performSaveEditWeightsAnon = async () => {
    try {
      const result = await backend.put(`api/sharedlinks/${sharedLinkStore.link.id}/document/${sharedLinkStore.link.documentId}`, formRef.value)
      notifier.success('toast.successfully_saved')
      sharedLinkStore.setForm(result.data)
    } catch (e) {
      notifier.error('toast.save_failed')
    } finally {
      editWeightsModal.value.modal.close()
    }
  }

  return {
    editWeightsModal,
    showEditWeights,
    wasteIdentifications,
    saveEditWeights,
  }
})