import IdentificationType from "@/types/identificationType";
import branchNumberValidator from "@/services/branchNumberValidator";
import companyNumberValidator from "@/services/companyNumberValidator";
import vatNumberValidator from "@/services/vatNumberValidator";

const identificationService = {
  vatPattern: '^((AT)U[0-9]{8}|(BE)(0|1)[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$',

  typesToValidate: [IdentificationType.VAT, IdentificationType.BRANCH_NUMBER, IdentificationType.COMPANY],

  validateIdentifications: (model) => {
    for (let i = 0; i < model.identifications.length; i++) {
      const identification = model.identifications[i]

      switch (identification.identificationType) {
        case IdentificationType.VAT: {
          identification.value = identification.value.toLocaleUpperCase()
          const regex = new RegExp(identificationService.vatPattern)
          if (!regex.test(identification.value)) {
            return false
          }
          const onlyNumbers = identification.value.replaceAll(/\D/g, '')
          if (!vatNumberValidator.validate(model?.address?.country, onlyNumbers)) {
            return false
          }
          break
        }
        case IdentificationType.BRANCH_NUMBER: {
          identification.value = identification.value.replaceAll(/\D/g, '')
          if (!branchNumberValidator.validate(model?.address?.country, identification.value)) {
            return false
          }
          break
        }
        case IdentificationType.COMPANY: {
          identification.value = identification.value.replaceAll(/\D/g, '')
          if (!companyNumberValidator.validate(model?.address?.country, identification.value)) {
            return false
          }
          break
        }
      }
    }

    return true
  }
}

export default identificationService