<template>
  <ul class="nav nav-tabs">
    <li class="nav-item" v-for="t in tabs" :key="t.value">
      <a class="nav-link cursor-pointer" id="templates-tab"
         :class="tab === t.value ? 'active' : ''" @click="setTab(t)">{{ t.label }}</a>
    </li>
  </ul>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'

export default {
  name:  'ui-list-tabs',
  props: {
    modelValue: {
      type:    String,
      default: 'Forms',
    },
    tabs:       {
      type:    Array,
      default: () => [],
    },
  },
  setup: (props, {emit}) => {
    const tab    = useModelWrapper(props, emit)
    const setTab = (t) => {
      tab.value = t.value
    }

    return {
      tab,
      setTab,
    }
  },
}
</script>
