import {defineStore, storeToRefs} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import {useWarningStore} from "@/stores/modals/WarningStore";

export const useEditRideStore = defineStore('editRide', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const warningStore = useWarningStore()
  const ride = ref({})
  const index = ref(0)

  const editRideModal = ref('editRideModal')

  const showEditRide = (rideToEdit, i) => {
    ride.value = JSON.parse(JSON.stringify(rideToEdit))
    index.value = i
    editRideModal.value.modal.open()
  }

  const editRide = async () => {
    if (ride.value.volume * 1.6 > 50) {
      warningStore.showWarning('warning.heavy_load', performEditRide)
    } else {
      await performEditRide()
    }
    editRideModal.value?.modal.close()
  }

  const performEditRide = async () => {
    const {form} = storeToRefs(formStore)
    form.value.soilRides[index.value] = ride.value
    const url = `api/documents/${form.value.id}`
    const result = await backend.put(url, form.value)
    if (result.status === 200) {
      notifier.success('toast.successfully_saved')
      formStore.setForm(result.data)
    } else {
      notifier.error('toast.save_failed')
    }
    return result
  }

  return {
    editRideModal,
    showEditRide,
    editRide,
    ride,
  }
})