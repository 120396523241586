<template>
  <loading-layout :title="$t('grondbank.edit.title', {id})" :back-route="`/grondbank/view/${id}`"
                  v-if="formStore.loading"/>
  <layout v-else>
    <template #header>
      <ui-header data-cy="grondbank-title" :title="$t('grondbank.edit.title', {id})"
                 :back-route="`/grondbank/view/${id}`">
        <template #actions>
          <button data-cy="grondbank-check" type="button" class="btn btn-outline-secondary"
                  @click="formStore.formCheck()"><i
              class="bi-check"/>&nbsp;{{ $t('form.check_form') }}
          </button>
          <button data-cy="grondbank-save" type="button" class="btn btn-outline-primary" :disabled="saving"
                  @click="formStore.save()" form="grondbank-form"><i class="bi-save"/>&nbsp;{{ $t('general.save') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <grondbank-form :item="form"/>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import {watch} from 'vue'
import DocumentType from '@/types/documentType'
import GrondbankForm from '@/partials/grondbank/Form'
import {useFormStore} from '@/stores/FormStore'
import {storeToRefs} from 'pinia'
import LoadingLayout from '@/partials/LoadingLayout.vue'
import FormState from '@/types/formState'

export default {
  name:       'GrondbankEdit',
  components: {
    LoadingLayout,
    GrondbankForm,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK, BREADCRUMBS.EDIT]

    const formStore = useFormStore()
    formStore.init(DocumentType.GRONDBANK)

    formStore.loadForm(props.id)

    watch(() => props.id, () => {
      formStore.loadForm(props.id)
    })

    watch(() => formStore.form.state, (state) => {
      if (FormState.isEndState(state)) {
        formStore.goToView()
      }
    }, {deep: true})

    return {
      breadcrumbs,
      formStore,
      ...storeToRefs(formStore),
    }
  },
}
</script>
