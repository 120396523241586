<template>
  <layout>
    <template #header>
      <ui-header :title="$t('annex_7.view.title', {id})" back-route="/annex-7/list">
        <template #actions>
          <div class="btn-group">
            <button v-if="$roles([roles.USER])" class="btn btn-outline-secondary dropdown-toggle" type="button"
                    id="actionsDropdown"
                    data-bs-toggle="dropdown">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="contacts-actions" class="dropdown-menu">
              <li
                  v-if="$roles([roles.USER])"
              >
                <button class="dropdown-item" @click="duplicateStore.showDuplicate([form.id])">
                  {{ $t('annex_7.view.duplicate') }}
                </button>
              </li>
              <li
                  v-if="$roles([roles.USER]) && !FormState.isEndState(form.state)"
                  id="deleteBtn"
                  :title="!FormState.canDelete(form.state) ? $t('annex_7.view.only_draft_deleted') : ''"
              >
                <button class="dropdown-item" :class="!FormState.canDelete(form.state) ? 'disabled' : ''"
                        @click="deleteStore.showDelete([form.id])">
                  {{ $t('annex_7.view.delete') }}
                </button>
              </li>
              <li
                  v-if="$roles([roles.USER, roles.DRIVER]) && valid"
                  id="editBtn"
              >
                <button class="dropdown-item" @click="formStore.goToEdit()">
                  {{ $t('annex_7.view.edit') }}
                </button>
              </li>
            </ul>
          </div>
          <button
              v-if="$roles([roles.USER]) && !valid"
              data-cy="prim-edit"
              type="button"
              class="btn btn-outline-primary"
              @click="formStore.goToEdit()"
          >
            <i class="bi-pencil"/>&nbsp;{{ $t('annex_7.view.edit') }}
          </button>
          <button
              v-if="$roles([roles.USER]) && valid && FormState.isDraft(form.state)"
              data-cy="prim-sign"
              type="button"
              class="btn btn-outline-primary"
              @click="showSign()"
          >
            <i class="bi-pencil"/>&nbsp;{{ $t('annex_7.view.sign') }}
          </button>
          <button
              v-if="$roles([roles.USER]) && validAllowPast && FormState.canExport(form.state)"
              data-cy="prim-export"
              type="button"
              class="btn btn-outline-primary"
              :disabled="exportStore.exporting"
              @click="exportStore.exportPdf(id)"
          >
            <i class="bi-cloud-download"/>&nbsp;{{ $t('annex_7.view.export') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <annex7-form-view :form="form" :key="form.id+'-'+form.lastUpdate"/>
    </ui-content>
  </layout>
  <form-sign-modal ref="signModal" :callback="sign" :form="form"/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import OrganisationType from '@/types/organisationType'
import DocumentType from '@/types/documentType'
import Annex7Model from '@/models/Annex7Model'
import Annex7FormView from '@/partials/annex7/FormView.vue'
import FormSignModal from '@/views/forms/shared/Sign-Modal.vue'
import session from '@/util/session'
import {useFormStore} from '@/stores/FormStore'
import {storeToRefs} from 'pinia'
import {useDeleteStore} from '@/stores/modals/DeleteStore'
import {useDuplicateStore} from '@/stores/modals/DuplicateStore'
import {useExportStore} from '@/stores/modals/ExportStore'

export default {
  name:       'Annex7ViewUser',
  computed:   {
    DocumentType() {
      return DocumentType
    },
    FormState() {
      return FormState
    },
    OrganisationType() {
      return OrganisationType
    },
  },
  components: {
    FormSignModal,
    Annex7FormView,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ANNEX_7, BREADCRUMBS.VIEW]

    const notifier = Notifier()

    const formStore = useFormStore()
    formStore.init(DocumentType.ANNEX_7)

    const deleteStore    = useDeleteStore()
    const duplicateStore = useDuplicateStore()
    const exportStore    = useExportStore()

    const signModal = ref('signModal')
    const showSign  = () => {
      if (!Annex7Model.modelCheck(formStore.form)) {
        notifier.error('toast.form_incomplete')
        return false
      }
      signModal.value.modal.open()
    }
    const sign      = () => {
      backend.put(`api/documents/${props.id}/sign`).then(() => {
        session.setOrganisation()
        notifier.success('toast.form_signed')
      }).catch(() => {
      }).finally(() => {
        formStore.reloadForm(props.id)
        signModal.value.modal.close()
      })
    }

    return {
      breadcrumbs,
      ...storeToRefs(formStore),
      formStore,
      deleteStore,
      duplicateStore,
      exportStore,
      showSign,
      signModal,
      sign, // TODO sign store
    }
  },
}
</script>
