import mod97Checker from "@/services/mod97Checker";
import countryCodes from "@/models/CountryCodes";

const companyNumberValidator = {
  validate: (country, value) => {
    switch (country) {
      case countryCodes.BE: { // https://economie.fgov.be/nl/themas/ondernemingen/kruispuntbank-van/inschrijving-de-kruispuntbank
        if (value.length !== 10 || !value[0].match(/[01]/)) {
          return false
        }
        return mod97Checker.check(value)
      }
      default: {
        return true
      }
    }
  },
}

export default companyNumberValidator