<template>
  <div class="container-fluid">
    <form id="grondbank-form" class="row g-2">
      <ui-view-card :title="$t('form.title.basic_information')" type="col-lg-12" :demo-warning="itemRef.demo">
        <div class="row">
          <div class="col-lg-6">
            <ui-view-card-field data-cy="grondbank-id" :label="$t('form.labels.unique_identifier')"
                                :model-value="itemRef.id ? 'DGF-GB-' + itemRef.id : ''" :readonly="true"/>
            <ui-view-card-field v-model="itemRef.permissionId" :label="$t('form.labels.permission_identifier')"
                                data-cy="grondbank-permission-id" :required="true"/>
            <ui-view-card-field v-model="itemRef.soilReferenceId" :label="$t('form.labels.grondbank_identifier')"
                                data-cy="grondbank-grondbank-id"/>
            <ui-view-card-field data-cy="grondbank-external-id" :label="$t('form.labels.external_identifier')"
                                v-model="itemRef.externalId"/>
            <ui-view-card-field data-cy="grondbank-last-update" :label="$t('form.labels.last_update')"
                                :model-value="updateDate" :readonly="true"/>
            <ui-view-card-field data-cy="grondbank-updated-by" :label="$t('form.labels.updated_by')"
                                :model-value="itemRef.modifiedBy" :readonly="true"/>
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="grondbank-state" :label="$t('form.labels.status')"
                                :model-value="$t('form.state.'+itemRef.state)" :readonly="true"/>
            <ui-view-card-field data-cy="grondbank-license-plate" :label="$t('form.labels.license_plate')"
                                v-model="itemRef.licensePlate"/>
            <ui-view-card-field :label="$t('form.labels.transport_date')" :required="true">
              <datepicker data-cy="grondbank-transport-date" v-model="itemRef.transportDate" :enableTimePicker="false"
                          :autoApply="true" :minDate="new Date()" format="dd/MM/yyyy" :required="true"/>
            </ui-view-card-field>
            <ui-view-card-field data-cy="grondbank-description" :label="$t('form.labels.description')"
                                v-model="itemRef.description" :required="true" :maxlength="200"/>
            <ui-view-card-number data-cy="grondbank-estimated-volume" :label="$t('form.labels.estimatedVolume')"
                                 v-model="itemRef.volume"/>
            <ui-view-card-field data-cy="grondbank-calculated-volume" :label="$t('form.labels.calculatedVolume')"
                                v-model="itemRef.calculatedVolume" :readonly="true"/>
            <ui-view-card-field :label="$t('form.title.notes')">
              <textarea class="form-control" v-model="itemRef.notes" rows="4" style="width: 100%" maxlength="255"/>
            </ui-view-card-field>
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.origin')">
        <ui-view-card-organisation
            v-model="itemRef.soilOrigin"
            :fields="[
              OrganisationField.NAME,
              OrganisationField.ADDRESS,
              OrganisationField.LOCATIONINFO,
            ]"
            :required-fields="[
              OrganisationField.NAME,
              OrganisationField.ADDRESS,
            ]"
        />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.destination')">
        <ui-view-card-organisation
            v-model="itemRef.soilDestination"
            :fields="[
              OrganisationField.NAME,
              OrganisationField.EMAIL,
              OrganisationField.ADDRESS,
              OrganisationField.LOCATIONINFO,
              OrganisationField.DESTINATIONTYPE,
            ]"
            :required-fields="[
              OrganisationField.NAME,
              OrganisationField.ADDRESS,
            ]"
        />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.soil_origin_contractor')">
        <ui-view-card-organisation
            v-model="itemRef.soilOriginContractor"
            :fields="[
              OrganisationField.NAME,
              OrganisationField.RESPONSIBLE,
              OrganisationField.PHONE,
            ]"
            :required-fields="[
              OrganisationField.NAME,
              OrganisationField.RESPONSIBLE,
            ]"
        />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.transporter')">
        <ui-view-card-organisation
            v-model="itemRef.soilTransporter"
            :fields="[
              OrganisationField.NAME,
              OrganisationField.EMAIL,
              OrganisationField.ADDRESS,
              OrganisationField.GRONDBANKNUMBER,
            ]"
            :required-fields="[
              OrganisationField.NAME,
              OrganisationField.ADDRESS,
            ]"
        />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.rides')" type="col-lg-12">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>{{ $t('form.table.ride_number') }}</th>
            <th>{{ $t('form.table.start_hour') }}<span :title="$t('general.required')" class="text-danger">*</span></th>
            <th>{{ $t('form.table.volume') }}<span :title="$t('general.required')" class="text-danger">*</span></th>
            <th>{{ $t('form.table.end_hour') }}<span :title="$t('general.required')" class="text-danger">*</span></th>
            <th>{{ $t('form.table.signature_organiser') }}</th>
            <th>{{ $t('form.table.signature_transporter') }}</th>
            <th>{{ $t('form.table.signature_processor') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(ride, index) in itemRef.soilRides" :key="index">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>{{ ride.startOfTransport }}</td>
              <td>{{ ride.volume }} m³</td>
              <td>{{ ride.endOfTransport }}</td>
              <td :key="ride.id + '-' + ride?.organiserSignature?.signingDate">
                <ui-view-card-signature-field :signature="ride.organiserSignature" :id="itemRef.id" :ride-id="ride.id"
                                              :signature-type="SignatureType.ORGANISER"
                />
              </td>
              <td :key="ride.id + '-' + ride?.transporterSignature?.signingDate">
                <ui-view-card-signature-field :signature="ride.transporterSignature" :id="itemRef.id" :ride-id="ride.id"
                                              :signature-type="SignatureType.TRANSPORTER"
                />
              </td>
              <td :key="ride.id + '-' + ride?.processorSignature?.signingDate">
                <ui-view-card-signature-field :signature="ride.processorSignature" :id="itemRef.id" :ride-id="ride.id"
                                              :signature-type="SignatureType.PROCESSOR"
                />
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.soil.responsible')" type="col-lg-4">
        <ui-view-card-signature-field :signature="itemRef.organiserSignature" :id="itemRef.id" :edit="true"/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.soil.transporter')" type="col-lg-4">
        <ui-view-card-signature-field :signature="itemRef.transporterSignature" :id="itemRef.id" :edit="true"/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.soil.purchaser')" type="col-lg-4">
        <ui-view-card-signature-field :signature="itemRef.processorSignature" :id="itemRef.id" :edit="true"/>
      </ui-view-card>
    </form>
  </div>
</template>

<script>

import {computed, inject, ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import UiViewCardField from '@/components/ui-view-card-field'
import {DateFormatter} from '@/services/dateFormatter'
import UiViewCardSignatureField from '@/components/ui-view-card-signature-field'
import SignatureType from '@/types/signatureType'
import UiViewCardOrganisation from '@/components/ui-view-card-organisation.vue'
import OrganisationField from '@/types/organisationField'
import FormState from '@/types/formState'
import UiViewCardNumber from '@/components/ui-view-card-number.vue'

export default {
  name:       'GrondbankForm',
  computed:   {
    FormState() {
      return FormState
    },
    OrganisationField() {
      return OrganisationField
    },
    SignatureType() {
      return SignatureType
    },
  },
  components: {
    UiViewCardNumber,
    UiViewCardOrganisation,
    UiViewCardSignatureField,
    UiViewCardField,
    Datepicker,
    UiViewCard,
  },
  props:      {
    item: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const $t         = inject('$t')
    const rideStarts = ref([])
    const rideEnds   = ref([])
    let itemRef      = ref(props.item)

    itemRef.value.soilRides.forEach((ride) => {
      if (ride.startOfTransport) {
        rideStarts.value.push({
          hours:   ride.startOfTransport.slice(0, 2),
          minutes: ride.startOfTransport.slice(3, 5),
        })
      } else {
        rideStarts.value.push(null)
      }

      if (ride.endOfTransport) {
        rideEnds.value.push({
          hours:   ride.endOfTransport.slice(0, 2),
          minutes: ride.endOfTransport.slice(3, 5),
        })
      } else {
        rideEnds.value.push(null)
      }
    })

    watch(rideStarts, (starts) => {
      for (let index in starts) {
        if (starts[index]) {
          itemRef.value.soilRides[index].startOfTransport
              = starts[index].hours.toString().padStart(2, '0') + ':' + starts[index].minutes.toString().padStart(2, '0')
        }
      }
    }, {deep: true})

    watch(rideEnds, (ends) => {
      for (let index in ends) {
        if (ends[index]) {
          itemRef.value.soilRides[index].endOfTransport
              = ends[index].hours.toString().padStart(2, '0') + ':' + ends[index].minutes.toString().padStart(2, '0')
        }
      }
    }, {deep: true})


    const state = $t('form.state.' + itemRef.value.state)

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(itemRef.value.lastUpdate)
    })

    const removeRide = (index) => {
      rideStarts.value.splice(index, 1)
      rideEnds.value.splice(index, 1)
      itemRef.value.soilRides.splice(index, 1)
    }

    return {
      itemRef,
      state,
      removeRide,
      rideStarts,
      rideEnds,
      updateDate,
    }
  },
}
</script>
