<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="validAmount">
    <template #title>
      {{ $t('forms.delete_modal.title') }}
    </template>
    <template #default>
      {{ $t('forms.delete_modal.delete_forms', {amount: amount ?? ids.length}) }}
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import {useDeleteStore} from '@/stores/modals/DeleteStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormDeleteModal',
  components: {UiModal},
  props:      {
    amount:   {
      type: Number,
    },
    callback: {
      type: Function,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const deleteStore = useDeleteStore()

    const validAmount = () => {
      return props.amount > 0 || deleteStore.ids.length > 0
    }

    const cb = async () => {
      if (props.callback) {
        await props.callback()
      } else {
        await deleteStore.deleteFunc()
      }
    }

    return {
      modal,
      validAmount,
      ...storeToRefs(deleteStore),
      cb,
    }
  },
}
</script>
