import {defineStore} from "pinia";
import {ref} from "vue";

export const useWarningStore = defineStore('warning', () => {
  const warningModal = ref('warningModal')
  const message = ref('')
  const callback = ref(null)

  const showWarning = (msg, cb) => {
    message.value = msg
    callback.value = cb
    warningModal.value.modal.open()
  }

  const close = () => {
    warningModal.value.modal.close()
  }

  const performCallback = async () => {
    await callback.value()
    close()
  }

  return {
    warningModal,
    showWarning,
    close,
    message,
    callback,
    performCallback,
  }
})