import {defineStore} from "pinia";
import {computed, ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import DocumentType from "@/types/documentType";
import FormState from "@/types/formState";
import {useFormListStore} from "@/stores/FormListStore";

export const useFinishStore = defineStore('finish', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const formListStore = useFormListStore()
  const ids = ref([])
  const list = ref(false)

  const finishModal = ref('finishModal')

  const showFinish = (selectedIDs, isList) => {
    ids.value = selectedIDs
    list.value = isList
    finishModal.value.modal.open()
  }

  const canFinish = computed(() => {
    switch (formStore.form.type) {
      case DocumentType.GRONDBANK:
        return formStore.form.organiserSignature && formStore.form.transporterSignature && formStore.form.processorSignature && !FormState.isEndState(formStore.form.state)
      default:
        return false
    }
  })

  const finishFunc = async () => {
    if (!list.value) {
      await finishOne()
    } else {
      await finishList()
    }
  }

  const finishOne = async () => {
    try {
      const result = await backend.put(`api/documents/${formStore.form.id}/finish`)
      if (result.status !== 200) {
        notifier.error('toast.finish_failed')
      } else {
        notifier.success('toast.finish_successful')
        formStore.setForm(result.data)
      }
    } catch (err) {
      notifier.error('toast.finish_failed')
    } finally {
      finishModal.value.modal.close()
    }
  }

  const finishList = async () => {
    try {
      if (ids.value.length > 0) {
        const result = await backend.post('api/documents/batch', {
          'action': 'FINISH',
          'ids': ids.value,
        })
        if (result.status === 200) {
          notifier.success('toast.finish_successful')
        } else {
          notifier.error('toast.finish_failed')
        }
      }
    } catch (err) {
      notifier.error('toast.finish_successful')
    } finally {
      finishModal.value.modal.close()
      formListStore.reload()
    }
  }

  return {
    finishModal,
    canFinish,
    showFinish,
    finishFunc,
    ids,
  }
})