<template>
  <waste-identification-list-overviewer v-if="$orgType(OrganisationType.OVERVIEWER)"/>
  <waste-identification-list-support v-else-if="$orgType(OrganisationType.SUPPORT)"/>
  <waste-identification-list-driver v-else-if="roles.hasOnlyRole(roles.DRIVER)"/>
  <waste-identification-list-user v-else/>
</template>

<script>

import WasteIdentificationListDriver from '@/views/forms/waste-identification/List-Driver'
import WasteIdentificationListUser from '@/views/forms/waste-identification/List-User'
import WasteIdentificationListOverviewer from '@/views/forms/waste-identification/List-Overviewer'
import OrganisationType from '@/types/organisationType'
import session from '@/util/session'
import WasteIdentificationListSupport from '@/views/forms/waste-identification/List-Support.vue'
import {useFormStore} from '@/stores/FormStore'
import DocumentType from '@/types/documentType'

export default {
  name:       'WasteIdentificationList',
  computed:   {
    OrganisationType() {
      return OrganisationType
    },
  },
  components: {
    WasteIdentificationListSupport,
    WasteIdentificationListOverviewer,
    WasteIdentificationListUser,
    WasteIdentificationListDriver,
  },
  setup:      () => {
    session.setActiveForm('waste-identification')

    const formStore = useFormStore()
    formStore.init(DocumentType.WASTE_IDENTIFICATION)

    return {}
  },
}
</script>
