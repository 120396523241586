import {defineStore} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import {useFormListStore} from "@/stores/FormListStore";

export const useDuplicateStore = defineStore('duplicate', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const formListStore = useFormListStore()
  const ids = ref([])
  const list = ref(false)

  const duplicateModal = ref('duplicateModal')

  const showDuplicate = (selectedIDs, isList) => {
    ids.value = selectedIDs
    list.value = isList
    duplicateModal.value.modal.open()
  }

  const duplicate = async () => {
    if (!list.value) {
      await duplicateOne()
    } else {
      await duplicateList()
    }
  }

  const duplicateOne = async () => {
    try {
      const result = await backend.post(`api/documents/${ids.value[0]}/duplicate`)
      if (result.status !== 200) {
        notifier.error('toast.duplication_failed')
      } else {
        formStore.setForm(result.data)
        await formStore.goToView(result.data.id)
      }
    } catch (err) {
      notifier.error('toast.duplication_failed')
    } finally {
      duplicateModal.value.modal.close()
    }
  }

  const duplicateList = async () => {
    try {
      if (ids.value.length > 0) {
        const result = await backend.post('api/documents/batch', {
          'action': 'COPY',
          'ids': ids.value,
        })
        if (result.status === 200) {
          notifier.success('toast.duplication_successful')
        } else {
          notifier.error('toast.duplication_failed')
        }
      }
    } catch (err) {
      notifier.error('toast.duplication_failed')
    } finally {
      duplicateModal.value.modal.close()
      formListStore.reload()
    }
  }

  return {
    duplicateModal,
    showDuplicate,
    duplicate,
    ids,
  }
})