<template>
  <ui-modal size="lg" id="editDisclaimerModal" ref="modal" :callback="cb">
    <template #title>
      {{ $t('forms.edit_disclaimer_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('forms.edit_disclaimer_modal.disclaimer_1') }}
      </div>
      <div>
        {{ $t('forms.edit_disclaimer_modal.disclaimer_2') }}
        <ul>
          <li>{{ $t('forms.edit_disclaimer_modal.disclaimer_3') }}</li>
          <li>{{ $t('forms.edit_disclaimer_modal.disclaimer_4') }}</li>
          <li>{{ $t('forms.edit_disclaimer_modal.disclaimer_5') }}</li>
        </ul>
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import {useRefinaliseStore} from '@/stores/modals/RefinaliseStore'

export default {
  name:       'FormRefinaliseModal',
  components: {
    UiModal,
  },
  setup:      () => {
    const modal           = ref('modal')
    const refinaliseStore = useRefinaliseStore()

    const cb = async () => {
      await refinaliseStore.refinalise()
    }

    return {
      modal,
      cb,
    }
  },
}
</script>
