<template>
  <ui-modal id="refuseModal" ref="modal" :callback="cb" :can-confirm="isValid">
    <template #title>
      {{ $t('forms.refuse_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('forms.refuse_modal.refuse_form') }}
      </div>
      <ui-view-card-field :label="$t('forms.refuse_modal.reason')">
        <textarea class="form-control" rows="4" style="width: 100%" v-model="reason" maxlength="200"/>
      </ui-view-card-field>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field'
import {useRefuseStore} from '@/stores/modals/RefuseStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormRefuseModal',
  components: {
    UiViewCardField,
    UiModal,
  },
  props:      {
    callback: {
      type: Function,
    },
  },
  setup:      (props) => {
    const modal       = ref('modal')
    const refuseStore = useRefuseStore()

    const {reason} = storeToRefs(refuseStore)

    const cb = async () => {
      if (props.callback) {
        await props.callback(reason.value)
      } else {
        await refuseStore.refuse()
      }
    }

    const isValid = () => {
      return validReason()
    }

    const validReason = () => {
      return !!reason.value
    }

    return {
      modal,
      refuseStore,
      ...storeToRefs(refuseStore),
      isValid,
      cb,
    }
  },
}
</script>
