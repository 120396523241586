<template>
  <loading-layout v-if="loading"/>
  <template v-else>
    <shared-link-driver v-if="shareLinkType === ShareLinkType.TRANSPORTER" :link="link"/>
    <shared-link-processor v-else-if="shareLinkType === ShareLinkType.PROCESSOR" :link="link"/>
    <shared-link-control v-else-if="shareLinkType === ShareLinkType.CONTROL" :link="link"/>
    <shared-link-signature-transporter v-else-if="shareLinkType === ShareLinkType.SIGNATURE_TRANSPORTER" :link="link"/>
    <shared-link-signature-processor v-else-if="shareLinkType === ShareLinkType.SIGNATURE_PROCESSOR" :link="link"/>
  </template>
</template>

<script>

import SharedLinkDriver from '@/views/shared-links/Shared-Link-Driver'
import SharedLinkProcessor from '@/views/shared-links/Shared-Link-Processor'
import SharedLinkControl from '@/views/shared-links/Shared-Link-Control'
import SharedLinkSignatureTransporter from '@/views/shared-links/Shared-Link-Signature-Transporter.vue'
import SharedLinkSignatureProcessor from '@/views/shared-links/Shared-Link-Signature-Processor.vue'
import {useSharedLinkStore} from '@/stores/SharedLinkStore'
import LoadingLayout from '@/partials/LoadingLayout.vue'
import {storeToRefs} from 'pinia'
import ShareLinkType from '@/types/shareLinkType'

export default {
  name:       'SharedLink',
  computed:   {
    ShareLinkType() {
      return ShareLinkType
    },
  },
  components: {
    LoadingLayout,
    SharedLinkSignatureProcessor,
    SharedLinkSignatureTransporter,
    SharedLinkControl,
    SharedLinkProcessor,
    SharedLinkDriver,
  },
  props:      {
    uuid: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const sharedLinkStore = useSharedLinkStore()
    sharedLinkStore.init(props.uuid)

    return {
      sharedLinkStore,
      ...storeToRefs(sharedLinkStore),
    }
  },
}
</script>
