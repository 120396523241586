import {defineStore} from "pinia";
import {inject, ref} from "vue";
import ExportType from "@/types/exportType";
import backend from "@/util/backend";
import Notifier from "@/util/notifier";
import session from "@/util/session";
import router from "@/router/router";

export const useExportStore = defineStore('export', () => {
  const notifier = Notifier()
  const ids = ref([])
  const list = ref(false)
  const language = ref(session.getLanguage())
  const $isSafari = inject('$isSafari')
  const exporting = ref(false)

  const exportType = ref(null)
  const exportModal = ref('exportModal')
  const showExport = (selectedIds, isList) => {
    ids.value = selectedIds
    list.value = isList
    exportModal.value.modal.open()
  }

  const exportFunc = async () => {
    if (!list.value) {
      await exportOne()
      exportType.value = null
    } else {
      await exportList()
      exportType.value = null
    }
  }

  const exportOne = async () => {
    switch (exportType.value) {
      case ExportType.PDF: {
        await exportPdf()
        return
      }
      case ExportType.JSON: {
        await exportJson()
        return
      }
      case ExportType.CSV: {
        if ($isSafari()) {
          const route = router.resolve(`/export-csv/${ids.value[0]}}`)
          window.open(route.href, '_blank')
        } else {
          await exportCsv()
        }
        return
      }
    }
  }

  const exportPdf = async (id) => {
    exporting.value = true
    try {
      const result = await backend.get(`api/documents/${id ?? ids.value[0]}/pdf?language=${language.value}`, {responseType: 'blob'})
      if (result.status === 200) {
        const blob = new Blob([result.data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url)
      } else {
        notifier.error('toast.exporting_failed')
      }
    } catch (e) {
      notifier.error('toast.exporting_failed')
    } finally {
      exporting.value = false
      exportModal.value.modal.close()
    }
  }

  const exportJson = async () => {
    exporting.value = true
    try {
      const result = await backend.get(`api/documents/${ids.value[0]}/json`,
          {responseType: 'blob'})
      if (result.status === 200) {
        const blob = new Blob([result.data], {type: 'application/json'})
        const url = URL.createObjectURL(blob)
        window.open(url)
      } else {
        notifier.error('toast.exporting_failed')
      }
    } catch (e) {
      notifier.error('toast.exporting_failed')
    } finally {
      exporting.value = false
      exportModal.value.modal.close()
    }
  }

  const exportCsv = async () => {
    exporting.value = true
    try {
      const result = await backend.get(`api/documents/${ids.value[0]}/csv`, {responseType: 'blob'})
      if (result.status === 200) {
        const blob = new Blob([result.data])
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `export_${ids.value[0]}.csv`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } else {
        notifier.error('toast.exporting_failed')
      }
    } catch (e) {
      notifier.error('toast.exporting_failed')
    } finally {
      exporting.value = false
      exportModal.value.modal.close()
    }
  }

  const exportList = async () => {
    if (ids.value.length > 0) {
      switch (exportType.value) {
        case ExportType.PDF: {
          await exportPdfList()
          return
        }
        case ExportType.JSON: {
          await exportJsonList()
          return
        }
        case ExportType.CSV: {
          if ($isSafari()) {
            const route = router.resolve(`/export-csv/${ids.value.join(',')}`)
            window.open(route.href, '_blank')
          } else {
            await exportCsvList()
          }
          return
        }
      }
    }
  }

  const exportPdfList = async () => {
    exporting.value = true
    try {
      const result = await backend.post('api/documents/batch', {
        'action': 'EXPORT_PDF',
        'ids': ids.value,
        'params': {
          'language': language.value,
        },
      }, {responseType: 'blob'})
      if (result.status === 200) {
        const blob = new Blob([result.data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url)
      } else {
        notifier.error('toast.exporting_failed')
      }
    } catch (e) {
      notifier.error('toast.exporting_failed')
    } finally {
      exporting.value = false
      exportModal.value.modal.close()
    }
  }

  const exportJsonList = async () => {
    exporting.value = true
    try {
      const result = await backend.post('api/documents/batch', {
        'action': 'EXPORT_JSON',
        'ids': ids.value,
      })
      if (result.status === 200) {
        const data = JSON.stringify(result.data)
        const blob = new Blob([data], {type: 'application/json'})
        const url = URL.createObjectURL(blob)
        window.open(url)
      } else {
        notifier.error('toast.exporting_failed')
      }
    } catch (e) {
      notifier.error('toast.exporting_failed')
    } finally {
      exporting.value = false
      exportModal.value.modal.close()
    }
  }

  const exportCsvList = async () => {
    exporting.value = true
    try {
      const result = await backend.post('api/documents/batch', {
        'action': 'EXPORT_CSV',
        'ids': ids.value,
      })
      if (result.status === 200) {
        const blob = new Blob([result.data])
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `export.csv`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } else {
        notifier.error('toast.exporting_failed')
      }
    } catch (e) {
      notifier.error('toast.exporting_failed')
    } finally {
      exporting.value = false
      exportModal.value.modal.close()
    }
  }

  return {
    exportModal,
    showExport,
    exportFunc,
    exportPdf,
    exportType,
    language,
    ids,
    exporting,
  }
})