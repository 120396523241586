<template>
  <layout>
    <template #header>
      <ui-header :title="$t('grondbank.list.grondbank')">
        <template #actions>
          <div v-if="$roles([roles.USER])" class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                    data-bs-toggle="dropdown" :disabled="selection.length === 0">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="grondbank-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li v-if="$roles([roles.USER])"
                  :title="shareableSelectionIDs.length === 0 ? $t('forms.view.only_signed_shared') : ''">
                <button :disabled="shareableSelectionIDs.length === 0" class="dropdown-item"
                        @click="shareStore.showShare(shareableSelectionIDs, true)">
                  {{ $t('forms.list.actions.share_with_driver') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])"
                  :title="notDraftSelectionIDs.length === 0 ? $t('forms.view.draft_no_export') : ''">
                <button :disabled="notDraftSelectionIDs.length === 0" class="dropdown-item"
                        @click="exportStore.showExport(notDraftSelectionIDs, true)">
                  {{ $t('forms.list.actions.export') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])">
                <button :disabled="selection.length === 0" class="dropdown-item"
                        @click="duplicateStore.showDuplicate(selectionIDs, true)">
                  {{ $t('forms.list.actions.duplicate') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])"
                  :title="draftAndEmptySelectionIDs.length === 0 ? $t('waste_identification.view.only_draft_delete') : ''">
                <button :disabled="draftAndEmptySelectionIDs.length === 0" class="dropdown-item"
                        @click="deleteStore.showDelete(draftAndEmptySelectionIDs, true)">
                  {{ $t('forms.list.actions.delete') }}
                </button>
              </li>
            </ul>
          </div>
          <button v-if="$roles([roles.USER])" data-cy="create-form" type="button" class="btn btn-outline-primary"
                  @click="formStore.addForm()"><i class="bi-plus"/>&nbsp;{{ $t('general.add_form') }}
          </button>
        </template>
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="formListStore.resetGrid">{{
            $t('general.reset_filters')
          }}
        </button>
      </div>
    </div>
    <ui-content>
      <ag-grid-vue
          :serverSideDatasource="grid.datasource(`api/documents/list?documentType=${DocumentType.GRONDBANK}`)"
          :grid-options="grid.defaultOptions"
          :columnDefs="xsWindow ? xsColumnDefs : columnDefs"
          class="ag-theme-quartz"
          style="min-height:100%"
          @row-clicked="formListStore.formRowClick"
          @selection-changed="formListStore.refreshSelection"
          @grid-ready="formListStore.onGridReady"
          @grid-pre-destroyed="formListStore.onGridPreDestroyed"
          @filter-changed="formListStore.onFilterChanged"
          :initialState="initialState"
      />
    </ui-content>
  </layout>
</template>

<script>

import {inject, onBeforeUnmount, ref} from 'vue'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import {DateFormatter} from '@/services/dateFormatter'
import FormState from '@/types/formState'
import '@vuepic/vue-datepicker/dist/main.css'
import DocumentType from '@/types/documentType'
import grid from '@/util/grid'
import session from '@/util/session'
import stateColorRenderer from '@/util/stateColorRenderer'
import {useFormListStore} from '@/stores/FormListStore'
import {storeToRefs} from 'pinia'
import {useFormStore} from '@/stores/FormStore'
import {useDeleteStore} from '@/stores/modals/DeleteStore'
import {useDuplicateStore} from '@/stores/modals/DuplicateStore'
import {useExportStore} from '@/stores/modals/ExportStore'
import {useShareStore} from '@/stores/modals/ShareStore'

export default {
  name:       'GrondbankListUser',
  computed:   {
    grid() {
      return grid
    },
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    UiContent,
    UiHeader,
    Layout,
    // eslint-disable-next-line
    stateColorRenderer,
    UiBreadcrumbs,
    AgGridVue,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK]
    const $t          = inject('$t')
    session.setActiveForm('grondbank')

    const formListStore = useFormListStore()
    formListStore.init('grondbank-list-user')

    const xsWindow = ref(window.innerWidth < 576)
    const resize   = () => {
      xsWindow.value = window.innerWidth < 576
      setTimeout(() => {
        formListStore.api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }

    window.addEventListener('resize', resize)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resize)
      formListStore.api.value = null
    })

    const formStore      = useFormStore()
    const deleteStore    = useDeleteStore()
    const duplicateStore = useDuplicateStore()
    const exportStore    = useExportStore()
    const shareStore     = useShareStore()

    const xsColumnDefs = [
      {
        headerName:  $t('grondbank.list.table.key'),
        field:       'id',
        valueGetter: (params) => {
          let res = 'DGF-GB-' + params.data.id
          if (params.data.demo) {
            res += ' (demo)'
          }
          return res
        },
      },
      {
        headerName:  $t('grondbank.list.table.state'),
        valueGetter: (params) => {
          return $t('form.state.' + params.data.state)
        },
      },
    ]

    const columnDefs = [
      {
        headerName:  $t('forms.list.table.key'),
        field:       'id',
        valueGetter: (params) => {
          let res = 'DGF-GB-' + params.data.id
          if (params.data.demo) {
            res += ' (demo)'
          }
          return res
        },
      },
      {
        headerName:     $t('forms.list.table.date'),
        filter:         'agDateColumnFilter',
        field:          'transportDate',
        maxWidth:       250,
        filterParams:   {
          minValidYear:  2000,
          maxValidYear:  2099,
          filterOptions: ['inRange'],
        },
        valueGetter:    (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        },
      },
      {
        headerName: $t('forms.list.table.description'),
        field:      'wasteDescription',
      },
      {
        headerName: $t('forms.list.table.permissionId'),
        field:      'permissionId',
      },
      {headerName: $t('forms.list.table.from'), field: 'from'},
      {headerName: $t('forms.list.table.to'), field: 'to'},
      {
        headerName:   $t('forms.list.table.state'),
        field:        'state',
        maxWidth:     400,
        cellRenderer: 'stateColorRenderer',
        filter:       'agSetColumnFilter',
        filterParams: {
          values:         FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          },
        },
      },
    ]

    return {
      formListStore,
      ...storeToRefs(formListStore),
      formStore,
      deleteStore,
      duplicateStore,
      exportStore,
      shareStore,
      breadcrumbs,
      xsWindow,
      xsColumnDefs,
      columnDefs,
    }
  },
}
</script>
