import {defineStore} from "pinia";
import {computed, ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import FormState from "@/types/formState";
import roles from "@/util/roles";

export const useConfirmStore = defineStore('confirm', () => {
  const notifier = Notifier()
  const formStore = useFormStore()

  const confirmModal = ref('confirmModal')

  const showConfirm = () => {
    confirmModal.value.modal.open()
  }

  const canConfirm = computed(() => {
    return roles.hasOneOfRoles([roles.USER]) && FormState.isDraft(formStore.form.state) && formStore.modelCheck()
  })

  const confirm = async () => {
    try {
      const result = await backend.put(`api/documents/${formStore.form.id}/confirm`)
      notifier.success('toast.confirmed')
      formStore.setForm(result.data)
    } catch (err) {
      if (!err?.response?.data?.messageKey) {
        notifier.error('toast.confirming_failed')
      }
    } finally {
      confirmModal.value.modal.close()
    }
  }

  return {
    confirmModal,
    canConfirm,
    showConfirm,
    confirm
  }
})