<template>
  <div class="container-fluid">
    <form id="organisation-form">
      <div class="row  justify-content-center">
        <ui-view-card :title="$t('organisations.form.title')">
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field data-cy="organisation-name" :label="$t('form.labels.name')" v-model="itemRef.name"
                                  :required="true"/>
            </div>
            <div class="col-lg-6">
              <ui-view-card-field data-cy="organisation-external-id" :label="$t('form.labels.external_identifier')"
                                  v-model="itemRef.externalId" :maxlength="50"/>
            </div>
          </div>
        </ui-view-card>
      </div>
      <div class="row  justify-content-center">
        <ui-view-card :title="$t('organisations.form.contact_information')">
          <div class="row">
            <ui-view-card-language data-cy="organisation-language" v-model="itemRef.language"/>
            <div class="col-lg-6">
              <ui-view-card-field data-cy="organisation-email" :label="$t('form.labels.email')" v-model="itemRef.email"
                                  type="email"/>
            </div>
            <div class="col-lg-6">
              <ui-view-card-field data-cy="organisation-phonenumber" :label="$t('form.labels.phonenumber')"
                                  v-model="itemRef.phone" :maxlength="50"/>
            </div>
            <div class="col-lg-6">
              <ui-view-card-field data-cy="organisation-fax" :label="$t('form.labels.fax')" v-model="itemRef.fax"
                                  :maxlength="25"/>
            </div>
            <div class="col-lg-6">
              <ui-view-card-field data-cy="organisation-contact-person" :label="$t('form.labels.contact_person')"
                                  v-model="itemRef.contactPerson" :maxlength="50"/>
            </div>
          </div>
        </ui-view-card>
      </div>
      <div class="row  justify-content-center">
        <ui-view-card :title="$t('organisations.form.address')">
          <ui-view-card-address cy="organisation-address" :edit="true" v-model="itemRef.address"/>
          <ui-view-card-field cy="organisation-other-location-info" :label="$t('form.labels.other_location_info')"
                              v-model="itemRef.otherLocationDetermination" :maxlength="50"/>
          <ui-view-card-field cy="organisation-destination-type" :label="$t('form.labels.destination_type')"
                              v-model="itemRef.destinationType" :maxlength="50"/>
        </ui-view-card>
      </div>
      <div class="row  justify-content-center">
        <ui-view-card :title="$t('organisations.form.shipment_address')">
          <ui-view-card-field :label="$t('form.labels.same_address')" v-model="sameAddress" type="checkbox"/>
          <ui-view-card-address v-if="!sameAddress" cy="organisation-shipment-address" :edit="true"
                                v-model="itemRef.shipmentAddress"/>
          <ui-view-card-field :label="$t('form.labels.matis_location_type')" icon="bi-question-circle-fill"
                              :icon-text="$t('help.matis_location_type')">
            <v-select v-if="matisLocationTypesLoaded" :options="matisLocationTypes"
                      v-model="itemRef.locationType" :reduce="type => type.value">
              <template #search="{attributes, events}">
                <input
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
            </v-select>
          </ui-view-card-field>
        </ui-view-card>
      </div>
      <div class="row  justify-content-center">
        <ui-view-card :title="$t('organisations.form.additional_information')">
          <ui-view-card-ids v-model="itemRef.identifications" :country-code="itemRef.address?.country" cy="organisation"
                            :allow-individual="true"/>
          <ui-view-card-field v-model="itemRef.licensePlate" :label="$t('form.labels.license_plate')" :maxlength="25"/>
          <ui-view-card-field v-model="itemRef.meansOfTransport" :label="$t('form.labels.means_of_transport')">
            <v-select :options="meansOfTransport.getAll()" v-model="itemRef.meansOfTransport"
                      :reduce="operation => operation.value">
              <template #search="{attributes, events}">
                <input
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
            </v-select>
          </ui-view-card-field>
          <ui-view-card-field :label="$t('form.labels.annex_7_recovery_destination_type')"
                              icon="bi-question-circle-fill" :icon-text="$t('help.annex_7_recovery_destination_type')">
            <v-select :options="Annex7RecoveryDestinationType.getAll()" v-model="itemRef.annex7RecoveryDestinationType"
                      :reduce="operation => operation.value">
              <template #search="{attributes, events}">
                <input
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
            </v-select>
          </ui-view-card-field>
          <ui-view-card-field v-model="itemRef.responsible" :label="$t('form.labels.responsible')" :maxlength="50"/>
          <ui-view-card-field v-model="itemRef.soilBankNumber" :label="$t('form.labels.soil_bank_number')"
                              :maxlength="50"/>
        </ui-view-card>
      </div>
    </form>
  </div>
</template>

<script>

import {inject, ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import UiViewCardIds from '@/components/ui-view-card-ids'
import ContactOrganisationType from '@/types/contactOrganisationType'
import MatisLocationTypes from '@/models/MatisLocationTypes'
import Annex7RecoveryDestinationType from '@/types/annex7RecoveryDestinationType'
import meansOfTransport from '@/types/meansOfTransport'

export default {
  name:       'OrganisationForm',
  computed:   {
    meansOfTransport() {
      return meansOfTransport
    },
    Annex7RecoveryDestinationType() {
      return Annex7RecoveryDestinationType
    },
  },
  components: {
    UiViewCardIds,
    UiViewCardLanguage,
    UiViewCardField,
    UiViewCardAddress,
    UiViewCard,
  },
  props:      {
    item: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const $t = inject('$t')

    let itemRef = ref(props.item)

    itemRef.value.address         = itemRef.value.address ?? {}
    itemRef.value.shipmentAddress = itemRef.value.shipmentAddress ?? {}

    const types = ref([
      {label: $t('organisations.types.PRODUCER'), value: 'PRODUCER'},
      {label: $t('organisations.types.ORGANISER'), value: 'ORGANISER'},
      {label: $t('organisations.types.TRANSPORTER'), value: 'TRANSPORTER'},
      {label: $t('organisations.types.PROCESSOR'), value: 'PROCESSOR'},
    ])

    //----------------------------
    // Same Address checkbox logic
    //----------------------------
    const shipmentAddress = JSON.parse(JSON.stringify(itemRef.value.shipmentAddress))
    const address         = JSON.parse(JSON.stringify(itemRef.value.address))
    delete shipmentAddress.id
    delete address.id
    const sameAddress = ref(JSON.stringify(shipmentAddress) === JSON.stringify(address))

    watch(() => sameAddress.value, (newVal) => {
      if (!newVal) {
        itemRef.value.shipmentAddress = {}
      } else {
        // don't copy over id, just all the rest
        // eslint-disable-next-line
        const {id, ...address} = itemRef.value.address
        Object.assign(itemRef.value.shipmentAddress, address)
      }
    })

    // if sameAddress then keep shipmentAddress in sync with address
    watch(() => itemRef.value.address, () => {
      if (sameAddress.value) {
        // don't copy over id, just all the rest
        // eslint-disable-next-line
        const {id, ...address} = itemRef.value.address
        Object.assign(itemRef.value.shipmentAddress, address)
      }
    }, {deep: true})

    const languages = ref([
      {label: $t('languages.dutch'), value: 'NL'},
      {label: $t('languages.english'), value: 'EN'},
      {label: $t('languages.french'), value: 'FR'},
      {label: $t('languages.german'), value: 'DE'},
    ])

    const matisLocationTypes       = ref([])
    const matisLocationTypesLoaded = ref(false)
    const getMatisLocationTypes    = async () => {
      matisLocationTypes.value       = await MatisLocationTypes.get()
      matisLocationTypesLoaded.value = true
    }
    getMatisLocationTypes()

    return {
      languages,
      itemRef,
      types,
      sameAddress,
      ContactOrganisationType,
      matisLocationTypes,
      matisLocationTypesLoaded,
    }
  },
}
</script>
