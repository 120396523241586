<template>
  <grondbank-list-support v-if="$orgType(OrganisationType.SUPPORT)"/>
  <grondbank-list-driver v-else-if="roles.hasOnlyRole(roles.DRIVER)"/>
  <grondbank-list-user v-else/>
</template>

<script>
import roles from '@/util/roles'
import GrondbankListUser from '@/views/forms/grondbank/List-User.vue'
import GrondbankListDriver from '@/views/forms/grondbank/List-Driver.vue'
import session from '@/util/session'
import OrganisationType from '@/types/organisationType'
import GrondbankListSupport from '@/views/forms/grondbank/List-Support.vue'
import {useFormStore} from '@/stores/FormStore'
import DocumentType from '@/types/documentType'

export default {
  name:       'GrondbankList',
  computed:   {
    OrganisationType() {
      return OrganisationType
    },
    roles() {
      return roles
    },
  },
  components: {
    GrondbankListSupport,
    GrondbankListDriver,
    GrondbankListUser,
  },
  setup:      () => {
    session.setActiveForm('grondbank')

    const formStore = useFormStore()
    formStore.init(DocumentType.GRONDBANK)

    return {}
  },
}
</script>
