<template>
  <loading-layout :title="$t('waste_identification.edit.title', {id})" :back-route="`/waste_identification/view/${id}`"
                  v-if="formStore.loading"/>
  <layout v-else>
    <template #header>
      <ui-header data-cy="waste-identification-title" :title="$t('waste_identification.edit.title', {id})"
                 :back-route="`/waste-identification/view/${id}`">
        <template #actions v-if="!isFinal">
          <button data-cy="wi-check" type="button" class="btn btn-outline-secondary" @click="formStore.formCheck()"><i
              class="bi-check"/>&nbsp;{{ $t('form.check_form') }}
          </button>
          <button data-cy="wi-save" type="button" class="btn btn-outline-primary" :disabled="saving"
                  @click="formStore.save()"
                  form="waste-identification-form"><i class="bi-save"/>&nbsp;{{ $t('general.save') }}
          </button>
        </template>
        <template #actions v-else>
          <button data-cy="wi-check" type="button" class="btn btn-outline-secondary" @click="formStore.formCheck()"><i
              class="bi-check"/>&nbsp;{{ $t('form.check_form') }}
          </button>
          <button data-cy="wi-cancel" type="button" class="btn btn-outline-secondary" @click="formStore.goToView()"><i
              class="bi-x"/>&nbsp;{{ $t('general.cancel') }}
          </button>
          <button data-cy="wi-refinalise" type="button" class="btn btn-outline-primary" :disabled="saving"
                  @click="refinaliseStore.showRefinalise(true)"><i class="bi-save"/>&nbsp;{{ $t('general.refinalise') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <waste-identification-form :item="form"/>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import {watch} from 'vue'
import WasteIdentificationForm from '@/partials/waste-identification/Form'
import DocumentType from '@/types/documentType'
import {useFormStore} from '@/stores/FormStore'
import {storeToRefs} from 'pinia'
import LoadingLayout from '@/partials/LoadingLayout.vue'
import {useRefinaliseStore} from '@/stores/modals/RefinaliseStore'

export default {
  name:       'WasteIdentificationEdit',
  components: {
    LoadingLayout,
    WasteIdentificationForm,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION, BREADCRUMBS.EDIT]

    const formStore = useFormStore()
    formStore.init(DocumentType.WASTE_IDENTIFICATION)

    formStore.loadForm(props.id)

    watch(() => props.id, () => {
      formStore.loadForm(props.id)
    })

    const refinaliseStore = useRefinaliseStore()

    return {
      breadcrumbs,
      formStore,
      ...storeToRefs(formStore),
      refinaliseStore,
    }
  },
}
</script>
