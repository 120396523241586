import {defineStore, storeToRefs} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import {useWarningStore} from "@/stores/modals/WarningStore";

export const useRefinaliseStore = defineStore('refinalise', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const {form} = storeToRefs(formStore)
  const warningStore = useWarningStore()
  const execute = ref(false)

  const refinaliseModal = ref('refinaliseModal')

  const showRefinalise = (shouldExecute) => {
    execute.value = shouldExecute
    refinaliseModal.value.modal.open()
  }

  const refinalise = async () => {
    if (!execute.value) {
      await formStore.goToEdit()
      return
    }

    if (!formStore.validate(true)) {
      return
    }

    const warning = await formStore.warningChecks()
    if (warning) {
      await warningStore.showWarning(warning, performRefinalise)
      return
    }

    await performRefinalise()
  }

  const performRefinalise = async () => {
    try {
      const saveResult = await backend.put(`api/documents/${form.value.id}`, form.value)
      if (saveResult.status === 200) {
        const finishResult = await backend.put(`api/documents/${form.value.id}/finish`)
        if (finishResult.status === 200) {
          notifier.success('toast.finish_successful')
          await formStore.goToView()
        } else {
          notifier.error('toast.finish_failed')
          refinaliseModal.value.modal.close()
        }
      }
    } catch (e) {
      notifier.error('toast.save_failed')
    } finally {
      refinaliseModal.value.modal.close()
    }
  }

  return {
    refinaliseModal,
    showRefinalise,
    refinalise,
    form,
  }
})