<template>
  <layout>
    <template #header>
      <ui-header :title="$t('waste_identification.view.title', {id})" back-route="/waste-identification/list">
        <template #actions>
          <template v-if="viewStep === ViewStep.VIEWING">
            <div class="btn-group">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                      data-bs-toggle="dropdown">
                {{ $t('general.actions') }}
              </button>
              <ul data-cy="contacts-actions" class="dropdown-menu">
                <li
                    v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT])"
                    id="fullViewBtn"
                >
                  <button v-if="showDriverView" class="dropdown-item" @click="$emit('showDriverView')">
                    {{ $t('waste_identification.view.show_simple_form') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && !FormState.isEndState(form.state)"
                    id="shareBtn"
                    :title="!FormState.canShare(form.state) ? $t('waste_identification.view.only_signed_shared') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canShare(form.state) ? 'disabled' : ''"
                          @click="shareStore.showShare([form.id])">
                    {{ $t('waste_identification.view.share_with_driver') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT, roles.SUPPORT]) && !FormState.isDraft(form.state)"
                    id="sharedWithBtn"
                    :title="!FormState.isDraft(form.state) ? $t('waste_identification.view.only_signed_shared') : ''"
                >
                  <button class="dropdown-item" :class="FormState.isDraft(form.state) ? 'disabled' : ''"
                          @click="sharedWithStore.showSharedWith()">
                    {{ $t('waste_identification.view.show_shared_with') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT])"
                    id="changeLogBtn"
                >
                  <button class="dropdown-item" @click="changeLogStore.showChangeLog()">
                    {{ $t('waste_identification.view.view_change_log') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER_BOSSCHAERT])"
                    :title="!FormState.canExport(form.state) ? $t('waste_identification.view.draft_no_export') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canExport(form.state) ? 'disabled' : ''"
                          @click="exportStore.showExport([form.id])">
                    {{ $t('waste_identification.view.export') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])"
                >
                  <button class="dropdown-item" @click="duplicateStore.showDuplicate([form.id])">
                    {{ $t('waste_identification.view.duplicate') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && !FormState.isEndState(form.state)"
                    id="deleteBtn"
                    :title="!FormState.canDelete(form.state) ? $t('waste_identification.view.only_draft_deleted') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canDelete(form.state) ? 'disabled' : ''"
                          @click="deleteStore.showDelete([form.id])">
                    {{ $t('waste_identification.view.delete') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && !FormState.isEndState(form.state)"
                    id="cancelBtn"
                    :title="!FormState.canCancel(form.state) ? $t('waste_identification.view.only_signed_cancel') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canCancel(form.state) ? 'disabled' : ''"
                          @click="cancelStore.showCancel()">
                    {{ $t('waste_identification.view.cancel') }}
                  </button>
                </li>
                <li
                    v-if="$flag('empty_container') && $roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && !FormState.isEndState(form.state)"
                    id="setStateEmptyBtn"
                    :title="!FormState.canSetStateEmpty(form.state) ? $t('waste_identification.view.only_draft_empty') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canSetStateEmpty(form.state) ? 'disabled' : ''"
                          @click="setStateEmptyStore.showSetStateEmpty([form.id])">
                    {{ $t('waste_identification.view.set_state_empty') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && form.state && FormState.canControl(form.state)"
                    id="editBtn"
                >
                  <button class="dropdown-item" @click="controlStore.showControl()">
                    {{ $t('waste_identification.view.control') }}
                  </button>
                </li>
                <li
                    v-if="(canStart || canArrive) && $roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && form.state && !FormState.isEndState(form.state)"
                    id="editBtn"
                >
                  <button class="dropdown-item" @click="formStore.goToEdit()">
                    {{ $t('waste_identification.view.edit') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && form.state === FormState.FINISHED && $flag('EDIT_FINAL')"
                >
                  <button class="dropdown-item" @click="refinaliseStore.showRefinalise(false)">
                    {{ $t('waste_identification.view.edit') }}
                  </button>
                </li>
                <li
                    v-if="$flag('ATTACHMENTS') && $roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT, roles.SUPPORT])"
                >
                  <button class="dropdown-item" @click="attachmentsStore.showAttachments()">
                    {{ $t('waste_identification.view.manage_attachments') }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- If we can start/stop then show this as main button, else show edit-->
            <button
                v-if="(!canStart && !canArrive) && $roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && form.state && !FormState.isEndState(form.state)"
                type="button"
                class="btn"
                :class="!canSign && FormState.isDraft(form.state) ? 'btn-outline-primary' : 'btn-outline-secondary'"
                @click="formStore.goToEdit()"
            >
              <i class="bi-pencil"/>&nbsp;{{ $t('waste_identification.view.edit') }}
            </button>
            <button
                v-if="canSign && $roles([roles.ORGANISATION_ADMIN, roles.USER, roles.DRIVER_BOSSCHAERT]) && FormState.isDraft(form.state)"
                type="button" class="btn btn-outline-primary"
                @click="clickSignOrganiserBtn()"
                :disabled="locating"
            >
              <i class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_as_organiser') }}
            </button>
            <button
                v-if="!$roles([roles.DRIVER], false) && $roles([roles.USER]) && FormState.SIGNED === form.state"
                type="button"
                class="btn btn-outline-primary"
                @click="shareStore.showShare([form.id])"
            >
              {{ $t('waste_identification.view.share_with_driver') }}
            </button>
            <button
                id="startBtn"
                v-if="canStart"
                type="button"
                class="btn btn-outline-primary"
                @click="start()"
                :disabled="locating"
            >
              {{ $t('waste_identification.view.start_transport') }}
            </button>
            <button
                id="arriveBtn"
                v-if="canArrive"
                type="button"
                class="btn btn-outline-primary"
                @click="formStore.stop()"
                :disabled="locating"
            >
              {{ $t('waste_identification.view.stop_transport') }}
            </button>
            <button
                v-if="$roles([roles.USER]) && form.state && FormState.canFinish(form.state)"
                type="button"
                class="btn btn-outline-primary"
                @click="finishStore.showFinish([form.id])"
            >
              <i class="bi-check-circle"/>&nbsp;{{ $t('waste_identification.view.finish') }}
            </button>
            <button
                id="backBtn"
                v-if="roles.hasOnlyRole(roles.DRIVER) && form.state === FormState.ARRIVED"
                type="button"
                class="d-flex d-sm-none btn btn-outline-primary"
                @click="formStore.goToList()"
            >
              {{ $t('waste_identification.view.to_overview') }}
            </button>
          </template>
          <div class="btn-group" v-if="viewStep === ViewStep.CHECKING">
            <button type="button" class="btn btn-outline-secondary" @click="editWeightsStore.showEditWeights()">
              <i class="bi-pencil"/>&nbsp;{{ $t('share_link.processor.edit_weights') }}
            </button>
            <button id="signForDeliveryBtn" type="button" class="btn btn-outline-primary" @click="goSign()"
                    :disabled="saving || signing">
              <i class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
            </button>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <template v-if="viewStep === ViewStep.ARRIVING">
        <div id="arrive" class="d-flex justify-content-center">
          <h3 class="d-flex" v-if="!$isMobile">
                <span class="btn-group pe-3">
                  <button id="signProcessorBtn" type="button" class="btn btn-outline-primary" @click="signProcessor()">{{
                      $t('shared.sign_as_processor')
                    }}</button>
                  <button id="signDriverBtn" type="button" class="btn btn-outline-secondary"
                          @click="signDriver()">{{ $t('shared.sign_as_driver') }}</button>
                  <button id="refuseBtn" type="button" class="btn btn-outline-secondary"
                          @click="refuseStore.showRefuse()">{{ $t('shared.refuse') }}</button>
                </span>
          </h3>
          <h3 v-else>
            <button style="height:5rem; width:100%" id="signProcessorBtn" type="button"
                    class="btn btn-outline-primary" @click="signProcessor()">{{ $t('shared.sign_as_processor') }}
            </button>
            <button style="height:5rem; width:100%" id="signDriverBtn" type="button"
                    class="btn btn-outline-secondary" @click="signDriver()">{{ $t('shared.sign_as_driver') }}
            </button>
            <button style="height:5rem; width:100%" id="refuseBtn" type="button" class="btn btn-outline-secondary"
                    @click="refuseStore.showRefuse()">{{ $t('shared.refuse') }}
            </button>
          </h3>
        </div>
      </template>
      <template v-else-if="viewStep === ViewStep.CHECKING">
        <waste-identification-form-view :form="form" :key="form.id+'-'+form.lastUpdate" @signed="signed()"/>
      </template>
      <template v-else-if="viewStep === ViewStep.SIGNING">
        <div class="container-fluid">
          <form id="processor-form" class="row g-2 justify-content-center">
            <ui-view-card data-cy="information" :title="$t('shared.processor_information')"
                          :key="signerInfo.language">
              <template #actions>
                <button type="button" class="btn btn-sm btn-outline-secondary" @click="backToControl()">
                  {{ $t('general.back') }}
                </button>
              </template>
              <ui-view-card-language v-model="signerInfo.language" :required="true" :own-language="true"/>
              <template v-if="signerInfo.language">
                <ui-view-card-field data-cy="processor-first-name" :label="$t('user.labels.first_name')"
                                    v-model="signerInfo.firstName" :required="true"/>
                <ui-view-card-field data-cy="processor-last-name" :label="$t('user.labels.last_name')"
                                    v-model="signerInfo.lastName" :required="true"/>
                <ui-view-card-field data-cy="processor-organisation" :label="$t('user.labels.organisation')"
                                    v-model="signerInfo.organisation" :required="true"/>
                <br/>
                <br/>
                <div class="row g-0 justify-content-center">
                  <button id="signBtn" type="button" class="btn" :class="'btn-outline-primary'" @click="sign(true)"
                          form="processor-form" :disabled="signing"><i
                      class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
                  </button>
                </div>
              </template>
            </ui-view-card>
          </form>
        </div>
      </template>
      <template v-else>
        <waste-identification-form-view :form="form" :key="form.id+'-'+form.lastUpdate" @signed="signed()"/>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {computed, inject, ref, watch} from 'vue'
import WasteIdentificationFormView from '@/partials/waste-identification/FormView'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import OrganisationType from '@/types/organisationType'
import roles from '@/util/roles'
import geolocation from '@/services/geolocation'
import session from '@/util/session'
import DocumentType from '@/types/documentType'
import WasteIdentificationModel from '@/models/forms/WasteIdentificationModel'
import UiViewCard from '@/components/ui-view-card.vue'
import UiViewCardField from '@/components/ui-view-card-field.vue'
import UiViewCardLanguage from '@/components/ui-view-card-language.vue'
import {useFormStore} from '@/stores/FormStore'
import {storeToRefs} from 'pinia'
import {useExportStore} from '@/stores/modals/ExportStore'
import ViewStep from '@/types/viewStep'
import {useDuplicateStore} from '@/stores/modals/DuplicateStore'
import {useAttachmentsStore} from '@/stores/modals/AttachmentsStore'
import {useChangeLogStore} from '@/stores/modals/ChangeLogStore'
import {useShareStore} from '@/stores/modals/ShareStore'
import {useCancelStore} from '@/stores/modals/CancelStore'
import {useDeleteStore} from '@/stores/modals/DeleteStore'
import {useFinishStore} from '@/stores/modals/FinishStore'
import {useControlStore} from '@/stores/modals/ControlStore'
import {useRefuseStore} from '@/stores/modals/RefuseStore'
import {useEditWeightsStore} from '@/stores/modals/EditWeightsStore'
import {useSharedWithStore} from '@/stores/modals/SharedWithStore'
import {useSetStateEmptyStore} from '@/stores/modals/SetStateEmptyStore'
import {useRefinaliseStore} from '@/stores/modals/RefinaliseStore'

export default {
  name:       'WasteIdentificationViewUser',
  computed:   {
    ViewStep() {
      return ViewStep
    },
    DocumentType() {
      return DocumentType
    },
    FormState() {
      return FormState
    },
    OrganisationType() {
      return OrganisationType
    },
  },
  components: {
    UiViewCardLanguage,
    UiViewCardField,
    UiViewCard,
    WasteIdentificationFormView,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id:             {
      type:     String,
      required: true,
    },
    showDriverView: {
      type:    Boolean,
      default: false,
    },
  },
  emits:      ['showDriverView'],
  setup:      (props) => {
    const $tset       = inject('$tset')
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION, BREADCRUMBS.VIEW]
    const notifier    = Notifier()
    const canSign     = ref(false)

    const formStore        = useFormStore()
    const {form, viewStep} = storeToRefs(formStore)

    const exportStore        = useExportStore()
    const duplicateStore     = useDuplicateStore()
    const attachmentsStore   = useAttachmentsStore()
    const changeLogStore     = useChangeLogStore()
    const shareStore         = useShareStore()
    const cancelStore        = useCancelStore()
    const deleteStore        = useDeleteStore()
    const finishStore        = useFinishStore()
    const controlStore       = useControlStore()
    const refuseStore        = useRefuseStore()
    const editWeightsStore   = useEditWeightsStore()
    const sharedWithStore    = useSharedWithStore()
    const setStateEmptyStore = useSetStateEmptyStore()
    const refinaliseStore    = useRefinaliseStore()

    canSign.value = WasteIdentificationModel.modelCheck(form.value) && !form.value.organiserSignature

    // ********************
    // start and arrive for DRIVER role

    const canStart = computed(() => {
      return roles.hasOneOfRoles([roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && FormState.canStart(form.value.state)
    })

    const canArrive = computed(() => {
      return roles.hasOneOfRoles([roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && FormState.canArrive(form.value.state)
    })

    watch(() => canStart.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    watch(() => canArrive.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    const locating = ref(false)
    geolocation.watchPosition()
    const start = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          start()
        }, 3000)
      } else {
        const user         = session.getUser()
        const organisation = session.getOrganisation()
        backend.put(`api/documents/${props.id}/start`, {
          'coordinates':  geolocation.getLocation(),
          'firstName':    user.firstName,
          'lastName':     user.lastName,
          'organisation': organisation.name,
        }).then((r) => {
          notifier.success('toast.start_location_saved')
          form.value = r.data
        }).catch(() => {
          notifier.error('toast.location_failed')
        }).finally(() => {
          locating.value = false
        })
      }
    }

    const signer        = ref(null)
    const signerInfo    = ref({})
    const signProcessor = () => {
      signer.value     = 'PROCESSOR'
      viewStep.value   = ViewStep.CHECKING
      signerInfo.value = {
        'language':     form.value.processorIdentification.language,
        'organisation': form.value.processorIdentification.name,
      }
    }

    // language gets set when signer is chosen, so we change to that person's language
    watch(() => signerInfo.value.language, (l) => {
      $tset(l)
    })

    const signDriver = () => {
      signer.value       = 'DRIVER'
      viewStep.value     = ViewStep.CHECKING
      const user         = session.getUser()
      const organisation = session.getOrganisation()
      signerInfo.value   = {
        'firstName':    user.firstName,
        'lastName':     user.lastName,
        'language':     user.language,
        'organisation': organisation.name,
      }
    }

    const backToControl = () => {
      $tset(session.getLanguage())
      viewStep.value = ViewStep.CHECKING
    }

    const signing = ref(false)
    const goSign  = () => {
      if (signer.value === 'PROCESSOR') {
        viewStep.value = ViewStep.SIGNING
      } else {
        sign()
      }
    }

    const sign = (checkForm = false) => {
      signing.value = true
      if (checkForm) {
        const formElement = $('#processor-form')[0]
        if (!formElement.checkValidity()) {
          // something in the form is not valid so don't allow signing
          formElement.reportValidity()
          signing.value = false
          return false
        }
      }
      performSign()
    }

    const performSign = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          performSign()
        }, 3000)
      } else {
        locating.value   = false
        const signingUrl = `api/documents/${props.id}/stop`
        backend.put(signingUrl, {
          'coordinates':  geolocation.getLocation(),
          'firstName':    signerInfo.value.firstName,
          'lastName':     signerInfo.value.lastName,
          'organisation': signerInfo.value.organisation,
        }).then((r) => {
          notifier.success('toast.form_signed')
          form.value         = r.data
          formStore.viewStep = ViewStep.VIEWING
          $tset(session.getLanguage())
        }).catch(() => {
        }).finally(() => {
          signing.value = false
        })
      }
    }

    const clickSignOrganiserBtn = () => {
      signing.value = true
      document.getElementById('signOrganiserBtn').click()
      setTimeout(() => {
        signing.value = false
      }, 4000)
    }

    const signed = () => {
      $tset(session.getLanguage())
      formStore.viewStep = ViewStep.VIEWING
      signing.value      = false
      formStore.reloadForm()
    }

    return {
      breadcrumbs,
      formStore,
      ...storeToRefs(formStore),
      exportStore,
      duplicateStore,
      attachmentsStore,
      changeLogStore,
      shareStore,
      cancelStore,
      deleteStore,
      finishStore,
      controlStore,
      refuseStore,
      editWeightsStore,
      sharedWithStore,
      setStateEmptyStore,
      refinaliseStore,
      canSign,
      canStart,
      canArrive,
      backToControl,
      start,
      stop,
      locating,
      signProcessor,
      signDriver,
      signerInfo,
      signing,
      goSign,
      sign,
      clickSignOrganiserBtn,
      signed,
    }
  },
}
</script>
