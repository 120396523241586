const OrganisationModel = {
  empty: {
    id: null,
    language: null,
    name: '',
    email: '',
    phone: '',
    address: {},
    shipmentAddress: {},
    identifications: [{
      value: null,
      identificationType: null
    }],
  },
}

export default OrganisationModel
