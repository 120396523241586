<template>
  <ui-modal id="editWasteModal" ref="modal" :callback="cb" :on-open="init" size="lg" :confirm-button="'modal.save'">
    <template #title>
      {{ $t('transport_document_templates.waste_identification.edit_waste_modal.title') }}
    </template>
    <template #default>
      <ui-view-card-waste v-model="wasteRef.wasteIdentification" :include-amount="true"/>
      <hr/>
      <div class="bg-info-subtle fst-italic">
        {{ $t('transport_document_templates.waste_identification.edit_waste_modal.text') }}
      </div>
      <ui-view-card-field
          :label="$t('transport_document_templates.waste_identification.edit_waste_modal.allowed_processors')"
          :required="true">
        <v-select :options="availableProcessors" :label="'name'" :reduce="proc => proc.id"
                  v-model="wasteRef.allowedProcessors" :multiple="true">
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                v-bind="attributes"
                v-on="events"
            />
          </template>
          <template #no-options>
            {{ $t('general.no_options') }}
          </template>
        </v-select>
      </ui-view-card-field>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref} from 'vue'
import UiViewCardWaste from '@/components/ui-view-card-waste'
import UiViewCardField from '@/components/ui-view-card-field'

export default {
  name:       'EditWasteModal',
  components: {
    UiViewCardField,
    UiViewCardWaste,
    UiModal,
  },
  props:      {
    waste:      {
      type:     Object,
      required: true,
    },
    processors: {
      type:     Array,
      required: true,
    },
    callback:   {
      type:     Function,
      required: true,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const wasteRef = ref({})
    const init     = () => {
      wasteRef.value = JSON.parse(JSON.stringify(props.waste))
    }

    const availableProcessors = ref([])
    availableProcessors.value = props.processors.filter((processor) => {
      return processor.name && processor.id
    })

    const cb = () => {
      return props.callback(wasteRef.value)
    }

    return {
      modal,
      wasteRef,
      availableProcessors,
      cb,
      init,
    }
  },
}
</script>
