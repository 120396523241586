<template>
  <ui-modal ref="modal" :callback="setStateEmptyStore.setStateEmptyFunc" :can-confirm="() => { return ids.length > 0}">
    <template #title>
      {{ $t('waste_identification.set_state_empty_modal.title') }}
    </template>
    <template #default>
      {{ $t('waste_identification.set_state_empty_modal.set_state_empty', {amount: ids.length}) }}
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import {useSetStateEmptyStore} from '@/stores/modals/SetStateEmptyStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormSetStateEmptyModal',
  components: {UiModal},
  setup:      () => {
    const modal              = ref('modal')
    const setStateEmptyStore = useSetStateEmptyStore()

    return {
      modal,
      setStateEmptyStore,
      ...storeToRefs(setStateEmptyStore),
    }
  },
}
</script>
