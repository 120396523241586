<template>
  <ui-modal ref="modal" :callback="cb" size="xl">
    <template #title>
      {{ $t('support.translation_modal.title') }}
    </template>
    <template #default>
      <ui-view-card-field v-model="translationRef.translationKey"
                          :label="$t('support.translation_modal.translation_key')" :readonly="!translationRef.new"/>
      <ui-view-card-field v-model="translationRef.en"
                          :label="$t('support.translation_modal.en')"/>
      <ui-view-card-field v-model="translationRef.nl"
                          :label="$t('support.translation_modal.nl')"/>
      <ui-view-card-field v-model="translationRef.fr"
                          :label="$t('support.translation_modal.fr')"/>
      <ui-view-card-field v-model="translationRef.de"
                          :label="$t('support.translation_modal.de')"/>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal.vue'
import {ref} from 'vue'
import UiViewCardField from '@/components/ui-view-card-field.vue'

export default {
  name:       'TranslationModal',
  components: {
    UiViewCardField,
    UiModal,
  },
  props:      {
    callback: {
      type:     Function,
      required: true,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const translationRef = ref({})

    const cb = async () => {
      await props.callback(translationRef.value)
      modal.value.close()
    }

    return {
      modal,
      translationRef,
      cb,
    }
  },
}
</script>