import identificationService from '@/services/identificationService'

const WasteIdentificationTemplateModel = {
  empty:                                      {
    'description':         '',
    'euralCode':           null,
    'dangerous':           false,
    'removalOperation':    null,
    'processingTechnique': null,
    'physical':            null,
    'chemical':            null,
    'packagingType':       null,
    'amountOfPackages':    null,
    'amount':              null,
    'externalId':          null,
  },
  templateCheck:                              (template) => {
    const validProducers = template.producers.length > 0 && template.producers.filter((producer) => {
      return !WasteIdentificationTemplateModel.validProducer(producer)
    }).length === 0
    if (!validProducers) {
      return 'toast.invalid_producer'
    }
    const validProcessors = template.processors.length > 0 && template.processors.filter((processor) => {
      return !WasteIdentificationTemplateModel.validProcessor(processor)
    }).length === 0
    if (!validProcessors) {
      return 'toast.invalid_processor'
    }
    const validOrganiser = template.organiser === null || (template.organiser && WasteIdentificationTemplateModel.validOrganiser(template.organiser))
    if (!validOrganiser) {
      return 'toast.invalid_organiser'
    }
    const validTransporters = template.transporters.length > 0 && template.transporters.filter((transporter) => {
      return !WasteIdentificationTemplateModel.validTransporter(transporter)
    }).length === 0
    if (!validTransporters) {
      return 'toast.invalid_transporter'
    }
    const validWastes = template.wasteIdentificationTemplateProcessors.length > 0 && template.wasteIdentificationTemplateProcessors.filter((waste) => {
      return !WasteIdentificationTemplateModel.validWasteIdentificationTemplateProcessors(waste, template.processors)
    }).length === 0
    if (!validWastes) {
      return 'toast.invalid_waste'
    }
  },
  validWasteIdentificationTemplateProcessors: (waste, processors) => {
    const validIDs         = processors.map((proc) => {
      return proc.id
    })
    let allProcessorsValid = waste.allowedProcessors &&
      waste.allowedProcessors.length > 0
    waste.allowedProcessors.forEach((processor) => {
      if (validIDs.indexOf(processor) === -1) {
        allProcessorsValid = false
      }
    })
    return WasteIdentificationTemplateModel.validWaste(waste.wasteIdentification) &&
      allProcessorsValid
  },
  validWaste:                                 (waste) => {
    return waste.description &&
      waste.euralCode &&
      waste.removalOperation &&
      waste.processingTechnique &&
      waste.amount && Number(waste.amount.replace(',', '.')) > 0 &&
      (!waste.dangerous || (
          waste.dangerous &&
          waste.physical &&
          waste.chemical &&
          waste.packagingType &&
          (waste.amountOfPackages || waste.amountOfPackages === 0)
        )
      )
  },
  validIdentifications:                       (model, allowZero = false) => {
    if (!model.identifications || model.identifications.length === 0) {
      return allowZero
    }
    for (let i = 0; i < model.identifications.length; i++) {
      const identification = model.identifications[i]

      if (allowZero && identification.value === null && identification.identificationType === null) {
        continue
      }

      if (!identification.value || !identification.identificationType) {
        return false
      }
    }

    return identificationService.validateIdentifications(model)
  },
  validCheckAddress:                          (address) => {
    return !!(address &&
      address.street &&
      address.number &&
      address.zip &&
      address.city &&
      address.country)
  },
  validProducer:                              (producer) => {
    return !!(producer?.name &&
      WasteIdentificationTemplateModel.validCheckAddress(producer?.address) &&
      WasteIdentificationTemplateModel.validCheckAddress(producer?.shipmentAddress) &&
      WasteIdentificationTemplateModel.validProducerZip(producer) &&
      producer?.email &&
      producer?.language &&
      WasteIdentificationTemplateModel.validIdentifications(producer)
    )
  },
  validProducerZip:                           (producer) => {
    const address = producer?.shipmentAddress?.zip
                    ? producer.shipmentAddress
                    : producer.address
    return address.zip >= 1000 && address.zip <= 9999
  },
  validProcessor:                             (processor) => {
    return !!(processor?.name &&
      WasteIdentificationTemplateModel.validCheckAddress(processor?.address) &&
      processor?.email &&
      processor?.language &&
      WasteIdentificationTemplateModel.validIdentifications(processor)
    )
  },
  validOrganiser:                             (organiser) => {
    const allIdentificationsValid = WasteIdentificationTemplateModel.validIdentifications(organiser)
    return organiser.name &&
      organiser.language &&
      organiser.address &&
      organiser.address.street &&
      organiser.address.number &&
      organiser.address.zip &&
      organiser.address.city &&
      organiser.address.country &&
      organiser.identifications &&
      organiser.identifications.length > 0 &&
      allIdentificationsValid
  },
  validTransporter:                           (transporter) => {
    return !!(transporter?.name &&
      WasteIdentificationTemplateModel.validCheckAddress(transporter?.address) &&
      transporter?.language &&
      WasteIdentificationTemplateModel.validIdentifications(transporter)
    )
  },
}

export default WasteIdentificationTemplateModel
