<template>
  <ui-modal id="shareModal" ref="modal" :callback="cb" :can-confirm="isValid" :on-open="init()"
            :on-close="shareStore.resetQR">
    <template #title>
      {{ $t('forms.share_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        <template v-if="ids.length > 1">
          {{ $t('forms.share_modal.share_forms', {amount: ids.length}) }}
        </template>
        <template v-else>
          {{ $t('forms.share_modal.share_form') }}
        </template>
      </div>
      <label>{{ $t('forms.share_modal.share_with') }}:</label>
      <form id="driver-form">
        <div class="row">
          <div class="col-lg-12">
            <Popper arrow class="light" @close="closePopper" :show="showPopperEmail && suggestions.length > 0"
                    v-click-outside="closePopper">
              <ui-view-card-field data-cy="share-modal-driver-email" :label="$t('drivers.form.email')"
                                  v-model="driver.email"
                                  :required="true"/>
              <template #content>
                <div v-for="suggestion in suggestions" :key="suggestion.name" class="popper-suggestion">
                  <div @click="useSuggestion(suggestion)">
                    {{ suggestion.firstName }} {{ suggestion.lastName }}: {{ suggestion.email }}
                  </div>
                </div>
              </template>
            </Popper>
          </div>
          <div class="col-lg-6">
            <Popper arrow class="light" @close="closePopper" :show="showPopperFirstName && suggestions.length > 0"
                    v-click-outside="closePopper">
              <ui-view-card-field data-cy="share-modal-driver-first-name" :label="$t('drivers.form.first_name')"
                                  v-model="driver.firstName"/>
              <template #content>
                <div v-for="suggestion in suggestions" :key="suggestion.name" class="popper-suggestion">
                  <div @click="useSuggestion(suggestion)">
                    {{ suggestion.firstName }} {{ suggestion.lastName }}: {{ suggestion.email }}
                  </div>
                </div>
              </template>
            </Popper>
          </div>
          <div class="col-lg-6">
            <Popper arrow class="light" @close="closePopper" :show="showPopperLastName && suggestions.length > 0"
                    v-click-outside="closePopper">
              <ui-view-card-field data-cy="share-modal-driver-last-name" :label="$t('drivers.form.last_name')"
                                  v-model="driver.lastName"/>
              <template #content>
                <div v-for="suggestion in suggestions" :key="suggestion.name" class="popper-suggestion">
                  <div @click="useSuggestion(suggestion)">
                    {{ suggestion.firstName }} {{ suggestion.lastName }}: {{ suggestion.email }}
                  </div>
                </div>
              </template>
            </Popper>
          </div>
          <div class="col-lg-6">
            <Popper arrow class="light" @close="closePopper" :show="showPopperOrganisation && suggestions.length > 0"
                    v-click-outside="closePopper">
              <ui-view-card-field data-cy="share-modal-driver-organisation" :label="$t('drivers.form.organisation')"
                                  v-model="driver.organisation"/>
              <template #content>
                <div v-for="suggestion in suggestions" :key="suggestion.name" class="popper-suggestion">
                  <div @click="useSuggestion(suggestion)">
                    {{ suggestion.organisation }}: {{ suggestion.firstName }} {{ suggestion.lastName }}
                  </div>
                </div>
              </template>
            </Popper>
          </div>
          <div class="col-lg-6">
            <ui-view-card-language data-cy="share-modal-driver-language" :label="$t('drivers.form.language')"
                                   v-model="driver.language" :required="true"/>
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="share-modal-driver-workslip" :label="$t('forms.share_modal.include_work_slip')"
                                v-model="driver.workslip">
              <input id="workslipCheckbox" class="form-check-input" type="checkbox" v-model="driver.workslip">
            </ui-view-card-field>
          </div>
        </div>
      </form>
      <template v-if="ids.length === 1 && !list">
        <hr/>
        <div class="d-flex justify-content-center">
          <button id="generateQRCode" type="button" :class="generating ? 'disabled' : ''"
                  class="btn btn-outline-secondary" @click="shareStore.generateQR()"
                  v-if="!qrSrc">
            {{ $t('forms.share_modal.generate_qr_code') }}
          </button>
          <div class="d-flex justify-content-center" v-if="qrSrc">
            <img id="qr-canvas" :src="qrSrc" alt="qrSrc"/>
          </div>
        </div>
        <hr/>
        <div class="d-flex justify-content-center">
          <button id="generateWorkSlip" type="button" class="btn btn-outline-secondary"
                  :disabled="shareStore.generating"
                  @click="shareStore.generateWorkSlip">
            {{ $t('forms.share_modal.generate_work_slip') }}
          </button>
        </div>
      </template>
    </template>
  </ui-modal>
</template>

<script>

import {ref, watch} from 'vue'
import UiModal from '@/components/ui-modal'
import {debounce} from '@/util/debounce'
import backend from '@/util/backend'
import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import {useShareStore} from '@/stores/modals/ShareStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormShareModal',
  components: {
    UiViewCardLanguage,
    UiViewCardField,
    UiModal,
  },
  props:      {
    amount:       {
      type: Number,
    },
    id:           {
      type:    Number,
      default: null,
    },
    callback:     {
      type: Function,
    },
    documentType: {
      type: String,
    },
  },
  setup:      (props) => {
    const modal                                                 = ref('modal')
    const shareStore                                            = useShareStore()
    const {driver, qrSrc, generatedWorkSlip, ids, documentType} = storeToRefs(shareStore)
    shareStore.initDriver()

    const init = () => {
      if (props.id) {
        ids.value          = [props.id]
        documentType.value = props.documentType
      }
    }

    const cb = async () => {
      if (props.callback) {
        await props.callback(driver.value)
        shareStore.initDriver()
      } else {
        await shareStore.share()
        shareStore.initDriver()
      }
    }

    const isValid = () => {
      return validShareWith() || qrSrc.value || generatedWorkSlip.value
    }

    const validShareWith = () => {
      return !!driver.value.email && driver.value.email.includes('@') && !!driver.value.language
    }

    const suggestions     = ref([])
    const usingSuggestion = ref(false)
    const showPopperEmail = ref(true)
    watch(() => driver.value.email, debounce(async (newVal) => {
      if (newVal && !usingSuggestion.value) {
        const result = await backend.get(`api/drivers?email=${encodeURIComponent(newVal)}`)
        if (result.status === 200 && result.data.length > 0) {
          showPopperEmail.value = true
          suggestions.value     = result.data.slice(0, 5)
        } else {
          showPopperEmail.value = false
        }
      }
    }, 300))

    const showPopperFirstName = ref(true)
    watch(() => driver.value.firstName, debounce(async (newVal) => {
      if (newVal && !usingSuggestion.value) {
        const result = await backend.get(`api/drivers?firstName=${encodeURIComponent(newVal)}`)
        if (result.status === 200 && result.data.length > 0) {
          showPopperFirstName.value = true
          suggestions.value         = result.data.slice(0, 5)
        } else {
          showPopperFirstName.value = false
        }
      }
    }, 300))

    const showPopperLastName = ref(true)
    watch(() => driver.value.lastName, debounce(async (newVal) => {
      if (newVal && !usingSuggestion.value) {
        const result = await backend.get(`api/drivers?lastName=${encodeURIComponent(newVal)}`)
        if (result.status === 200 && result.data.length > 0) {
          showPopperLastName.value = true
          suggestions.value        = result.data.slice(0, 5)
        } else {
          showPopperLastName.value = false
        }
      }
    }, 300))

    const showPopperOrganisation = ref(true)
    watch(() => driver.value.organisation, debounce(async (newVal) => {
      if (newVal && !usingSuggestion.value) {
        const result = await backend.get(`api/drivers?organisation=${encodeURIComponent(newVal)}`)
        if (result.status === 200 && result.data.length > 0) {
          showPopperOrganisation.value = true
          suggestions.value            = result.data.slice(0, 5)
        } else {
          showPopperOrganisation.value = false
        }
      }
    }, 300))

    const closePopper = () => {
      showPopperEmail.value        = false
      showPopperFirstName.value    = false
      showPopperLastName.value     = false
      showPopperOrganisation.value = false
    }

    const useSuggestion = (suggestion) => {
      driver.value          = Object.assign(driver.value, suggestion)
      usingSuggestion.value = true
      setTimeout(() => {
        usingSuggestion.value = false
      }, 400)
      closePopper()
    }

    return {
      shareStore,
      ...storeToRefs(shareStore),
      isValid,
      modal,
      cb,
      suggestions,
      showPopperEmail,
      showPopperFirstName,
      showPopperLastName,
      showPopperOrganisation,
      closePopper,
      useSuggestion,
      usingSuggestion,
      init,
    }
  },
}
</script>
