<template>
  <loading-layout :title="$t('waste_identification.view.title', {id})" back-route="/waste-identification/list"
                  v-if="formStore.loading"/>
  <template v-else>
    <waste-identification-view-driver :id="id" v-if="roles.hasOnlyRole(roles.DRIVER)"/>
    <waste-identification-view-user :id="id" v-else/>
  </template>
</template>

<script>

import WasteIdentificationViewDriver from '@/views/forms/waste-identification/View-Driver'
import WasteIdentificationViewUser from '@/views/forms/waste-identification/View-User'
import {useFormStore} from '@/stores/FormStore'
import LoadingLayout from '@/partials/LoadingLayout.vue'
import DocumentType from '@/types/documentType'
import {watch} from 'vue'

export default {
  name:       'WasteIdentificationView',
  components: {
    LoadingLayout,
    WasteIdentificationViewDriver,
    WasteIdentificationViewUser,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const formStore = useFormStore()
    formStore.init(DocumentType.WASTE_IDENTIFICATION)

    formStore.loadForm(props.id)

    watch(() => props.id, () => {
      formStore.init(DocumentType.WASTE_IDENTIFICATION)
      formStore.loadForm(props.id)
    })

    return {
      formStore,
    }
  },
}
</script>
