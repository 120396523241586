import {defineStore} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import {useFormListStore} from "@/stores/FormListStore";

export const useSetStateEmptyStore = defineStore('setStateEmpty', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const formListStore = useFormListStore()
  const ids = ref([])
  const list = ref(false)

  const setStateEmptyModal = ref('setStateEmptyModal')

  const showSetStateEmpty = (selectedIDs, isList) => {
    ids.value = selectedIDs
    list.value = isList
    setStateEmptyModal.value.modal.open()
  }

  const setStateEmptyFunc = async () => {
    if (!list.value) {
      await setStateEmptyOne()
    } else {
      await setStateEmptyList()
    }
  }

  const setStateEmptyOne = async () => {
    try {
      const result = await backend.put(`api/documents/${ids.value[0]}/empty`)
      if (result.status === 200) {
        notifier.success('toast.update_successful')
      } else {
        notifier.error('toast.update_failed')
      }
    } catch (e) {
      notifier.error('toast.update_failed')
    } finally {
      setStateEmptyModal.value.modal.close()
      await formStore.reloadForm()
    }
  }

  const setStateEmptyList = async () => {
    try {
      if (ids.value.length > 0) {
        const result = await backend.post('api/documents/batch', {
          'action': 'EMPTY',
          'ids': ids.value,
        })
        if (result.status === 200) {
          notifier.success('toast.update_successful')
        } else {
          notifier.error('toast.update_failed')
        }
      }
    } catch (err) {
      notifier.error('toast.update_successful')
    } finally {
      setStateEmptyModal.value.modal.close()
      formListStore.reload()
    }
  }

  return {
    setStateEmptyModal,
    showSetStateEmpty,
    setStateEmptyFunc,
    ids,
    list,
  }
})