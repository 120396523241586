<template>
  <router-view/>
</template>

<script>

export default {
  name:       'App',
  components: {},
  setup:      () => {
    return {}
  },
}
</script>

