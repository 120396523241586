import {defineStore} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import {useWarningStore} from "@/stores/modals/WarningStore";

export const useStopRideStore = defineStore('stopRide', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const warningStore = useWarningStore()
  const ride = ref({})

  const stopRideModal = ref('stopRideModal')

  const initRide = () => {
    ride.value = JSON.parse(JSON.stringify(formStore.form.soilRides.find((r) => {
      return r.startOfTransport && !r.endOfTransport
    })))
    ride.value.endOfTransport = new Date()
  }

  const showStopRide = () => {
    initRide()
    stopRideModal.value.modal.open()
  }

  const stopRide = async () => {
    if (ride.value.volume * 1.6 > 50) {
      await warningStore.showWarning('warning.heavy_load', performStopRide)
    } else {
      await performStopRide()
    }
    stopRideModal.value?.modal.close()
  }

  const performStopRide = async () => {
    const id = formStore.form?.id
    if (!id) {
      return notifier.error('toast.ride_stop_failed')
    }
    try {
      const rideToStop = formStore.form.soilRides.find((r) => {
        return r.startOfTransport && !r.endOfTransport
      })

      rideToStop.volume = ride.value.volume
      rideToStop.endOfTransport = ride.value.endOfTransport.getHours().toString().padStart(2, '0') + ':' + ride.value.endOfTransport.getMinutes().toString().padStart(2, '0')

      const result = await backend.put(`api/documents/${id}/rides/${rideToStop.id}/stop`, rideToStop)
      if (result.status !== 200) {
        notifier.error('toast.ride_stop_failed')
      } else {
        notifier.success('toast.ride_stopped')
        await formStore.reloadForm(id)
      }
    } catch (err) {
      notifier.error('toast.ride_stop_failed')
    } finally {
      stopRideModal.value.modal.close()
    }
  }

  return {
    stopRideModal,
    showStopRide,
    stopRide,
    ride,
  }
})