const weightEditedChecker = {
  check: (form) => {
    if (!form.documentAuditLogs) {
      return false
    }
    for (let i = 0; i < form.documentAuditLogs.length; i++) {
      if (form.documentAuditLogs[i].field === 'wasteIdentification.amount') {
        return true
      }
    }
    return false
  }
}

export default weightEditedChecker
