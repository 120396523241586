<template>
  <layout>
    <template #header>
      <ui-header :title="$t('waste_identification.list.waste_identification')">
        <template #actions v-if="currentTab === TABS.FORMS.value">
          <div v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER_BOSSCHAERT])" class="btn-group">
            <button id="actionsDropdown" :disabled="selection.length === 0"
                    class="btn btn-outline-secondary dropdown-toggle"
                    data-bs-toggle="dropdown" type="button">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="waste-identification-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])" id="shareBtn"
                  :title="shareableSelectionIDs.length === 0 ? $t('waste_identification.view.only_signed_shared') : ''">
                <button :disabled="shareableSelectionIDs.length === 0" class="dropdown-item"
                        @click="shareStore.showShare(shareableSelectionIDs, true)">
                  {{ $t('waste_identification.list.actions.share_with_driver') }}
                </button>
              </li>
              <li v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER_BOSSCHAERT])"
                  :title="notDraftSelectionIDs.length === 0 ? $t('waste_identification.view.draft_no_export') : ''">
                <button :disabled="notDraftSelectionIDs.length === 0" class="dropdown-item"
                        @click="exportStore.showExport(notDraftSelectionIDs, true)">
                  {{ $t('waste_identification.list.actions.export') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])">
                <button :disabled="selection.length === 0" class="dropdown-item"
                        @click="duplicateStore.showDuplicate(selectionIDs, true)">
                  {{ $t('waste_identification.list.actions.duplicate') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])"
                  :title="draftAndEmptySelectionIDs.length === 0 ? $t('waste_identification.view.only_draft_delete') : ''">
                <button class="dropdown-item" :disabled="draftAndEmptySelectionIDs.length === 0"
                        @click="deleteStore.showDelete(draftAndEmptySelectionIDs, true)">
                  {{ $t('waste_identification.list.actions.delete') }}
                </button>
              </li>
              <li v-if="$flag('empty_container') && $roles([roles.USER, roles.DRIVER_BOSSCHAERT])">
                <button :disabled="selection.length === 0" class="dropdown-item"
                        @click="setStateEmptyStore.showSetStateEmpty(draftSelectionIDs, true)">
                  {{ $t('waste_identification.list.actions.set_state_empty') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])"
                  :title="finishableSelectionIDs.length === 0 ? $t('waste_identification.view.only_arrived_finish') : ''">
                <button :disabled="finishableSelectionIDs.length === 0" class="dropdown-item"
                        @click="finishStore.showFinish(finishableSelectionIDs, true)">
                  {{ $t('waste_identification.list.actions.finish') }}
                </button>
              </li>
            </ul>
          </div>
          <button v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])" class="btn btn-outline-primary"
                  data-cy="create-form" type="button"
                  @click="formStore.addForm()"><i class="bi-plus"/>&nbsp;{{ $t('general.add_form') }}
          </button>
        </template>
        <template #actions v-else-if="currentTab === TABS.TEMPLATES.value">
          <waste-identification-list-user-templates-actions :selection="selectedTemplates" @reload="reloadTemplates()"/>
        </template>
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
        <ui-list-tabs v-if="$flag('SJABLONEN') && $roles([roles.USER])" v-model="currentTab"
                      :tabs="[TABS.FORMS, TABS.TEMPLATES]"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary"
                @click="currentTab === TABS.FORMS.value ? formListStore.resetGrid() : templates.resetGrid()">{{
            $t('general.reset_filters')
          }}
        </button>
      </div>
    </div>
    <ui-content>
      <div class="tab-content h-100">
        <div
          id="forms"
          :class="{
            'show': currentTab === TABS.FORMS.value,
            'active': currentTab === TABS.FORMS.value,
          }"
          class="tab-pane fade h-100"
          role="tabpanel"
        >
          <div class="d-flex flex-column h-100">
            <ag-grid-vue
              :serverSideDatasource="grid.datasource('api/documents/list?documentType=WASTE_IDENTIFICATION')"
              :grid-options="grid.defaultOptions"
              :columnDefs="xsWindow ? xsColumnDefs : columnDefs"
              class="ag-theme-quartz"
              style="min-height:100%"
              @row-clicked="formListStore.formRowClick"
              @selection-changed="formListStore.refreshSelection"
              @grid-ready="formListStore.onGridReady"
              @grid-pre-destroyed="formListStore.onGridPreDestroyed"
              @filter-changed="formListStore.onFilterChanged"
              @first-data-rendered="formListStore.onFirstDataRendered"
              :initialState="initialState"
            />
          </div>
        </div>
        <div
          id="templates"
          :class="{
            'show': currentTab === TABS.TEMPLATES.value,
            'active': currentTab === TABS.TEMPLATES.value,
          }"
          class="tab-pane fade h-100"
          role="tabpanel"
        >
          <waste-identification-list-user-templates ref="templates" v-model:selection="selectedTemplates"
                                                    :active="currentTab === TABS.TEMPLATES.value"/>
        </div>
      </div>
    </ui-content>
  </layout>
</template>

<script>

import {computed, inject, onBeforeUnmount, ref, watch} from 'vue'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import {useRoute, useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import '@vuepic/vue-datepicker/dist/main.css'
import DocumentType from '@/types/documentType'
import stateColorRenderer from '@/util/stateColorRenderer'
import checkboxRenderer from '@/util/checkboxRenderer'
import grid from '@/util/grid'
import UiListTabs from '@/components/ui-list-tabs'
import TABS from '@/types/tabs'
import WasteIdentificationListUserTemplates from '@/views/forms/waste-identification/List-User-Templates'
import TransportDocumentTemplateModel from '@/models/TransportDocumentTemplateModel'
import WasteIdentificationListUserTemplatesActions from '@/views/forms/waste-identification/List-User-Templates-Actions'
import WeightEditedIcon from '@/components/weight-edited-icon.vue'
import {useFormListStore} from '@/stores/FormListStore'
import {storeToRefs} from 'pinia'
import {useFormStore} from '@/stores/FormStore'
import {useFinishStore} from '@/stores/modals/FinishStore'
import {useSetStateEmptyStore} from '@/stores/modals/SetStateEmptyStore'
import {useDeleteStore} from '@/stores/modals/DeleteStore'
import {useDuplicateStore} from '@/stores/modals/DuplicateStore'
import {useExportStore} from '@/stores/modals/ExportStore'
import {useShareStore} from '@/stores/modals/ShareStore'

export default {
  name:       'WasteIdentificationListUser',
  computed:   {
    TABS() {
      return TABS
    },
    grid() {
      return grid
    },
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    WasteIdentificationListUserTemplatesActions,
    WasteIdentificationListUserTemplates,
    UiListTabs,
    UiContent,
    UiHeader,
    Layout,
    UiBreadcrumbs,
    AgGridVue,
    // eslint-disable-next-line
    stateColorRenderer,
    // eslint-disable-next-line
    checkboxRenderer,
    // eslint-disable-next-line
    WeightEditedIcon,
  },
  setup:      () => {
    const breadcrumbs       = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION]
    const $t                = inject('$t')
    const notifier          = Notifier()
    const route             = useRoute()
    const currentTab        = ref(route?.query?.tab ?? TABS.FORMS.value)
    const router            = useRouter()
    const selectedTemplates = ref([]) // for some reason emit update:selection causes rerender

    const formListStore = useFormListStore()
    formListStore.init('waste-identification-list-user')

    const shareStore         = useShareStore()
    const exportStore        = useExportStore()
    const duplicateStore     = useDuplicateStore()
    const deleteStore        = useDeleteStore()
    const setStateEmptyStore = useSetStateEmptyStore()
    const finishStore        = useFinishStore()
    const formStore          = useFormStore()

    const xsWindow     = ref(window.innerWidth < 576)
    const xsColumnDefs = [
      {
        headerName:  $t('waste_identification.list.table.key'),
        valueGetter: (params) => {
          let res = 'DGF-' + params.data.id
          if (params.data.demo) {
            res += ' (demo)'
          }
          return res
        },
        field:       'id',
      },
      {
        headerName:   $t('waste_identification.list.table.state'),
        field:        'state',
        cellRenderer: 'stateColorRenderer',
        filter:       'agSetColumnFilter',
        filterParams: {
          values:         FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          },
        },
      },
    ]

    const columnDefs = [
      {
        headerName:  $t('waste_identification.list.table.key'),
        field:       'id',
        maxWidth:    250,
        valueGetter: (params) => {
          let res = 'DGF-' + params.data.id
          if (params.data.demo) {
            res += ' (demo)'
          }
          return res
        },
      },
      {
        headerName:     $t('waste_identification.list.table.date'),
        field:          'transportDate',
        maxWidth:       250,
        filter:         'agDateColumnFilter',
        filterParams:   {
          minValidYear: 2000,
          maxValidYear: 2099,
          // defaultOption: DateFormatter.formatDate(new Date()),
          filterOptions: ['inRange'],
        },
        valueGetter:    (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        },
      },
      {headerName: $t('waste_identification.list.table.waste_description'), field: 'wasteDescription'},
      {
        headerName:     $t('waste_identification.list.table.amount'),
        field:          'amount',
        maxWidth:       200,
        cellRenderer:   (params) => {
          let result = ''
          if (params.data.amount) {
            result += params.data.amount + ' ' + $t('form.table.tonne')
          }
          if (params.data.amountChanged) {
            const weightEdited = $t('form.weight_edited')
            result
              += ' <span class="fs-4" v-if="weightEdited" title="' + weightEdited + '">' +
              '<i class="bi-pencil" style="font-size: 14px; color:green"/>' +
              '</span>'
          }
          return result
        },
        valueFormatter: (params) => {
          if (params.value) {

            return params.value + ' ' + $t('form.table.tonne')
          } else {
            return ''
          }
        },
      },
      {headerName: $t('waste_identification.list.table.from'), field: 'from'},
      {headerName: $t('waste_identification.list.table.to'), field: 'to'},
      {headerName: $t('waste_identification.list.table.transporter'), field: 'transporter'},
      {
        headerName:         $t('waste_identification.list.table.dangerous'),
        field:              'dangerous',
        maxWidth:           200,
        suppressSizeToFit:  true,
        width:              100,
        filter:             'agSetColumnFilter',
        filterParams:       {
          values:         ['WASTE_TRANSPORT_DANGEROUS', 'WASTE_TRANSPORT_NON_DANGEROUS'],
          valueFormatter: (val) => {
            return $t('form.type.' + val.value)
          },
        },
        cellRenderer:       'checkboxRenderer',
        cellRendererParams: {
          callback: (data) => {
            return data.dangerous
          },
        },
      },
      {
        headerName:   $t('waste_identification.list.table.state'),
        field:        'state',
        maxWidth:     400,
        cellRenderer: 'stateColorRenderer',
        filter:       'agSetColumnFilter',
        filterParams: {
          values:         FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          },
        },
      },
    ]

    let active = computed(() => {
      return currentTab.value === TABS.FORMS
    })
    let sized  = false
    watch(() => active.value, (val) => {
      if (val && !sized) {
        resize()
        sized = true
      }
    })

    const resize = () => {
      xsWindow.value = window.innerWidth < 576
      setTimeout(() => {
        formListStore.api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }
    window.addEventListener('resize', resize)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resize)
      formListStore.api.value = null
    })

    const addTemplate = async () => {
      const result = await backend.post('api/transportDocumentTemplates', JSON.parse(JSON.stringify(TransportDocumentTemplateModel.wasteIdentification)))
      if (result.status === 200) {
        router.push(`/transport-document-templates/edit/${result.data.id}`)
      } else {
        notifier.error('toast.template_create_failed')
      }
    }

    const templates       = ref('templates')
    const reloadTemplates = () => {
      templates.value.reload()
    }

    return {
      breadcrumbs,
      formListStore,
      ...storeToRefs(formListStore),
      shareStore,
      exportStore,
      duplicateStore,
      deleteStore,
      setStateEmptyStore,
      finishStore,
      formStore,
      xsWindow,
      xsColumnDefs,
      columnDefs,
      addTemplate,
      currentTab,
      selectedTemplates,
      templates,
      reloadTemplates,
      resize,
    }
  },
}
</script>
