import {defineStore} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";
import GrondbankModel from "@/models/GrondbankModel";
import DocumentType from "@/types/documentType";
import {useWarningStore} from "@/stores/modals/WarningStore";

export const useStartRideStore = defineStore('startRide', () => {
  const notifier = Notifier()
  const formStore = useFormStore()
  const warningStore = useWarningStore()
  const ride = ref({})

  const startRideModal = ref('startRideModal')

  const initRide = () => {
    switch (formStore.form.type) {
      case DocumentType.GRONDBANK: {
        ride.value = JSON.parse(JSON.stringify(GrondbankModel.soilRide))
        break
      }
    }
    ride.value.startOfTransport = new Date()
  }

  const showStartRide = () => {
    initRide()
    startRideModal.value.modal.open()
  }

  const startRide = async () => {
    if (ride.value.volume * 1.6 > 50) {
      warningStore.showWarning('warning.heavy_load', performStartRide)
    } else {
      await performStartRide()
    }
    startRideModal.value?.modal.close()
  }

  const performStartRide = async () => {
    const id = formStore.form?.id
    if (!id) {
      return notifier.error('toast.ride_start_failed')
    }
    try {
      const newRide = Object.assign(JSON.parse(JSON.stringify(GrondbankModel.soilRide)), {
        volume: ride.value.volume,
        startOfTransport: ride.value.startOfTransport.getHours().toString().padStart(2, '0') + ':' + ride.value.startOfTransport.getMinutes().toString().padStart(2, '0'),
      })
      const result = await backend.put(`api/documents/${id}/rides/start`, newRide)
      if (result.status !== 200) {
        notifier.error('toast.ride_start_failed')
      } else {
        notifier.success('toast.ride_started')
        await formStore.reloadForm(id)
      }
    } catch (err) {
      notifier.error('toast.ride_start_failed')
    } finally {
      startRideModal.value.modal.close()
    }
  }

  return {
    startRideModal,
    showStartRide,
    startRide,
    ride,
  }
})