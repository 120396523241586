const mod97Checker = {
  check: (value) => {
    const checksum = parseInt(value.substring(value.length - 2))

    const v = parseInt(value.substring(0, value.length - 2))
    const remainder = (97 - (v % 97))
    return remainder === checksum
  },
}

export default mod97Checker