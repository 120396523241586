<template>
  <ui-modal ref="modal" :callback="cb">
    <template #title>
      {{ $t('waste_identification.duplicate_modal.title') }}
    </template>
    <template #default>
      {{ $t('waste_identification.duplicate_modal.duplicate_forms', {amount: qty}) }}
    </template>
  </ui-modal>
</template>

<script>

import {computed, ref} from 'vue'
import UiModal from '@/components/ui-modal'
import duplicateModal from '@/views/transport-document-templates/Duplicate-modal.vue'
import {useDuplicateStore} from '@/stores/modals/DuplicateStore'

export default {
  name:       'FormDuplicateModal',
  computed:   {
    duplicateModal() {
      return duplicateModal
    },
  },
  components: {UiModal},
  props:      {
    amount:   {
      type: Number,
    },
    callback: {
      type: Function,
    },
  },
  setup:      (props) => {
    const modal          = ref('modal')
    const duplicateStore = useDuplicateStore()

    const qty = computed(() => {
      return props.amount ?? duplicateStore.ids.length
    })
    const cb  = props.callback ?? duplicateStore.duplicate

    return {
      modal,
      qty,
      cb,
    }
  },
}
</script>
