<template :key="id">
  <layout v-if="driverView">
    <template #header>
      <ui-header :title="$t('grondbank.view.title', {id})" back-route="/grondbank/list">
        <template #actions>
          <div class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                    data-bs-toggle="dropdown">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="driver-actions" class="dropdown-menu">
              <li id="fullViewBtn">
                <button class="dropdown-item" @click="goToFullView()">
                  {{ $t('waste_identification.view.show_complete_form') }}
                </button>
              </li>
              <li id="changeLogBtn">
                <button class="dropdown-item" @click="changeLogStore.showChangeLog()">
                  {{ $t('forms.view.view_change_log') }}
                </button>
              </li>
              <li v-if="$flag('ATTACHMENTS')">
                <button class="dropdown-item" @click="attachmentsStore.showAttachments()">
                  {{ $t('forms.view.manage_attachments') }}
                </button>
              </li>
            </ul>
          </div>
          <button
              v-if="rideInProgress && !FormState.isEndState(form.state)"
              type="button" class="btn btn-outline-secondary"
              @click="editRideStore.showEditRide(form.soilRides[form.soilRides.length-1], form.soilRides.length-1)"><i
              class="bi-pencil"/>&nbsp;{{ $t('forms.view.edit_volume') }}
          </button>
          <!--            <button-->
          <!--                v-if="!rideInProgress && !FormState.isEndState(form.state)"-->
          <!--                type="button" class="btn btn-outline-secondary"-->
          <!--            ><i class="bi-pencil-square" />&nbsp;{{ $t('forms.view.signatures') }}-->
          <!--            </button>-->
          <button
              v-if="!rideInProgress && !FormState.isEndState(form.state)"
              type="button" class="btn btn-outline-primary"
              @click="startRideStore.showStartRide"><i class="bi-truck"/>&nbsp;{{ $t('forms.view.start_ride') }}
          </button>
          <button
              v-if="rideInProgress && !FormState.isEndState(form.state)"
              type="button" class="btn btn-outline-primary"
              @click="stopRideStore.showStopRide"><i class="bi-slash-circle"/>&nbsp;{{ $t('forms.view.stop_ride') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <grondbank-form-view-driver ref="formViewDriver" :key="form.lastUpdate"/>
    </ui-content>
  </layout>
  <grondbank-view-user v-else :id="id" :show-driver-view="true" @show-driver-view="driverView=true"
                       :key="form.lastUpdate"/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import {computed, provide, ref} from 'vue'
import OrganisationType from '@/types/organisationType'
import DocumentType from '@/types/documentType'
import GrondbankViewUser from '@/views/forms/grondbank/View-User.vue'
import GrondbankFormViewDriver from '@/partials/grondbank/FormViewDriver.vue'
import {useFormStore} from '@/stores/FormStore'
import {storeToRefs} from 'pinia'
import {useChangeLogStore} from '@/stores/modals/ChangeLogStore'
import {useAttachmentsStore} from '@/stores/modals/AttachmentsStore'
import {useStartRideStore} from '@/stores/modals/StartRideStore'
import {useStopRideStore} from '@/stores/modals/StopRideStore'
import FormState from '@/types/formState'
import {useEditRideStore} from '@/stores/modals/EditRideStore'

export default {
  name:       'GrondbankViewDriver',
  computed:   {
    DocumentType() {
      return DocumentType
    },
    FormState() {
      return FormState
    },
    OrganisationType() {
      return OrganisationType
    },
  },
  components: {
    GrondbankFormViewDriver,
    GrondbankViewUser,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK, BREADCRUMBS.VIEW]

    const formStore = useFormStore()
    const {form}    = storeToRefs(formStore)

    const changeLogStore   = useChangeLogStore()
    const attachmentsStore = useAttachmentsStore()
    const startRideStore   = useStartRideStore()
    const stopRideStore    = useStopRideStore()
    const editRideStore    = useEditRideStore()

    const processorInfo = ref(null)
    provide('processorInfo', processorInfo)
    processorInfo.value = {organisation: form.value.soilDestination.name}

    const driverView   = ref(true)
    const goToFullView = () => {
      driverView.value = false
    }

    const rideInProgress = computed(() => {
      let res = false
      form.value.soilRides.forEach((ride) => {
        if (ride.startOfTransport && !ride.endOfTransport) {
          res = true
        }
      })
      return res
    })

    return {
      breadcrumbs,
      ...storeToRefs(formStore),
      formStore,
      changeLogStore,
      attachmentsStore,
      startRideStore,
      stopRideStore,
      editRideStore,
      driverView,
      goToFullView,
      rideInProgress,
    }
  },
}
</script>
