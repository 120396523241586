<template>
  <loading-layout :title="$t('annex_7.view.title', {id})" back-route="/annex-7/list" v-if="formStore.loading"/>
  <template v-else>
    <annex7-view-user :id="id"/>
  </template>
</template>

<script>

import Annex7ViewUser from '@/views/forms/annex-7/View-User.vue'
import {useFormStore} from '@/stores/FormStore'
import DocumentType from '@/types/documentType'
import {watch} from 'vue'
import LoadingLayout from '@/partials/LoadingLayout.vue'

export default {
  name:       'Annex7View',
  components: {
    LoadingLayout,
    Annex7ViewUser,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const formStore = useFormStore()
    formStore.init(DocumentType.ANNEX_7)

    formStore.loadForm(props.id)

    watch(() => props.id, () => {
      formStore.loadForm(props.id)
    })
    return {
      formStore,
    }
  },
}
</script>
