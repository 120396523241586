<template>
  <v-select :options="countries" v-model="itemRef" :reduce="country => country.code">
    <template #search="{attributes, events}">
      <input
          class="vs__search"
          :required="required && !itemRef"
          v-bind="attributes"
          v-on="events"
      />
    </template>
    <template #no-options>
      {{ $t('general.no_options') }}
    </template>
  </v-select>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'
import CountryCodes from '@/models/CountryCodes'

export default {
  name:  'country-selector',
  props: {
    modelValue: {
      default: '',
    },
    required:   {
      type:    Boolean,
      default: false,
    },
  },
  setup: (props, {emit}) => {
    const itemRef = useModelWrapper(props, emit)

    const countries = []
    countries.push(...CountryCodes.top)
    countries.push(...CountryCodes.rest) // TODO sort rest alphabetically

    return {
      itemRef,
      countries,
    }
  },
}
</script>