import DocumentType from '../types/documentType'
import backend from '@/util/backend'
import TransportDocumentTemplateState from '@/types/transportDocumentTemplateState'
import WasteIdentificationTemplateModel from "@/models/templates/WasteIdentificationTemplateModel";

const TransportDocumentTemplate = {
  wasteIdentification: {
    id: null,
    documentType: DocumentType.WASTE_IDENTIFICATION,
    name: '',
    notes: '',
    producers: [],
    processors: [],
    wasteIdentificationTemplateProcessors: [],
    transporters: [],
    organiser: null,
    organiserSignature: null
  },
  canSignWasteIdentification: (template) => {
    if (!template.name) {
      return 'toast.invalid_name'
    }
    const err = TransportDocumentTemplate.templateCheck(template)
    if (err) {
      return err
    }
  },
  templateCheck: (template) => {
    switch (template.documentType) {
      case DocumentType.WASTE_IDENTIFICATION:
        return WasteIdentificationTemplateModel.templateCheck(template)
    }
  },
  setSigned: async (template) => {
    return await backend.put(`api/transportDocumentTemplates/${template.id}/changeState`, {transportDocumentTemplateState: TransportDocumentTemplateState.SIGNED})
  },
  setDraft: async (template) => {
    return await backend.put(`api/transportDocumentTemplates/${template.id}/changeState`, {transportDocumentTemplateState: TransportDocumentTemplateState.DRAFT})
  },
}

export default TransportDocumentTemplate
