<template>
  <layout v-if="driverView">
    <template #header>
      <ui-header :title="$t('waste_identification.view.title', {id})" back-route="/waste-identification/list">
        <template #actions>
          <template v-if="viewStep === ViewStep.VIEWING">
            <div class="btn-group">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                      data-bs-toggle="dropdown">
                {{ $t('general.actions') }}
              </button>
              <ul data-cy="driver-actions" class="dropdown-menu">
                <li id="fullViewBtn">
                  <button class="dropdown-item" @click="goToFullView()">
                    {{ $t('waste_identification.view.show_complete_form') }}
                  </button>
                </li>
                <li id="changeLogBtn">
                  <button class="dropdown-item" @click="changeLogStore.showChangeLog()">
                    {{ $t('waste_identification.view.view_change_log') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && form.state && FormState.canControl(form.state)"
                    id="editBtn"
                >
                  <button class="dropdown-item" @click="controlStore.showControl()">
                    {{ $t('waste_identification.view.control') }}
                  </button>
                </li>
                <li
                    v-if="(canStart || canArrive) && $roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && form.state && !FormState.isEndState(form.state)"
                    id="editBtn"
                >
                  <button class="dropdown-item" @click="formStore.goToEdit()">
                    {{ $t('waste_identification.view.edit') }}
                  </button>
                </li>
                <li v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && $flag('ATTACHMENTS')">
                  <button class="dropdown-item" @click="attachmentsStore.showAttachments">
                    {{ $t('waste_identification.view.manage_attachments') }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- If we can start/stop then show this as main button, else show edit-->
            <button
                v-if="(!canStart && !canArrive) && $roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && form.state && !FormState.isEndState(form.state)"
                type="button" class="btn btn-outline-secondary"
                @click="formStore.goToEdit()"><i class="bi-pencil"/>&nbsp;{{ $t('waste_identification.view.edit') }}
            </button>
            <button
                id="startBtn"
                v-if="canStart"
                type="button"
                class="btn btn-outline-primary"
                @click="start()"
                :disabled="locating"
            >
              {{ $t('waste_identification.view.start_transport') }}
            </button>
            <button
                id="arriveBtn"
                v-if="canArrive"
                type="button"
                class="btn btn-outline-primary"
                @click="formStore.stop()"
                :disabled="locating"
            >
              {{ $t('waste_identification.view.stop_transport') }}
            </button>
            <button
                id="backBtn"
                v-if="form.state === FormState.ARRIVED"
                type="button"
                class="d-flex d-sm-none btn btn-outline-primary"
                @click="formStore.goToList()"
            >
              {{ $t('waste_identification.view.to_overview') }}
            </button>
          </template>
          <div class="btn-group" v-if="viewStep === ViewStep.CHECKING">
            <button type="button" class="btn btn-outline-secondary" @click="editWeightsStore.showEditWeights()">
              <i class="bi-pencil"/>&nbsp;{{ $t('share_link.processor.edit_weights') }}
            </button>
            <button id="signForDeliveryBtn" type="button" class="btn btn-outline-primary" @click="goSign()"
                    :disabled="signing">
              <i class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
            </button>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <template v-if="viewStep === ViewStep.ARRIVING">
        <div id="arrive" class="d-flex justify-content-center">
          <h3 class="d-flex" v-if="!$isMobile">
              <span class="btn-group pe-3">
                <button id="signProcessorBtn" type="button" class="btn btn-outline-primary" @click="signProcessor()">{{
                    $t('shared.sign_as_processor')
                  }}</button>
                <button id="signDriverBtn" type="button" class="btn btn-outline-secondary"
                        @click="signDriver()">{{ $t('shared.sign_as_driver') }}</button>
                <button id="refuseBtn" type="button" class="btn btn-outline-secondary"
                        @click="refuseStore.showRefuse()">{{ $t('shared.refuse') }}</button>
              </span>
          </h3>
          <h3 v-else>
            <button style="height:5rem; width:100%" id="signProcessorBtn" type="button"
                    class="btn btn-outline-primary" @click="signProcessor()">{{ $t('shared.sign_as_processor') }}
            </button>
            <button style="height:5rem; width:100%" id="signDriverBtn" type="button"
                    class="btn btn-outline-secondary" @click="signDriver()">{{ $t('shared.sign_as_driver') }}
            </button>
            <button style="height:5rem; width:100%" id="refuseBtn" type="button" class="btn btn-outline-secondary"
                    @click="refuseStore.showRefuse()">{{ $t('shared.refuse') }}
            </button>
          </h3>
        </div>
      </template>
      <template v-else-if="viewStep === ViewStep.CHECKING">
        <waste-identification-form-view :form="form" :key="form.id+'-'+form.lastUpdate" @signed="signed()"/>
      </template>
      <template v-else-if="viewStep === ViewStep.SIGNING">
        <div class="container-fluid">
          <form id="processor-form" class="row g-2 justify-content-center">
            <ui-view-card data-cy="information" :title="$t('shared.processor_information')"
                          :key="signerInfo.language">
              <template #actions>
                <button type="button" class="btn btn-sm btn-outline-secondary" @click="backToControl()">
                  {{ $t('general.back') }}
                </button>
              </template>
              <ui-view-card-language v-model="signerInfo.language" :required="true" :own-language="true"/>
              <template v-if="signerInfo.language">
                <ui-view-card-field data-cy="processor-first-name" :label="$t('user.labels.first_name')"
                                    v-model="signerInfo.firstName" :required="true"/>
                <ui-view-card-field data-cy="processor-last-name" :label="$t('user.labels.last_name')"
                                    v-model="signerInfo.lastName" :required="true"/>
                <ui-view-card-field data-cy="processor-organisation" :label="$t('user.labels.organisation')"
                                    v-model="signerInfo.organisation" :required="true"/>
                <br/>
                <br/>
                <div class="row g-0 justify-content-center">
                  <button id="signBtn" type="button" class="btn" :class="'btn-outline-primary'" @click="sign(true)"
                          form="processor-form" :disabled="signing"><i
                      class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
                  </button>
                </div>
              </template>
            </ui-view-card>
          </form>
        </div>
      </template>
      <template v-else>
        <waste-identification-form-view-driver :form="form" :key="form.id+'-'+form.lastUpdate" @signed="signed()"/>
      </template>
    </ui-content>
  </layout>
  <waste-identification-view-user :show-driver-view="true" @show-driver-view="driverView=true" :id="id" v-else/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {computed, inject, ref, watch} from 'vue'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import OrganisationType from '@/types/organisationType'
import roles from '@/util/roles'
import geolocation from '@/services/geolocation'
import session from '@/util/session'
import WasteIdentificationViewUser from '@/views/forms/waste-identification/View-User'
import WasteIdentificationFormViewDriver from '@/partials/waste-identification/FormViewDriver'
import WasteIdentificationFormView from '@/partials/waste-identification/FormView.vue'
import UiViewCard from '@/components/ui-view-card.vue'
import UiViewCardField from '@/components/ui-view-card-field.vue'
import UiViewCardLanguage from '@/components/ui-view-card-language.vue'
import {useFormStore} from '@/stores/FormStore'
import {storeToRefs} from 'pinia'
import {useAttachmentsStore} from '@/stores/modals/AttachmentsStore'
import {useChangeLogStore} from '@/stores/modals/ChangeLogStore'
import {useControlStore} from '@/stores/modals/ControlStore'
import {useRefuseStore} from '@/stores/modals/RefuseStore'
import ViewStep from '@/types/viewStep'
import {useEditWeightsStore} from '@/stores/modals/EditWeightsStore'


export default {
  name:       'WasteIdentificationViewDriver',
  computed:   {
    FormState() {
      return FormState
    },
    OrganisationType() {
      return OrganisationType
    },
    ViewStep() {
      return ViewStep
    },
  },
  components: {
    UiViewCardLanguage,
    UiViewCardField,
    UiViewCard,
    WasteIdentificationFormView,
    WasteIdentificationFormViewDriver,
    WasteIdentificationViewUser,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const $tset       = inject('$tset')
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION, BREADCRUMBS.VIEW]
    const notifier    = Notifier()

    const formStore        = useFormStore()
    const {form, viewStep} = storeToRefs(formStore)
    viewStep.value         = ViewStep.VIEWING

    const attachmentsStore = useAttachmentsStore()
    const changeLogStore   = useChangeLogStore()
    const controlStore     = useControlStore()
    const refuseStore      = useRefuseStore()
    const editWeightsStore = useEditWeightsStore()

    // ********************
    // start and arrive for DRIVER role

    const canStart = computed(() => {
      return roles.hasOneOfRoles(roles.DRIVER) && FormState.canStart(form.value.state)
    })

    const canArrive = computed(() => {
      return roles.hasOneOfRoles(roles.DRIVER) && FormState.canArrive(form.value.state)
    })

    watch(() => canStart.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    watch(() => canArrive.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    const locating = ref(false)
    geolocation.watchPosition()
    const start = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          start()
        }, 3000)
      } else {
        const user         = session.getUser()
        const organisation = session.getOrganisation()
        backend.put(`api/documents/${props.id}/start`, {
          'coordinates':  geolocation.getLocation(),
          'firstName':    user.firstName,
          'lastName':     user.lastName,
          'organisation': organisation.name,
        }).then((r) => {
          notifier.success('toast.start_location_saved')
          form.value = r.data
        }).catch(() => {
          notifier.error('toast.location_failed')
        }).finally(() => {
          locating.value = false
        })
      }
    }

    const signer        = ref(null)
    const signerInfo    = ref({})
    const signProcessor = () => {
      signer.value     = 'PROCESSOR'
      viewStep.value   = ViewStep.CHECKING
      signerInfo.value = {
        'language':     form.value.processorIdentification.language,
        'organisation': form.value.processorIdentification.name,
      }
    }
    // language gets set when signer is chosen, so we change to that person's language
    watch(() => signerInfo.value.language, (l) => {
      $tset(l)
    })

    const signDriver = () => {
      signer.value       = 'DRIVER'
      viewStep.value     = ViewStep.CHECKING
      const user         = session.getUser()
      const organisation = session.getOrganisation()
      signerInfo.value   = {
        'firstName':    user.firstName,
        'lastName':     user.lastName,
        'language':     user.language,
        'organisation': organisation.name,
      }
    }

    const backToControl = () => {
      $tset(session.getLanguage())
      viewStep.value = ViewStep.CHECKING
    }

    const signing = ref(false)
    const goSign  = () => {
      if (signer.value === 'PROCESSOR') {
        viewStep.value = ViewStep.SIGNING
      } else {
        sign()
      }
    }

    const sign = (checkForm = false) => {
      signing.value = true
      if (checkForm) {
        const formElement = $('#processor-form')[0]
        if (!formElement.checkValidity()) {
          // something in the form is not valid so don't allow signing
          formElement.reportValidity()
          signing.value = false
          return false
        }
      }
      performSign()
    }

    const performSign = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          performSign()
        }, 3000)
      } else {
        locating.value   = false
        const signingUrl = `api/documents/${props.id}/stop`
        backend.put(signingUrl, {
          'coordinates':  geolocation.getLocation(),
          'firstName':    signerInfo.value.firstName,
          'lastName':     signerInfo.value.lastName,
          'organisation': signerInfo.value.organisation,
        }).then((r) => {
          notifier.success('toast.form_signed')
          form.value         = r.data
          formStore.viewStep = ViewStep.VIEWING
          $tset(session.getLanguage())
        }).catch(() => {
        }).finally(() => {
          signing.value = false
        })
      }
    }

    const signed = () => {
      $tset(session.getLanguage())
      formStore.viewStep = ViewStep.VIEWING
      signing.value      = false
      formStore.reloadForm()
    }

    // ********************

    const driverView   = ref(true)
    const goToFullView = () => {
      driverView.value = false
    }

    return {
      breadcrumbs,
      formStore,
      ...storeToRefs(formStore),
      attachmentsStore,
      changeLogStore,
      controlStore,
      refuseStore,
      editWeightsStore,
      canStart,
      canArrive,
      backToControl,
      start,
      locating,
      signProcessor,
      signDriver,
      signerInfo,
      signing,
      signed,
      goSign,
      sign,
      driverView,
      goToFullView,
    }
  },
}
</script>
