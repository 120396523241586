<template>
  <ui-modal ref="modal" :callback="cb">
    <template #title>
      {{ $t('grondbank.edit_ride.title') }}
    </template>
    <template #default>
      {{ $t('grondbank.edit_ride.text') }}
      <br/>
      <br/>
      <form id="edit-ride-modal-form">
        <ui-view-card-number :label="$t('form.table.volume')" v-model="ride.volume"/>
      </form>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref} from 'vue'
import UiViewCardNumber from '@/components/ui-view-card-number.vue'
import {useEditRideStore} from '@/stores/modals/EditRideStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormEditRideModal',
  components: {
    UiViewCardNumber,
    UiModal,
  },
  setup:      () => {
    const modal         = ref('modal')
    const editRideStore = useEditRideStore()

    const cb = async () => {
      const formElement = $('#edit-ride-modal-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow editing
        formElement.reportValidity()
        return false
      }

      await editRideStore.editRide()
    }

    return {
      modal,
      cb,
      ...storeToRefs(editRideStore),
    }
  },
}
</script>
