<template>
  <layout>
    <template #header>
      <ui-header :title=" $t('support.translations.title')">
        <template #actions>
          <button class="btn btn-primary" @click="addTranslation">
            {{ $t('support.translations.addTranslation') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-content>
      <spinner v-if="loading"/>
      <div class="container-fluid" v-if="!loading">
        <div class="row">
          <div class="col-6">
            <input type="text" class="form-control" placeholder="Search" v-model="textFilter">
          </div>
          <div class="col-6">
            <input type="checkbox" class="form-check-input" v-model="missingTranslations">
            {{ $t('support.translations.missingTranslations') }}
          </div>
        </div>
        <div class="mt-3">
          <table class="table table-bordered table-striped" style="table-layout: fixed;">
            <thead>
            <tr>
              <th></th>
              <th>Key</th>
              <th>EN</th>
              <th>NL</th>
              <th>FR</th>
              <th>DE</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="t in filteredTranslations" :key="t.id">
              <td>
                <div class="btn-group">
                  <div class="btn btn-outline-secondary" @click="editTranslation(t)">
                    <i class="bi-pencil"/>
                  </div>
                  <div class="btn btn-danger" @click="deleteTranslation(t)">
                    <i class="bi-trash"/>
                  </div>
                </div>
              </td>
              <td style="overflow: hidden;" :title="t.translationKey">{{ t.translationKey }}</td>
              <td style="overflow: hidden;" :title="t.en">{{ t.en }}</td>
              <td style="overflow: hidden;" :title="t.nl">{{ t.nl }}</td>
              <td style="overflow: hidden;" :title="t.fr">{{ t.fr }}</td>
              <td style="overflow: hidden;" :title="t.de">{{ t.de }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ui-content>
  </layout>
  <translation-modal ref="translationModal" :callback="addOrSave"/>
</template>

<script>
import UiContent from '@/components/ui-content.vue'
import UiHeader from '@/components/ui-header.vue'
import Layout from '@/components/layout.vue'
import {computed, ref} from 'vue'
import backend from '@/util/backend'
import Spinner from '@/components/spinner.vue'
import TranslationModal from '@/views/support/Translation-Modal.vue'
import Notifier from '@/util/notifier'

export default {
  name:       'SupportTranslations',
  components: {TranslationModal, Spinner, Layout, UiHeader, UiContent},
  props:      {},
  setup:      () => {
    const translations         = ref([])
    const textFilter           = ref('')
    const missingTranslations  = ref(true)
    const notifier             = Notifier()
    const filteredTranslations = computed(() => {
      const res = []
      var temp  = []

      if (missingTranslations.value) {
        translations.value.forEach((t) => {
          t.en = t.en || ''
          t.nl = t.nl || ''
          t.fr = t.fr || ''
          t.de = t.de || ''

          if (!t.en || !t.nl || !t.fr || !t.de) {
            temp.push(t)
          }
        })
      } else {
        temp = [...translations.value]
      }

      temp.forEach((t) => {
        if (t.translationKey.toLocaleLowerCase().indexOf(textFilter.value.toLocaleLowerCase()) > -1 ||
            t.en.toLocaleLowerCase().indexOf(textFilter.value.toLocaleLowerCase()) > -1 ||
            t.nl.toLocaleLowerCase().indexOf(textFilter.value.toLocaleLowerCase()) > -1 ||
            t.fr.toLocaleLowerCase().indexOf(textFilter.value.toLocaleLowerCase()) > -1 ||
            t.de.toLocaleLowerCase().indexOf(textFilter.value.toLocaleLowerCase()) > -1
        ) {
          res.push(t)
        }
      })

      return res
    })

    const loading = ref(true)

    const fetch = () => {
      backend.get('api/translations').then((res) => {
        translations.value = res.data
        // Object.keys(i18nTranslations).forEach((language) => {
        //   i18nTranslations[language].clear()
        //   translations.value.forEach((translation) => {
        //     const t                       = {}
        //     t[translation.translationKey] = translation[language.toLowerCase()]
        //     i18nTranslations[language].extend(
        //         t,
        //     )
        //   })
        // })
        loading.value = false
      })
    }
    fetch()

    const modalTranslation  = ref({})
    const addTranslation    = () => {
      showTranslationModal({new: true, en: '', nl: '', fr: '', de: ''})
    }
    const editTranslation   = (translation) => {
      showTranslationModal(translation)
    }
    const deleteTranslation = (translation) => {
      backend.delete(`api/translations/${translation.translationKey}`).then((res) => {
        if (res.status === 200) {
          notifier.success('toast.translation_deleted')
          fetch()
        } else {
          notifier.error('toast.translation_delete_failed')
        }
      })
    }

    const translationModal     = ref('translationModal')
    const showTranslationModal = (translation) => {
      translationModal.value.translationRef = translation
      translationModal.value.modal.open()
    }

    const addOrSave = (translation) => {
      if (translation.new) {
        backend.post('api/translations', translation).then((res) => {
          if (res.status === 200) {
            notifier.success('toast.translation_added')
            fetch()
          } else {
            notifier.error('toast.translation_add_failed')
          }
        })
      } else {
        backend.put(`api/translations/${translation.translationKey}`, translation).then((res) => {
          if (res.status === 200) {
            notifier.success('toast.translation_edited')
            fetch()
          } else {
            notifier.error('toast.translation_edit_failed')
          }
        })
      }
    }

    return {
      translations,
      modalTranslation,
      addTranslation,
      editTranslation,
      deleteTranslation,
      textFilter,
      filteredTranslations,
      missingTranslations,
      loading,
      addOrSave,
      translationModal,
    }
  },
}
</script>