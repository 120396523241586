<template>
  <div id="GrondbankFormView" class="container-fluid">
    <div class="row">
      <ui-view-card :title="$t('form.title.from', {from: form.soilOrigin.name})">
        <ui-view-card-address :model-value="form.soilOrigin.address" compact-address/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.to', {to: form.soilDestination.name})">
        <ui-view-card-address :model-value="form.soilDestination.address" compact-address/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.rides')" type="col-lg-6">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th scope="col">{{ $t('form.table.times') }}</th>
            <th scope="col">{{ $t('form.table.volume') }}</th>
            <th v-if="!FormState.isEndState(form.state)" scope="col" :style="{width: '1px'}"/>
          </tr>
          </thead>
          <tbody>
          <template v-for="(ride, index) in form.soilRides.slice().reverse()" :key="index">
            <tr>
              <td>
                <span v-if="ride.startOfTransport">
                  {{ ride.startOfTransport }} - {{ ride.endOfTransport }}
                </span>
              </td>
              <td>{{ ride.volume }} m³</td>
              <td v-if="!FormState.isEndState(form.state)">
                <div class="btn-group">
                  <button type="button" class="btn btn-outline-secondary"
                          @click="editRideStore.showEditRide(form.soilRides[form.soilRides.length-index-1], form.soilRides.length-index-1)">
                    <i class="bi-pencil"/>
                  </button>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card id="GrondbankBasicInfo" data-bs-toggle="collapse" :title="$t('form.title.basic_information')"
                    type="col-lg-6">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.unique_identifier') }}: {{ 'DGF-GB-' + form.id }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.status') }}: {{ $t('form.state.' + form.state) }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.transport_date') }}: {{ date }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.description') }}: {{ form.description }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.estimatedVolume') }}: {{ form.volume }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.calculatedVolume') }}: {{ form.calculatedVolume }}
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.soil.responsible')" type="col-lg-4">
        <div :key="form.id + '-' + form?.organiserSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.organiserSignature" :id="form.id"
                                        :signature-type="SignatureType.ORGANISER" @signed="formStore.reloadForm"
                                        :uuid="uuid" show-signed-via-app/>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.soil.transporter')" type="col-lg-4">
        <div :key="form.id + '-' + form?.transporterSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.transporterSignature" :id="form.id"
                                        :signature-type="SignatureType.TRANSPORTER" @signed="formStore.reloadForm"
                                        :signable="transporterFormSignable" :uuid="uuid"
                                        :subtitle="form.state === FormState.ON_THE_ROAD ? $t('signature.cant_sign_while_ride_in_progress') : ''"/>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.soil.purchaser')" type="col-lg-4">
        <div :key="form.id + '-' + form?.processorSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.processorSignature" :id="form.id"
                                        :signature-type="SignatureType.PROCESSOR" @signed="formStore.reloadForm"
                                        :signable="processorFormSignable" :uuid="uuid"
                                        :subtitle="form.state === FormState.ON_THE_ROAD ? $t('signature.cant_sign_while_ride_in_progress') : ''"/>
        </div>
      </ui-view-card>
    </div>
  </div>
</template>

<script>
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {ref} from 'vue'
import languages from '@/types/languages'
import UiViewCardSignatureField from '@/components/ui-view-card-signature-field'
import SignatureType from '@/types/signatureType'
import roles from '@/util/roles'
import FormState from '@/types/formState'
import {useFormStore} from '@/stores/FormStore'
import {storeToRefs} from 'pinia'
import {useEditRideStore} from '@/stores/modals/EditRideStore'
import session from '@/util/session'

export default {
  name:       'GrondbankFormViewDriver',
  computed:   {
    FormState() {
      return FormState
    },
    languages() {
      return languages
    },
    SignatureType() {
      return SignatureType
    },
  },
  components: {
    UiViewCardSignatureField,
    UiViewCardAddress,
    UiViewCard,
  },
  setup:      () => {
    const isSharelink = session.isAnonymousUser()

    const formStore = useFormStore()
    const {form}    = storeToRefs(formStore)

    const editRideStore = useEditRideStore()

    const transporterFormSignable = ref(false)
    const processorFormSignable   = ref(false)
    const checkSignable           = () => {
      let allRidesFinished = true
      form.value.soilRides.forEach((ride) => {
        if (!(ride.startOfTransport && ride.volume && ride.endOfTransport)) {
          allRidesFinished = false
        }
      })
      transporterFormSignable.value
          = (((roles.hasOneOfRoles(roles.DRIVER)) || isSharelink) && allRidesFinished) && !FormState.isEndState(form.value.state)
      processorFormSignable.value
          = (((roles.hasOneOfRoles(roles.DRIVER)) || isSharelink) && allRidesFinished) && !FormState.isEndState(form.value.state)
    }
    setTimeout(checkSignable, 1)

    return {
      formStore,
      ...storeToRefs(formStore),
      editRideStore,
      transporterFormSignable,
      processorFormSignable,
    }
  },
}
</script>
