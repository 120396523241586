<template>
  <layout>
    <div class="container">
      {{ text }}
      <br/>
      <br/>
      <router-link class="btn btn-primary" :to="{ name: 'Home'}">{{ $t('logout.return_to_login') }}</router-link>
    </div>
  </layout>
</template>

<script>

import {inject, ref} from 'vue'
import Layout from '@/components/layout'
import session from '@/util/session'
import backend from '@/util/backend'

export default {
  name:       'Logout',
  components: {Layout},
  setup:      () => {
    const $t   = inject('$t')
    const text = ref($t('logout.you_logged_out'))

    const logout = async () => {
      if (session.isAuthenticated()) {
        const response = await backend.getLogout()
        session.removeOrganisation()
        session.removeUser()
        session.clearSessionStorage()
        if (response?.data) {
          location.href = response.data
        }
      }
    }
    logout()

    return {text}
  },
}
</script>
