<template>
  <div class="container-fluid">
    <form id="waste-identification-form" class="row g-2">
      <ui-view-card :title="$t('form.title.basic_information')" type="col-lg-12" :demo-warning="demo">
        <div class="row">
          <div class="col-lg-6">
            <ui-view-card-field data-cy="wi-id" :label="$t('form.labels.unique_identifier')"
                                :model-value="itemRef.id ? 'DGF-'+itemRef.id : ''" :readonly="true"/>
            <ui-view-card-field data-cy="wi-external-id" :label="$t('form.labels.external_identifier')"
                                v-model="itemRef.externalId"/>
            <ui-view-card-field data-cy="wi-last-update" :label="$t('form.labels.last_update')"
                                :model-value="updateDate" :readonly="true"/>
            <ui-view-card-field data-cy="wi-updated-by" :label="$t('form.labels.updated_by')"
                                :model-value="itemRef.modifiedBy" :readonly="true"/>
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="wi-state" :label="$t('form.labels.status')"
                                :model-value="$t('form.state.'+itemRef.state)" :readonly="true"/>
            <ui-view-card-field :label="$t('form.labels.transport_date')" :required="true">
              <datepicker data-cy="wi-transport-date" v-model="itemRef.transportDate" :enableTimePicker="false"
                          :autoApply="true" :minDate="new Date()" format="dd/MM/yyyy" :required="true"/>
            </ui-view-card-field>
            <ui-view-card-field v-if="$flag('PRIVATE_NOTE')" v-model="itemRef.privateNotes"
                                :label="$t('form.labels.note_for_driver')" data-cy="wi-private-note">
              <textarea class="form-control" v-model="itemRef.privateNotes" rows="4" style="width: 100%"
                        maxlength="250"/>
            </ui-view-card-field>
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.producer')">
        <ui-view-card-producer v-model="itemRef.producerIdentification" :shipment-address="true"/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.processor')">
        <ui-view-card-processor v-model="itemRef.processorIdentification" :shipment-address="true"/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.organiser_IHM')">
        <ui-view-card-organiser v-if="itemRef.organiserIdentification" v-model="itemRef.organiserIdentification">
          <template #subtitle>{{ $t('form.organiser_subtitle') }}</template>
        </ui-view-card-organiser>
      </ui-view-card>
      <template v-for="(_, index) in itemRef.transporterIdentifications" :key="index">
        <ui-view-card
          :title="index === 0 ? $t('form.title.transporter') : $t('form.title.transporter_count', {count: index+1})">
          <template #actions>
            <button v-if="index === 0" type="button" class="btn btn-sm btn-outline-primary" @click="addTransporter()"><i
              class="bi-plus"/>&nbsp;{{ $t('form.table.add_transporter') }}
            </button>
            <button v-else type="button" class="btn btn-sm btn-outline-danger" @click="removeTransporter(index)"><i
              class="bi-minus"/>&nbsp;{{ $t('form.table.remove_transporter') }}
            </button>
          </template>
          <ui-view-card-transporter v-model="itemRef.transporterIdentifications[index]" :with-license-plate="true"/>
        </ui-view-card>
      </template>
      <ui-view-card :title="$t('form.title.waste_identification')" type="col-lg-12">
        <template #subtitle>
          <div class="mb-2">
            {{ $t('form.waste_identification_subtitle') }}&nbsp;<a
            href="https://ovam.vlaanderen.be/eural-handleiding-en-eural-wizard"
            target="blank">{{ $t('form.here') }}</a>.
          </div>
        </template>
        <template #actions>
          <button v-if="itemRef?.state !== FormState.FINISHED" type="button" class="btn btn-sm btn-outline-primary"
                  @click="addRowIdentification()"><i
            class="bi-plus"/>&nbsp;{{ $t('form.table.add_waste_row') }}
          </button>
        </template>
        <table v-if="!$isMobile" class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>{{ $t('form.table.description') }}<span :title="$t('general.required')" class="text-danger">*</span>
            </th>
            <th style="width: 200px">{{ $t('form.table.amount') }}
              <span :title="$t('general.required')" class="text-danger">
                *
              </span>
              <span class="fs-4" v-if="weightEdited" :title="$t('form.weight_edited')">
                <weight-edited-icon/>
              </span>
            </th>
            <th style="width: 200px">{{ $t('form.table.EURAL-code') }}<span :title="$t('general.required')"
                                                                            class="text-danger">*</span></th>
            <th style="width: 60px">{{ $t('form.table.dangerous') }}<span :title="$t('general.required')"
                                                                          class="text-danger">*</span></th>
            <th>{{ $t('form.table.processing_mode') }}<span :title="$t('general.required')" class="text-danger">*</span>
            </th>
            <th>{{ $t('form.table.processing_technique') }}<span :title="$t('general.required')"
                                                                 class="text-danger">*</span></th>
            <th style="width: 60px"/>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in itemRef.wasteIdentifications" :key="index">
            <tr>
              <td>
                <Popper arrow class="light" @close="closePopper(index)"
                        :show="showPopper[index] && suggestions.length > 0"
                        v-click-outside="() => { closePopper(index) }">
                  <input data-cy="waste-description" class="form-control" v-model="row.description" required
                         @input="wasteInput(index)" :maxlength="255"/>
                  <template #content>
                    <div v-for="suggestion in suggestions" :key="suggestion.description" class="popper-suggestion">
                      <div @click="useSuggestion(suggestion, index)">
                        <span>{{
                            suggestion.description + ' (' + $t('processing_techniques.' + suggestion.processingTechnique) + ')'
                          }}</span>
                      </div>
                    </div>
                  </template>
                </Popper>
              </td>
              <td>
                <div class="container">
                  <div class="row">
                    <number-input class="waste-amount" v-model="row.amount" :required="true" min="0"/>
                    <input class="col form-control" readonly :placeholder="$t('form.table.tonne')"/>
                  </div>
                </div>
              </td>
              <td v-if="euralCodesLoaded" data-cy="euralCodes">
                <v-select :options="euralCodes" v-model="row.euralCode" :reduce="code => code.value"
                          :filter="EuralCodes.ignoreSpaceSearch"
                          :disabled="itemRef?.state === FormState.FINISHED"
                          :title="itemRef?.state === FormState.FINISHED ? $t('waste_identification.cant_edit_final_eural_code') : ''">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      :required="!row.euralCode"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <template #no-options>
                    {{ $t('general.no_options') }}
                  </template>
                </v-select>
              </td>
              <td class="align-middle">
                <div class="form-check d-flex justify-content-center">
                  <input class="form-check-input" type="checkbox" disabled v-model="row.dangerous">
                </div>
              </td>
              <td data-cy="removalOperation">
                <v-select :key="removalOperationsLoaded" :options="removalOperations" v-model="row.removalOperation"
                          :reduce="operation => operation.value">
                  <template #selected-option="option">
                    {{ option.value }}
                  </template>
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      :required="!row.removalOperation"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <template #no-options>
                    {{ $t('general.no_options') }}
                  </template>
                </v-select>
              </td>
              <td data-cy="processingTechnique">
                <v-select :key="processingTechniquesLoaded" :options="processingTechniques"
                          v-model="row.processingTechnique" :reduce="operation => operation.value">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      :required="!row.processingTechnique"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <template #no-options>
                    {{ $t('general.no_options') }}
                  </template>
                </v-select>
              </td>
              <td>
                <div>
                  <div v-if="itemRef.wasteIdentifications.length > 1 && itemRef?.state !== FormState.FINISHED"
                       class="btn btn-danger"
                       @click="deleteRowIdentification(index)">
                    <i class="bi-trash"/>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <table v-else class="table table-striped">
          <tbody>
          <tr v-for="(row, index) in itemRef.wasteIdentifications" :key="index">
            <td>
              <table class="table mb-0">
                <tbody>
                <tr>
                  <th>{{ $t('form.table.description') }}<span :title="$t('general.required')" class="text-danger"
                                                              style="display: inline-table">*</span></th>
                  <Popper arrow class="light" @close="closePopper(index)"
                          :show="showPopper[index] && suggestions.length > 0"
                          v-click-outside="() => { closePopper(index) }">
                    <input data-cy="waste-description" class="form-control" v-model="row.description" required
                           @input="wasteInput(index)"/>
                    <template #content>
                      <div v-for="suggestion in suggestions" :key="suggestion.description" class="popper-suggestion">
                        <div @click="useSuggestion(suggestion, index)">
                          <span>{{
                              suggestion.description + ' (' + $t('processing_techniques.' + suggestion.processingTechnique) + ')'
                            }}</span>
                        </div>
                      </div>
                    </template>
                  </Popper>
                </tr>
                <tr>
                  <th>
                    {{ $t('form.table.amount') }}
                    <span :title="$t('general.required')" class="text-danger" style="display: inline-table">*
                      <span
                        class="fs-4" v-if="weightEdited"
                        :title="$t('form.weight_edited')">
                        <weight-edited-icon/>
                      </span>
                    </span>
                  </th>
                  <td>
                    <div class="container">
                      <div class="row">
                        <number-input v-model="row.amount" :required="true" min="0"/>
                        <input class="col form-control" readonly :placeholder="$t('form.table.tonne')"/>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.EURAL-code') }}<span :title="$t('general.required')" class="text-danger"
                                                             style="display: inline-table">*</span></th>
                  <td>
                    <v-select :options="euralCodes" v-model="row.euralCode" :reduce="code => code.value"
                              :filter="EuralCodes.ignoreSpaceSearch" :disabled="itemRef?.state === FormState.FINISHED"
                              :title="itemRef?.state === FormState.FINISHED ? $t('waste_identification.cant_edit_final_eural_code') : ''"
                    >
                      <template #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!row.euralCode"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.dangerous') }}<span :title="$t('general.required')" class="text-danger"
                                                            style="display: inline-table">*</span></th>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" disabled v-model="row.dangerous">
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.processing_mode') }}<span :title="$t('general.required')" class="text-danger"
                                                                  style="display: inline-table">*</span></th>
                  <td>
                    <v-select :key="removalOperationsLoaded" :options="removalOperations" v-model="row.removalOperation"
                              :reduce="operation => operation.value">
                      <template #selected-option="option">
                        {{ option.value }}
                      </template>
                      <template #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!row.removalOperation"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.processing_technique') }}<span :title="$t('general.required')"
                                                                       class="text-danger"
                                                                       style="display: inline-table">*</span></th>
                  <td>
                    <v-select :key="processingTechniquesLoaded" :options="processingTechniques"
                              v-model="row.processingTechnique" :reduce="operation => operation.value">
                      <template #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!row.processingTechnique"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-if="itemRef.wasteIdentifications.length > 1 && itemRef?.state !== FormState.FINISHED">
                <div class="btn btn-outline-danger" @click="deleteRowIdentification(index)">
                  <i class="bi-trash"/>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.waste_description')" v-if="isDangerous">
        <template #subtitle>{{ $t('form.waste_description_subtitle') }}</template>
        <table v-if="!$isMobile" class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>{{ $t('form.table.EURAL-code') }}</th>
            <th>{{ $t('form.table.physical') }}<span :title="$t('general.required')" class="text-danger">*</span></th>
            <th>{{ $t('form.table.chemical') }}<span :title="$t('general.required')" class="text-danger">*</span></th>
            <th>{{ $t('form.table.packaging_type') }}<span :title="$t('general.required')" class="text-danger">*</span>
            </th>
            <th>{{ $t('form.table.amount_of_packages') }}<span :title="$t('general.required')"
                                                               class="text-danger">*</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in itemRef.wasteIdentifications" :key="index">
            <template v-if="row.dangerous">
              <td>
                {{ row.euralCode ? row.euralCode.substring(6).replaceAll('_', ' ') + '*' : '' }}
              </td>
              <td>
                <input class="form-control" v-model="row.physical" required maxlength="500"/>
              </td>
              <td>
                <input class="form-control" v-model="row.chemical" required maxlength="500"/>
              </td>
              <td>
                <input class="form-control" v-model="row.packagingType" required maxlength="500"/>
              </td>
              <td>
                <input class="form-control" v-model="row.amountOfPackages" required max="999999999" min="-999999999"
                       type="number"/>
              </td>
            </template>
          </tr>
          </tbody>
        </table>
        <table v-else class="table table-striped table-striped-columns">
          <tbody>
          <tr v-for="(row, index) in itemRef.wasteIdentifications" :key="index">
            <td>
              <table v-if="row.dangerous" class="table">
                <tbody>
                <tr>
                  <th>{{ $t('form.table.EURAL-code') }}</th>
                  <td>
                    {{ row.euralCode ? row.euralCode.substring(6).replaceAll('_', ' ') + '*' : '' }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.physical') }}</th>
                  <td>
                    <input class="form-control" v-model="row.physical" required maxlength="500"/>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.chemical') }}</th>
                  <td>
                    <input class="form-control" v-model="row.chemical" required maxlength="500"/>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.packaging_type') }}</th>
                  <td>
                    <input class="form-control" v-model="row.packagingType" required maxlength="500"/>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('form.table.amount_of_packages') }}</th>
                  <td>
                    <input class="form-control" v-model="row.amountOfPackages" required max="999999999" min="-999999999"
                           type="number"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.notes')" :type="isDangerous ? 'col-lg-6' : 'col-lg-12'">
        <textarea v-model="itemRef.notes" class="form-control" maxlength="1000" rows="4" style="width: 100%"/>
      </ui-view-card>
    </form>
  </div>
</template>

<script>

import {computed, inject, ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import UiViewCardField from '@/components/ui-view-card-field'
import {DateFormatter} from '@/services/dateFormatter'
import UiViewCardProducer from '@/components/ui-view-card-producer'
import UiViewCardOrganiser from '@/components/ui-view-card-organiser'
import UiViewCardTransporter from '@/components/ui-view-card-transporter'
import UiViewCardProcessor from '@/components/ui-view-card-processor'
import RemovalOperations from '@/models/RemovalOperations'
import ProcessingTechniques from '@/models/ProcessingTechniques'
import EuralCodes from '@/models/EuralCodes'
import clearField from '@/services/clearfield'
import OrganisationModel from '@/models/OrganisationModel'
import backend from '@/util/backend'
import NumberInput from '@/components/NumberInput.vue'
import session from '@/util/session'
import OrganisationState from '@/types/organisationState'
import {debounce} from '@/util/debounce'
import weightEditedChecker from '@/services/weightEditedChecker'
import WeightEditedIcon from '@/components/weight-edited-icon.vue'
import FormState from '@/types/formState'

export default {
  name:       'WasteIdentificationForm',
  computed:   {
    FormState() {
      return FormState
    },
    EuralCodes() {
      return EuralCodes
    },
  },
  components: {
    WeightEditedIcon,
    NumberInput,
    UiViewCardProcessor,
    UiViewCardTransporter,
    UiViewCardOrganiser,
    UiViewCardProducer,
    UiViewCardField,
    Datepicker,
    UiViewCard,
  },
  props:      {
    item: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const $t = inject('$t')

    let itemRef = ref(props.item)
    if (itemRef.value.producerIdentification === null) {
      itemRef.value.producerIdentification = OrganisationModel.empty
    }
    if (itemRef.value.processorIdentification === null) {
      itemRef.value.processorIdentification = OrganisationModel.empty
    }
    if (itemRef.value.organiserIdentification === null) {
      itemRef.value.organiserIdentification = OrganisationModel.empty
    }
    if (itemRef.value.transporterIdentifications.length === 0) {
      itemRef.value.transporterIdentifications.push(OrganisationModel.empty)
    }
    const state = $t('form.state.' + itemRef.value.state)

    const addRowIdentification = () => {
      itemRef.value.wasteIdentifications.push({
        'description': '',
        'amount':      '',
        'euralCode':   null,
      })
    }

    const deleteRowIdentification = (index) => {
      itemRef.value.wasteIdentifications.splice(index, 1)
    }

    const euralCodes       = ref([])
    const euralCodesLoaded = ref(false)
    const getEuralCodes    = async () => {
      euralCodes.value       = await EuralCodes.get()
      euralCodesLoaded.value = true
      checkDangerous()
    }
    getEuralCodes()

    const getEural = (ec) => {
      for (let i = 0; i < euralCodes.value.length; i++) {
        if (euralCodes.value[i].value === ec) {
          return euralCodes.value[i]
        }
      }
      return null
    }

    const isDangerous    = ref(false)
    const checkDangerous = () => {
      let dangerous = false
      itemRef.value.wasteIdentifications.forEach((row) => {
        const eural   = getEural(row.euralCode)
        row.dangerous = eural?.dangerous ?? false
        if (row.dangerous) {
          dangerous = true
        }
      })
      isDangerous.value = dangerous
    }
    watch(() => itemRef.value.wasteIdentifications, () => {
      checkDangerous()
    }, {deep: true})


    const removalOperations       = ref([])
    const removalOperationsLoaded = ref(false)
    const getRemovalOperations    = async () => {
      removalOperations.value       = await RemovalOperations.get()
      removalOperationsLoaded.value = true
    }
    getRemovalOperations()

    const processingTechniques       = ref([])
    const processingTechniquesLoaded = ref(false)
    const getProcessingTechniques    = async () => {
      processingTechniques.value       = await ProcessingTechniques.get()
      processingTechniquesLoaded.value = true
    }
    getProcessingTechniques()

    const addTransporter = () => {
      itemRef.value.transporterIdentifications.push({'address': {}})
    }

    const removeTransporter = (index) => {
      if (index !== 0) {
        itemRef.value.transporterIdentifications.splice(index, 1)
      }
    }

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(itemRef.value.lastUpdate)
    })

    const showPopper = ref([])
    itemRef.value.wasteIdentifications.forEach(() => {
      showPopper.value.push(false)
    })
    const suggestions = ref([])
    const wasteInput  = debounce((index) => {
      const row = itemRef.value.wasteIdentifications[index]
      if (row.description) {
        backend.get(`api/wasteidentificationtemplates?search=${row.description}`).then((result) => {
          showPopper.value[index] = true
          suggestions.value       = result.data
        })
      }
    }, 300)

    const closePopper = (index) => {
      showPopper.value[index] = false
    }

    const useSuggestion = (suggestion, index) => {
      const cleared = clearField(suggestion, 'id')
      Object.assign(itemRef.value.wasteIdentifications[index], cleared)
      closePopper(index)
    }

    const weightEdited = computed(() => {
      return weightEditedChecker.check(itemRef.value)
    })

    const org  = session.getOrganisation()
    const demo = computed(() => {
      return (org && org.organisationState === OrganisationState.ONBOARDING) || itemRef.value.demo
    })

    return {
      itemRef,
      state,
      isDangerous,
      removalOperations,
      removalOperationsLoaded,
      processingTechniques,
      processingTechniquesLoaded,
      euralCodes,
      euralCodesLoaded,
      addRowIdentification,
      deleteRowIdentification,
      addTransporter,
      removeTransporter,
      updateDate,
      wasteInput,
      suggestions,
      showPopper,
      closePopper,
      useSuggestion,
      weightEdited,
      demo,
    }
  },
}
</script>

<style>
.v-select {
  background-color: white !important;
}
</style>
