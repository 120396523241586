<template>
  <div id="A7FormView" class="container-fluid">
    <div class="row">
      <ui-view-card :title="$t('form.title.basic_information')" type="col-lg-12" :demo-warning="formRef.demo">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.unique_identifier') }}: {{ 'DGF-A7-' + formRef.id }}
            <br/>
            <template v-if="formRef.externalId">
              {{ $t('form.labels.external_identifier') }}: {{ formRef.externalId }}
              <br/>
            </template>
            {{ $t('form.labels.last_update') }}: {{ updateDate }}
            <br/>
            {{ $t('form.labels.updated_by') }}: {{ formRef.modifiedBy }}

          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.status') }}: {{ $t('form.state.' + formRef.state) }}
            <br/>
            {{ $t('form.labels.transport_date') }}: {{ date }}
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.producer')">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.name') }}: {{ formRef.producer.name }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.email') }}: {{ formRef.producer.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.contact_person') }}: {{ formRef.producer.contactPerson }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.language') }}: {{ languages.getLanguage(formRef.producer.language) }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.phone_number') }}: {{ formRef.producer.phone }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.fax') }}: {{ formRef.producer.fax }}
          </div>
        </div>
        <ui-view-card-address :model-value="formRef.producer.address"/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.processor')">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.name') }}: {{ formRef.processor.name }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.email') }}: {{ formRef.processor.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.contact_person') }}: {{ formRef.processor.contactPerson }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.language') }}: {{ languages.getLanguage(formRef.processor.language) }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.phone_number') }}: {{ formRef.processor.phone }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.fax') }}: {{ formRef.processor.fax }}
          </div>
        </div>
        <ui-view-card-address :model-value="formRef.processor.address"/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.organiser_IHM')">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.name') }}: {{ formRef.organiser.name }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.email') }}: {{ formRef.organiser.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.contact_person') }}: {{ formRef.organiser.contactPerson }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.language') }}: {{ languages.getLanguage(formRef.organiser.language) }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.phone_number') }}: {{ formRef.organiser.phone }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.fax') }}: {{ formRef.organiser.fax }}
          </div>
        </div>
        <ui-view-card-address :model-value="formRef.organiser.address"/>
      </ui-view-card>
      <template v-for="(transporter, index) in formRef.transporters" :key="index">
        <ui-view-card
            :title="index === 0 ? $t('form.title.transporter') : $t('form.title.transporter_count', {count: index+1})">
          <div class="row">
            <div class="col-lg-6">
              {{ $t('form.labels.name') }}: {{ transporter.name }}
            </div>
            <div class="col-lg-6">
              {{ $t('form.labels.email') }}: {{ transporter.email }}
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              {{ $t('form.labels.contact_person') }}: {{ transporter.contactPerson }}
            </div>
            <div class="col-lg-6">
              {{ $t('form.labels.language') }}: {{ languages.getLanguage(transporter.language) }}
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              {{ $t('form.labels.phone_number') }}: {{ transporter.phone }}
            </div>
            <div class="col-lg-6">
              {{ $t('form.labels.fax') }}: {{ transporter.fax }}
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              {{ $t('form.labels.means_of_transport') }}: {{
                transporter.meansOfTransport
                ? $t('means_of_transport.' + transporter.meansOfTransport)
                : ''
              }}
            </div>
            <div class="col-lg-6">
              {{ $t('form.labels.transport_date') }}: {{ DateFormatter.formatDate(transporter.transportDate) }}
            </div>
          </div>
          <ui-view-card-address :model-value="transporter.address"/>
        </ui-view-card>
      </template>
      <ui-view-card :title="$t('form.title.recovery_facility')">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.name') }}: {{ formRef.recoveryFacility.name }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.email') }}: {{ formRef.recoveryFacility.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.contact_person') }}: {{ formRef.recoveryFacility.contactPerson }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.annex_7_recovery_destination_type') }}:
            <span v-if="formRef.recoveryFacility.annex7RecoveryDestinationType">
              {{ $t('form.table.recovery_destination_type.' + formRef.recoveryFacility.annex7RecoveryDestinationType) }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.phone_number') }}: {{ formRef.recoveryFacility.phone }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.fax') }}: {{ formRef.recoveryFacility.fax }}
          </div>
        </div>
        <ui-view-card-address :model-value="formRef.recoveryFacility.address"/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.waste_identification')">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th style="width: 250px">{{ $t('form.table.type') }}</th>
            <th>{{ $t('form.table.value') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in formRef.wasteIdentifications" :key="index">
            <tr>
              <td>
                {{ row.type ? $t(`form.table.waste_codes.${row.type}`) : '-' }}
              </td>
              <td>
                {{ row.value }}
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        {{ $t('form.labels.waste_description') }}: {{ formRef.description }}
        <br/>
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.waste_amount_ton') }}: {{ formRef.amountTon }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.waste_amount_m3') }}: {{ formRef.amountM3 }}
          </div>
        </div>
        {{ $t('waste_identification_template.form.removal_operation') }}: {{ formRef.removalOperation }}
      </ui-view-card>
      <ui-view-card :title="$t('form.title.countries')">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>{{ $t('general.export').toUpperCase() }}</th>
            <th v-for="country in transitCountries" :key="country.id">
              {{ $t('general.transit').toUpperCase() }}
            </th>
            <th>{{ $t('general.destination').toUpperCase() }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <span v-if="exportCountry.value">{{ $t('countries.' + exportCountry.value) }}</span>
            </td>
            <td v-for="country in transitCountries" :key="country.id">
              <span v-if="country.value">{{ $t('countries.' + country.value) }}</span>
            </td>
            <td>
              <span v-if="destinationCountry.value">{{ $t('countries.' + destinationCountry.value) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.notes')" style="white-space: pre-wrap">
        {{ formRef.notes }}
      </ui-view-card>
    </div>
  </div>
</template>

<script>
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {computed, ref} from 'vue'
import {DateFormatter} from '@/services/dateFormatter'
import languages from '@/types/languages'
import OrganisationModel from '@/models/OrganisationModel'
import CountryType from '@/types/countryType'

export default {
  name:       'Annex7FormView',
  computed:   {
    DateFormatter() {
      return DateFormatter
    },
  },
  components: {
    UiViewCardAddress,
    UiViewCard,
  },
  props:      {
    form:          {
      type:     Object,
      required: true,
    },
    processorInfo: {
      type:    Object,
      default: () => {
      },
    },
    uuid:          {
      type:    String,
      default: '',
    },
    sharelink:     {
      type:    Boolean,
      default: false,
    },
  },
  setup:      (props) => {
    const formRef = ref(props.form)
    if (formRef.value.producer === null) {
      formRef.value.producer = OrganisationModel.empty
    }
    if (formRef.value.processor === null) {
      formRef.value.processor = OrganisationModel.empty
    }
    if (formRef.value.organiser === null) {
      formRef.value.organiser = OrganisationModel.empty
    }
    if (formRef.value.transporters.length === 0) {
      formRef.value.transporters.push(OrganisationModel.empty)
    }
    if (formRef.value.countriesConcerned.length === 0) {
      formRef.value.countriesConcerned.push({
        value: '',
        type:  CountryType.EXPORT,
      })
      formRef.value.countriesConcerned.push({
        value: '',
        type:  CountryType.DESTINATION,
      })
    }

    const date = computed(() => {
      return DateFormatter.formatDate(props.form.transportDate)
    })

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(props.form.lastUpdate)
    })

    const exportCountry = computed(() => {
      return formRef.value.countriesConcerned.filter((country) => {
        return country.type === CountryType.EXPORT
      })[0]
    })

    const destinationCountry = computed(() => {
      return formRef.value.countriesConcerned.filter((country) => {
        return country.type === CountryType.DESTINATION
      })[0]
    })

    const transitCountries = computed(() => {
      return formRef.value.countriesConcerned.filter((country) => {
        return country.type === CountryType.TRANSIT
      })
    })

    return {
      formRef,
      date,
      updateDate,
      languages,
      exportCountry,
      destinationCountry,
      transitCountries,
    }
  },
}
</script>
